// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
export default function BoxRequired(props) {
  const { t } = useTranslation();
  const { text, className } = props;
  return (
    <>
      <div className="formInput">
        <div className={`formInput__box ${className}`}>{t('textBoxRequired')}</div>
        <label className="formInput__text">{text}</label>
      </div>
    </>
  );
}
