// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './styles.scss';

function Step(props) {
  const { image, text, textDescription, description, imageTab, step, className } = props;
  return (
    <div className={`${className} wrap-step`}>
      <div className="step-top">
        <img src={imageTab} alt="img" />
        <div className="text-tab">{step}</div>
      </div>
      <div className="wrap-text-sp">
        <div className="step-text">{text}</div>
        <div className="step-text-description">{textDescription}</div>
      </div>
      <div className="step-image">
        <img src={image} alt="image" />
      </div>
      <div className="wrap-text">
        <div className="step-text">{text}</div>
        <div className="step-text-description">{textDescription}</div>
      </div>
      <div className="step-description">{description}</div>
    </div>
  );
}

export default Step;
