// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import TopHeader from '../../components/common/topHeader';
import LotteryInfo from '../../components/pages/myCollection/lotteryDetail/LotteryInfo';
import LotteryCard from '../../components/pages/myCollection/lotteryDetail/LotteryCard';
import LotteryIcon from '../../components/pages/myCollection/lotteryDetail/LotteryIcon';
import TButton from '../../components/common/button';
import HistoryPurchase from '../../components/pages/myCollection/lotteryDetail/HistoryPurchase';
import { facebookIcon, GUIDE, twitterIcon } from '../../../assets/imgs';
import ModalDiscardGuide from '../../components/common/modalDiscardGuide';

const MyCollectionLotteryDetailPC = (props) => {
  const { lotteryData, handleShare, getWidth } = props;
  const { lotteryId } = useParams();

  const [isShowDiscardGuide, setShowDiscardGuide] = useState(false);

  const eleWrap = document?.getElementById('share-sns-wrapper')?.offsetWidth;

  const [width, setWidth] = useState(eleWrap);
  useEffect(() => {
    setWidth(eleWrap);
  }, [eleWrap]);
  useEffect(() => {
    if (width) {
      getWidth(width);
    }
  }, [width]);
  useEffect(() => {
    function handleResize() {
      setWidth(document.getElementById('share-sns-wrapper')?.offsetWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const history = useHistory();

  return (
    <>
      <TopHeader title="Myコレクション" />
      <div className="main-content" style={{ marginTop: -31 }}>
        <LotteryInfo lotteryInfo={lotteryData.lottery_info} />
        <div className="fw-bold mb-3" style={{ marginTop: 80, fontSize: 20 }}>
          当せん賞品
        </div>
        <div>当せんした商品は以下になります。</div>
        {lotteryData.lottery_info?.can_discard && (
          <div>
            受取数を調整したい方は
            <span
              style={{ color: '#1D9DED', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => setShowDiscardGuide(true)}
            >
              破棄について
            </span>
            を確認ください。
            <span style={{ color: '#FF0000' }}>
              破棄数変更期限：{lotteryData.lottery_info?.discard_due_date} まで
            </span>
          </div>
        )}
        <div className="d-flex flex-wrap" style={{ marginTop: 60 }}>
          {lotteryData?.list_detail?.map((item, index) => (
            <LotteryCard
              data={item}
              key={index}
              canDiscard={lotteryData.lottery_info?.can_discard}
              refundPoint={lotteryData.lottery_info?.refund_point}
            />
          ))}
        </div>
      </div>
      <div id="lottery-icon-wrapper">
        <div className="main-content">
          <div
            className="d-flex flex-wrap"
            style={{ marginTop: 80, paddingTop: 20, width: 'max-content', maxWidth: 920 }}
            id="share-sns-wrapper"
          >
            {lotteryData?.list_prize?.map((item, index) => (
              <LotteryIcon data={item} key={index} />
            ))}
          </div>
          <div>※数字は当せん個数。（）内は破棄数。</div>

          <div style={{ margin: '0 auto', textAlign: 'center', marginTop: 80 }}>
            <TButton
              title={`${
                lotteryData?.lottery_info?.can_buy ? 'もう一度このクジを購入する' : '販売終了'
              }`}
              className={`${lotteryData?.lottery_info?.can_buy ? 'info' : 'primary'}`}
              id="goToBuy"
              onClick={() =>
                lotteryData?.lottery_info?.can_buy && history.push(`/purchase/${lotteryId}`)
              }
              disabled={!lotteryData?.lottery_info?.can_buy}
            />
          </div>

          <div className="share-sns">
            <div className="title">
              <img src={GUIDE.iconLeft} alt="icon" className="icon" />
              <div className="text-share">結果をシェアする</div>
              <img src={GUIDE.iconRight} alt="icon" className="icon" />
            </div>
            <div className="icon-wrapper">
              <img
                src={facebookIcon}
                alt="icon"
                className="icon icon-fb"
                onClick={() => handleShare('fb')}
              />
              <img
                src={twitterIcon}
                alt="icon"
                className="icon"
                onClick={() => handleShare('tw')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="main-content" style={{ marginBottom: 100 }}>
        <div>
          <HistoryPurchase data={lotteryData?.orders} />
        </div>
      </div>

      <ModalDiscardGuide
        isShowDiscardGuide={isShowDiscardGuide}
        setShowDiscardGuide={setShowDiscardGuide}
      />
    </>
  );
};

export default MyCollectionLotteryDetailPC;
