// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TopHeader from '../common/topHeader';
import { MaintainApi } from '../../services/maintain';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { logout } from '../../store/features/auth';
import ScrollButton from './top/backToTop/ScrollButton';

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 30px;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 24px;
  margin-bottom: 30px;
  white-space: pre-wrap;
  word-break: break-all;
  max-width: 920px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 15px;
    min-width: 345px;
  }
`;

export default function Maintain() {
  const [data, setData] = useState('');
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(async () => {
    const res = await MaintainApi.getMaintainData();
    const { data } = res;
    if (data?.status) {
      dispatch(logout());
      setData(data?.description);
    } else {
      history.push('/');
    }
  }, []);

  return (
    <>
      <TopHeader title="メンテナンス中" />
      <div className="main-content">
        <Title>メンテナンス中</Title>
        <Content>{data}</Content>
      </div>
      <ScrollButton />
    </>
  );
}
