// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { NewsApis } from '../../../services/listNews';
import useMessage from '../toast/UseMessage';
import { IoCloseSharp } from 'react-icons/io5';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { showNews } from '../../../store/features/auth';
import { listsErrorNotShowToast } from '../../../constants';
import JSCookie from 'js-cookie';

function NotifyNews(props) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    auth: { isShowNews },
  } = useAppSelector((state) => state);
  const authToken = JSCookie.get('token');
  const { openMessageError } = useMessage();
  const [count, setCount] = useState(0);

  const getNewsNotSeen = async () => {
    try {
      const res = await NewsApis.getNewsNotSeen();
      const { data } = res;
      setCount(data?.count);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    }
  };
  useEffect(() => {
    if (authToken) {
      getNewsNotSeen();
    }
  }, []);

  const [isPrivate, setPrivate] = useState(false);
  return (
    <>
      {count > 0 && isShowNews ? (
        <div>
          <div className="wrap-notify-news">
            <div>あなたへのお知らせが</div>
            <div>
              <span
                onClick={() => {
                  setPrivate(!isPrivate);
                  history.push({
                    pathname: '/news',
                    state: { TAB_PRIVATE: 2, isCheck: isPrivate },
                  });
                }}
              >
                {count}件
              </span>
              届いています。
            </div>
          </div>
          <div
            className="icon-close-notice"
            onClick={() => {
              dispatch(showNews({ status: false }));
            }}
          >
            <IoCloseSharp />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default NotifyNews;
