// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  font-size: ${(props) => (props.isInResult ? '22px' : '30px')};
  letter-spacing: 0.2em;
  position: relative;

  @media screen and (max-width: 768px) {
    font-size: ${(props) => (props.isInResult ? '20px' : '22px')};
  }
`;

const StyledStrokeText = styled.div`
  -webkit-text-stroke: 6px #fff;
  width: max-content;
`;

const StyledText = styled.div`
  width: max-content;
  position: absolute;
  top: 0;
`;

export default function OnChallengeText({ isInResult = false }) {
  return (
    <StyledDiv isInResult={isInResult}>
      <StyledStrokeText>+On Challenge</StyledStrokeText>
      <StyledText>
        <span
          style={{
            color: '#F55076',
          }}
        >
          +
        </span>
        <span
          style={{
            color: '#40B569',
          }}
        >
          O
        </span>
        <span
          style={{
            color: '#FF6C00',
          }}
        >
          n
        </span>{' '}
        <span
          style={{
            color: '#1D9DED',
          }}
        >
          C
        </span>
        <span
          style={{
            color: '#F55076',
          }}
        >
          h
        </span>
        <span
          style={{
            color: '#40B569',
          }}
        >
          a
        </span>
        <span
          style={{
            color: '#FF6C00',
          }}
        >
          l
        </span>
        <span
          style={{
            color: '#1D9DED',
          }}
        >
          l
        </span>
        <span
          style={{
            color: '#F55076',
          }}
        >
          e
        </span>
        <span
          style={{
            color: '#40B569',
          }}
        >
          n
        </span>
        <span
          style={{
            color: '#FF6C00',
          }}
        >
          g
        </span>
        <span
          style={{
            color: '#1D9DED',
          }}
        >
          e
        </span>
      </StyledText>
    </StyledDiv>
  );
}
