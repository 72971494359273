// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function CustomSlider(props) {
  const { data } = props;
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'slick-dots slick-thumb',
    arrows: false,
    autoplay: true,
    customPaging: function (i) {
      return (
        <div>
          <img src={data[i]?.thumbnail_url} alt="img" style={{ width: '100%' }} />
        </div>
      );
    },
  };
  return (
    <div>
      <Slider {...settings}>
        {data.map((item, index) => {
          return (
            <div key={index} className="wrap-custom-image">
              <img src={item?.image_url} alt="img" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default CustomSlider;
