// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { BUY_LOTS } from '../../../constants';
import TButton from '../../common/button';

function AddressShow(props) {
  const { dataInfo, data } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const search = queryString.parse(history?.location?.search);

  const moveToEditAddress = () => {
    history.push(
      `/purchase/${id}/edit-address?${
        data?.adult_purchase === BUY_LOTS
          ? `adult_purchase=${data?.adult_purchase}`
          : `quantity=${data?.quantity}`
      }${Number(search?.address_id) ? `&address_id=${search?.address_id}` : ''}&payment_method=${
        data?.payment_method_id
      }&point=${data?.number_point_used}`,
    );
  };

  return (
    <>
      {dataInfo?.postal_code ? (
        <div className="my-setting-address-content">
          <div className="setting-top">
            <div>
              {t('nameRecipient').replace('param', `${dataInfo?.last_name}${dataInfo?.first_name}`)}
            </div>
            <div>
              <TButton
                title={t('textBtnEdit')}
                className="my-btn-edit-custom primary"
                onClick={moveToEditAddress}
              />
            </div>
          </div>
          <div className="show-address">
            <p className="mb-0">{t('postalCode').replace('param', dataInfo?.postal_code)}</p>
            <p className="mb-0">
              {dataInfo?.district}
              {dataInfo?.province}
              {dataInfo?.address1}
            </p>
            <p className="mb-0">{dataInfo?.address2}</p>
          </div>
          <div className="show-contact">
            <p className="mb-0 show-contact-pc">
              {t('telFormat').replace('param', dataInfo?.phone)}
            </p>
            <p className="mb-0 show-contact-pc">
              {t('mailFormat').replace('param', dataInfo?.email_delivery_address)}
            </p>
            <p className="mb-0 show-contact-sp">{dataInfo?.phone}</p>
            <p className="mb-0 show-contact-sp">{dataInfo?.email_delivery_address}</p>
          </div>
        </div>
      ) : (
        <div className="my-address-no-data">
          <p className="description">{t('textSettingAddress')}</p>
          <div>
            <TButton
              title={t('textBtnDeliveryAddress')}
              className="btn-move-register-address primary"
              onClick={moveToEditAddress}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AddressShow;
