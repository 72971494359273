// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

export const setBackGroundLineUp = (rankName) => {
  let result = {
    color: '',
    background: '',
    bgWinRate: '',
  };
  switch (rankName) {
    case 'A':
      result = { color: 'my-color-red', background: 'my-bg-red', bgWinRate: 'my-bg-winRate-red' };
      break;
    case 'B':
      result = {
        color: 'my-color-green',
        background: 'my-bg-green',
        bgWinRate: 'my-bg-winRate-green',
      };
      break;
    case 'C':
      result = {
        color: 'my-color-orange',
        background: 'my-bg-orange',
        bgWinRate: 'my-bg-winRate-orange',
      };
      break;
    case 'D':
      result = {
        color: 'my-color-blue',
        background: 'my-bg-blue',
        bgWinRate: 'my-bg-winRate-blue',
      };
      break;
    case 'E':
      result = {
        color: 'my-color-black-200',
        background: 'my-bg-black-200',
        bgWinRate: 'my-bg-winRate-black',
      };
      break;
    default:
      break;
  }
  return result;
};
