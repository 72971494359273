// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import isEmpty from 'validator/lib/isEmpty';
import i18n from './../i18n/i18n';

export const validatePassword = (password) => {
  const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w\W]{8,16}$/;
  if (isEmpty(password)) {
    return i18n.t('checkRequiredPassword');
  } else if (!regexPassword.test(password)) {
    return i18n.t('checkPassword');
  }
  return '';
};
export const validatePasswordLogin = (password) => {
  if (isEmpty(password)) {
    return i18n.t('checkRequiredPassword');
  }
  return '';
};
export const validateConfirmPassword = (password, password_confirmation) => {
  if (isEmpty(password)) {
    return i18n.t('checkRequiredConfirmPassword');
  } else if (password !== password_confirmation) {
    return i18n.t('isMatchPassword');
  }
  return '';
};
export const validateNewPassword = (password) => {
  const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w\W]{8,16}$/;
  if (isEmpty(password)) {
    return i18n.t('checkRequiredNewPassword');
  } else if (!regexPassword.test(password)) {
    return i18n.t('checkPassword');
  }
  return '';
};
export const validateConfirmNewPassword = (password, password_confirmation) => {
  if (isEmpty(password)) {
    return i18n.t('checkRequiredConfirmNewPassword');
  } else if (password !== password_confirmation) {
    return i18n.t('isMatchNewPassword');
  }
  return '';
};
export const validateCurrentPassword = (password) => {
  const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w\W]{8,16}$/;
  if (isEmpty(password)) {
    return i18n.t('checkRequiredCurrentPassword');
  } else if (!regexPassword.test(password)) {
    return i18n.t('checkPassword');
  }
  return '';
};
export const validateNewPasswordChange = (password) => {
  const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w\W]{8,16}$/;
  if (isEmpty(password)) {
    return i18n.t('checkRequiredNewPasswordChange');
  } else if (!regexPassword.test(password)) {
    return i18n.t('checkPassword');
  }
  return '';
};
export const validateConfirmNewPasswordChange = (password, confirmPassword) => {
  if (isEmpty(password)) {
    return i18n.t('checkRequiredConfirmNewPasswordChange');
  } else if (password !== confirmPassword) {
    return i18n.t('isMatchNewPasswordChange');
  }
  return '';
};
export const validateCode = (code) => {
  if (isEmpty(code)) {
    return i18n.t('checkRequiredCode');
  }
  return '';
};
