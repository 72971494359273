// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import olAxios from '../api/Interceptors';

export const TopApis = {
  getListBanner: () => {
    return olAxios.get('/carousels');
  },
  getListLottery: (params) => {
    return olAxios.get('/lottery-events', { params });
  },
  getListNews: (params) => {
    return olAxios.get('/news?limit=10&show_range=1');
  },
  getStatusUrl: () => {
    return olAxios.get('/banner');
  },
};
