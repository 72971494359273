// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useState } from 'react';
import TopHeader from '../../components/common/topHeader';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import BoxRequired from '../../components/common/boxRequired';
import { validatePassword } from '../../validations/validatePassword';
import { useAppDispatch } from '../../store';
import { AuthApis } from '../../services/auth';
import TButton from '../../components/common/button';
import { useHistory } from 'react-router-dom';
import { AiOutlineRight } from 'react-icons/ai';
import JSCookie from 'js-cookie';

export default function DeleteAccount() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [validationMsg, setValidationMsg] = useState({});
  const [validationMsgApi, setValidationMsgApi] = useState('');
  const [checked, setChecked] = useState(false);

  const validateAll = () => {
    const error = {
      password: '',
    };
    error.password = validatePassword(password);
    setValidationMsg(error);
    if (error.password !== '') return false;
    return true;
  };

  const handleChange = (event) => {
    setPassword(event.target.value);
    setValidationMsg('');
    setValidationMsgApi('');
  };

  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) return;
    dispatch(showLoading());
    const data = {
      password: password,
    };
    try {
      const res = await AuthApis.deleteAccount(data);
      if (res.status === 200) {
        JSCookie.remove('token');
        history.push({ pathname: '/delete-account/success', state: true });
      }
    } catch (error) {
      setValidationMsgApi(error.response.data.message);
    } finally {
      dispatch(hideLoading());
    }
  };
  return (
    <div>
      <TopHeader title={t('headerDeleteAccount')} />
      <div className="main-content delete-account">
        <div className="info-delete-account">
          <div className="about-delete">{t('aboutDeleteAccount1')}</div>
          <div>{t('aboutDeleteAccount2')}</div>
          <div className="about-delete">{t('aboutDeleteAccount3')}</div>
          <div>{t('aboutDeleteAccount4')}</div>
        </div>

        <div>
          <BoxRequired text={t('textPassword')} />
        </div>

        <div>
          <div>
            <input
              type="password"
              className="form-control border-input"
              placeholder="半角英数字8~16文字"
              maxLength="16"
              value={password}
              onChange={handleChange}
            />
            {validationMsg.password && <div className="txt_error">{validationMsg.password}</div>}
            {validationMsgApi && <div className="txt_error">{validationMsgApi}</div>}
          </div>
        </div>
        <div className="move-to-page">
          <div className="text-move-to-page" onClick={() => history.push('/forgot-password')}>
            {t('textMoveToForgotPassword')}
          </div>
          <AiOutlineRight className="icon-move-to" />
        </div>
        <div className="btn-delete">
          <div className="check-box">
            <input
              className="checkbox form-check-input"
              type="checkbox"
              value={checked}
              onChange={() => {
                !checked ? setChecked(true) : setChecked(false);
              }}
            />
            <div className="textCheckbox">{t('checkboxConfirmDelete')}</div>
          </div>
          <div>
            <TButton
              title={t('buttonDeleteAccount')}
              className="btn primary"
              type="submit"
              onClick={handleSubmit}
              disabled={!checked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
