// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineRight } from 'react-icons/ai';
import RainbowText from '../../../common/rainbowText';

import './index.scss';
import News from './News';

export default function ListNews(props) {
  const { listNews } = props;
  const { t } = useTranslation();

  return (
    <div className="list-news">
      <div className="list-news-header position-relative d-flex align-items-center">
        <div className="notice text-white text-center me-3">NEWS</div>
        <RainbowText text={' ' + t('menu.news')} />
        <a href="/news">
          お知らせ一覧 <AiOutlineRight />
        </a>
      </div>
      <div className="list-news-content">
        {!listNews.length ? (
          <div />
        ) : (
          listNews.map((item, index) => <News news={item} key={index} />)
        )}
      </div>
    </div>
  );
}
