// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';

import ScratchUI from './scratchUI';

export const SCRATCH_TYPE = ['retail', 'package'];

export default function Scratch() {
  const history = useHistory();
  const { type } = useParams();
  const location = useLocation();
  const search = qs.parse(location.search);
  const [adultPurchase, setAdultPurchase] = useState();

  const pathLotteryTrial = ['/scratch/trial/retail', '/scratch/trial/package'];
  const pathSite = history.location.pathname;

  useEffect(() => {
    if (SCRATCH_TYPE[adultPurchase]) {
      if (type !== SCRATCH_TYPE[adultPurchase]) {
        history.push(`/scratch/${SCRATCH_TYPE[adultPurchase]}?order_id=${search.order_id}`);
      }
    }
  }, [adultPurchase]);

  if ((!SCRATCH_TYPE.includes(type) || !search.order_id) && !pathLotteryTrial.includes(pathSite))
    return <Redirect to="/" />;

  return <ScratchUI orderId={search.order_id} type={type} setAdultPurchase={setAdultPurchase} />;
}
