// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './styles.scss';

export default function PointHistoryPC(props) {
  const { data } = props;
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <div className="main-point-history-pc point-history-pc">
      <Table bordered borderless>
        <thead>
          <tr>
            <th>
              <div className="td-time-point">{t('titleTimePointHistory')}</div>
            </th>
            <th>
              <div className="td-reason-point">{t('titleReasonPointHistory')}</div>
            </th>
            <th>
              <div className="td-name-lottery">{t('titleNameLotteryPointHistory')}</div>
            </th>
            <th>
              <div className="td-name-prize">{t('titleNameProductPointHistory')}</div>
            </th>
            <th>
              <div className="td-quantity">{t('titleQuantityPointHistory')}</div>
            </th>
            <th>
              <div className="td-pt">{t('titlePtPointHistory')}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td>
                  <span className="move-to-detail">{item.created_date}</span>
                </td>
                <td>
                  <span className="move-to-detail">
                    {item.change_reason ? item.change_reason : '－'}
                  </span>
                </td>
                <td>
                  <span
                    className="name-lottery-pc"
                    style={
                      item.type === 3 || item.type === 4
                        ? { textDecoration: 'underline', cursor: 'pointer', color: '#1d9ded' }
                        : {}
                    }
                    onClick={() => {
                      item.lottery_event_id
                        ? history.push(`/lottery/${item.lottery_event_id}`)
                        : null;
                    }}
                  >
                    {item.type === 3 || item.type === 4 ? item.lottery_event_name : '－'}
                  </span>
                </td>
                <td>
                  <span className="move-to-detail">{item.prize_name ? item.prize_name : '－'}</span>
                </td>
                <td>
                  <span className="move-to-detail">
                    {item.discard_number ? item.discard_number : '－'}
                  </span>
                </td>
                <td>
                  <span
                    className="move-to-detail"
                    style={
                      Number(item.change_value.replace(',', '')) < 0 ? { color: '#F55076' } : {}
                    }
                  >
                    {item.change_value}
                    <span className="point-history-pt">pt</span>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div>※履歴機能開始以前の履歴は表示されません</div>
    </div>
  );
}
