// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './index.scss';

const COLOR = ['#F55076', '#40B569', '#FF6C00', '#1D9DED'];

export default function RainbowText(props) {
  const { text } = props;
  if (!text) return '';
  const __text = text.split('');
  return (
    <div className="rainbow-text">
      {__text.map((item, index) => (
        <span
          key={index}
          style={{
            color: COLOR[index % 4],
          }}
        >
          {item}
        </span>
      ))}
    </div>
  );
}
