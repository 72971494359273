// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import styled from 'styled-components';

export const Button = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  cursor: pointer;
`;

export const ScrollToTopIcon = styled.img`
  width: 87px;
  height: 87px;
`;
