// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { MyCollectionApis } from '../../services/myCollection';
import './index.scss';
import { BREAK_POINT, listsErrorNotShowToast } from '../../constants';
import MyCollectionLotteryDetailPC from './MyCollectionLotteryDetailPC';
import MyCollectionLotteryDetailSP from './MyCollectionLotteryDetailSP';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import useMessage from '../../components/common/toast/UseMessage';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import html2canvas from 'html2canvas';
import { getBase64Image, shareLink } from '../../utils/share';
import { ShareApi } from '../../services/share';

export default function MyCollectionLotteryDetail() {
  const [lotteryData, setLotteryData] = useState({});
  const { lotteryId } = useParams();
  const [width, setWidth] = useState();
  const ratioFace = 1.91;
  const height = width / ratioFace;

  const getWidth = (value) => {
    setWidth(value);
  };

  const dispatch = useAppDispatch();

  const {
    auth: { userInfo },
  } = useAppSelector((state) => state);

  const { openMessageError } = useMessage();

  const handleShare = (type) => {
    const windowReference = window.open();
    const element = document.getElementById('share-sns-wrapper');
    if (element) {
      html2canvas(element, {
        proxy: '',
        useCORS: true,
        allowTaint: true,
        // foreignObjectRendering: true,
        scrollY: 0,
        scrollX: 0,
        width: width,
        height: height,
        imageTimeout: 40000,
        onclone: (doc) => {
          const ele = doc.getElementById('share-sns-wrapper');
          const variationImages = doc.querySelectorAll('.prizeItemImg');
          variationImages.forEach((img) => {
            const img64 = getBase64Image(img);
            img.src = img64;
          });
          if (ele) {
            ele.style.position = 'fixed';
            ele.style.margin = '0';
            ele.style.top = '0';
            ele.style.left = '0';
          }
        },
      }).then(async function (canvas) {
        const image = canvas.toDataURL('image/png');
        dispatch(showLoading());
        const res = await ShareApi.uploadImg({
          file: image,
          lottery_event_id: lotteryId,
          type: 'collection',
        });
        const { data } = res;
        dispatch(hideLoading());

        windowReference.location = shareLink(
          type,
          `${process.env.APP_ENDPOINT}/share/sns/${userInfo.id}/${lotteryId}/collection/${data?.image_url}`,
        );
      });
    }
  };

  useEffect(async () => {
    if (lotteryId) {
      dispatch(showLoading());
      try {
        const res = await MyCollectionApis.getLotteryDetail({ lottery_event_id: lotteryId });
        const { data } = res;
        setLotteryData(data);
      } catch (error) {
        if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
          openMessageError(error);
        }
      } finally {
        dispatch(hideLoading());
      }
    }
  }, []);

  return (
    <>
      <MediaQuery minWidth={BREAK_POINT + 1}>
        <MyCollectionLotteryDetailPC
          lotteryData={lotteryData}
          handleShare={handleShare}
          getWidth={getWidth}
        />
      </MediaQuery>
      <MediaQuery maxWidth={BREAK_POINT}>
        <MyCollectionLotteryDetailSP
          lotteryData={lotteryData}
          handleShare={handleShare}
          getWidth={getWidth}
        />
      </MediaQuery>
      <ScrollButton />
    </>
  );
}
