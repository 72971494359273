// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { login } from '../../store/features/auth';
import { useAppDispatch } from '../../store';
import TButton from '../common/button';
import JSCookie from 'js-cookie';

export default function Verify() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isVerifySuccess, setVerifySuccess] = useState(false);

  const token = JSCookie.get('token');

  useEffect(() => {
    const search = qs.parse(history.location.search);
    if (search.token && search.email && search.id) {
      dispatch(
        login({
          token: search.token,
          user: {
            email: search.email,
            id: search.id,
          },
        }),
      );
      setVerifySuccess(true);
    } else if (token) {
      history.push('/sns-management');
    } else {
      history.push('/verify/invalid');
    }
  }, []);

  return (
    <>
      {isVerifySuccess ? (
        <div className="main-content text-center">
          <div className="text-verify">
            <div>
              メールアドレス確認が正常に完了しました。 <br />
              ご登録いただき、ありがとうございます。
            </div>
            <TButton title="TOP画面へ" className="primary mt-4" onClick={() => history.push('/')} />
          </div>
        </div>
      ) : (
        <div />
      )}
    </>
  );
}
