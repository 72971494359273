// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';

export default function OverviewNews(props) {
  const { data } = props;
  return (
    <>
      <div>
        <div className="txt-date">{data?.start_date}</div>
        <div className="txt-title">{data?.title}</div>
      </div>
    </>
  );
}
