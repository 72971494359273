// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { convertSaleStatusToColor } from '../../../../utils/convertSaleStatus';

import './index.scss';

const LotteryInfo = (props) => {
  const { lotteryInfo } = props;

  return (
    <div className="lottery-info-sp">
      <div style={{ width: '100%', height: '42.133333vw', overflow: 'hidden' }}>
        <img src={lotteryInfo?.image.image_url} />
      </div>
      <div className="information">
        <div className="time-box">
          <div className="fw-bold">
            {lotteryInfo?.name}
            <span
              className="sale-status"
              style={{
                whiteSpace: 'nowrap',
                fontSize: 10,
                color: '#fff',
                marginLeft: 18,
                padding: '1px 5px',
                backgroundColor: convertSaleStatusToColor(lotteryInfo?.sale_status),
              }}
            >
              {lotteryInfo?.sale_status}
            </span>
          </div>
          <div className="d-flex flex-column" style={{ marginTop: 28, marginBottom: 40 }}>
            <div className="d-flex">
              <span className={`tag ${lotteryInfo?.can_buy ? 'tag-end' : 'tag-ex'}`}>
                {lotteryInfo?.can_buy ? '販売終了日' : '販売終了'}
              </span>
              <span>{lotteryInfo?.end_date}</span>
            </div>
            {lotteryInfo?.can_buy && (
              <div className="d-flex" style={{ marginTop: 16 }}>
                <span className="tag tag-delivery">お届け時期</span>
                <span>{lotteryInfo?.estimated_delivery_date}</span>
              </div>
            )}
          </div>
          <div className="quantity-info">
            当せん個数:
            <div id="total-buy">
              <span style={{ fontSize: 20 }}>{lotteryInfo?.total_buy}</span>個
              <div style={{ fontWeight: 'normal' }}>
                (受け取り個数{lotteryInfo?.total_buy - lotteryInfo?.total_discard}個/破棄数
                {lotteryInfo?.total_discard}個)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LotteryInfo;
