// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import { listsErrorNotShowToast } from '../../../../constants';
import { MyCollectionApis } from '../../../../services/myCollection';
import { UserInfoApis } from '../../../../services/userInfo';
import { useAppDispatch } from '../../../../store';
import { showLoading, hideLoading } from '../../../../store/features/loadingPage';
import { convertRankName } from '../../../../utils/convertSaleStatus';
import TButton from '../../../common/button';
import useMessage from '../../../common/toast/UseMessage';
import './index.scss';

const LotteryCard = (props) => {
  const { data, canDiscard, refundPoint = 0 } = props;
  const { lotteryId } = useParams();

  const { openMessageError } = useMessage();

  const [discardNumber, setNumber] = useState(0);
  const [isShowModal, toggleShowModal] = useState(false);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isDiscardPrizeSuccess, setSuccess] = useState(false);

  const handleDiscardPrize = async () => {
    dispatch(showLoading());
    try {
      await MyCollectionApis.discardPrize({
        lottery_event_id: parseInt(lotteryId),
        prize_id: data.prize_id,
        quantity: discardNumber,
      });
      setSuccess(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const renderSelect = (maxValue, minValue = 0) => {
    const options = [];
    for (let i = minValue; i <= maxValue; i++) {
      options.push(
        <option value={i} key={i}>
          {i}
        </option>,
      );
    }
    return (
      <div className="my-select my-select-collection-detail">
        <select onChange={(e) => setNumber(e.target.value)}>{options}</select>
        <div className="my-icon-select">
          <FiChevronDown />
        </div>
      </div>
    );
  };

  const [point, setPoint] = useState();
  const getUserInfo = async () => {
    const formData = {
      info: 'profile',
    };
    dispatch(showLoading());
    try {
      const res = await UserInfoApis.getData(formData);
      const { data } = res;
      setPoint(data?.total_point);
      toggleShowModal(true);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleToggleShowModal = () => {
    getUserInfo();
  };

  return (
    <div className="lottery-card-wrapper">
      <div className="lottery-card-sp">
        <div className="card-img">
          <img src={data?.image[0]?.thumbnail_url} />
        </div>
        <div className="card-info">
          <div className="card-lottery-title">
            {convertRankName(data.rank_name)}-{data.number_order} {data.title}
          </div>
          <div className="lottery-quantity">
            <div>
              <div style={{ fontSize: 12, letterSpacing: '0.1em' }}>当せん個数</div>
              <div className="fw-bold">
                <span style={{ fontSize: 22 }}>{data.total}</span> 個
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12 }}>受取数</div>
              <div className="fw-bold">
                <span>{data.total - data.total_discard}</span> 個
              </div>
            </div>
            <div>
              <div style={{ fontSize: 12, letterSpacing: '0.1em' }}>破棄数</div>
              <div style={{ fontSize: 16 }}>{renderSelect(data.total - data.total_discard)}</div>
            </div>
          </div>
        </div>
      </div>

      <TButton
        className="primary button-delete"
        title="破棄数を変更する"
        onClick={() => canDiscard && discardNumber && handleToggleShowModal()}
        disabled={!canDiscard}
      />

      <Modal
        show={isShowModal}
        onHide={() => toggleShowModal(false)}
        dialogClassName="modal-discard-sp"
      >
        <Modal.Body>
          <div className="delete-item-popup">
            {isDiscardPrizeSuccess ? (
              <div style={{ marginTop: 150, marginBottom: 230, textAlign: 'center' }}>
                <div className="popup-title">破棄が完了しました。</div>
                <div style={{ fontSize: 16, marginBottom: 67 }}>
                  獲得ポイント　
                  <span style={{ fontSize: 24, fontWeight: 'bold' }}>
                    {Math.floor(discardNumber * refundPoint)?.toLocaleString('ja-JP')}Pt
                  </span>
                </div>
                <TButton
                  id="btn-goBack"
                  title="Myコレクションへ戻る"
                  className="primary"
                  onClick={() => window.location.reload()}
                />
              </div>
            ) : (
              <>
                <div className="popup-title">この商品を本当に破棄しますか？</div>
                <div className="popup-notice">※一度破棄した商品は元には戻せません。</div>
                <div className="popup-item-name">
                  {convertRankName(data.rank_name)}-{data.number_order} {data.title}
                </div>
                <div className="popup-item-img">
                  <img src={data?.image[0]?.thumbnail_url} />
                </div>
                <div style={{ fontSize: 16 }}>
                  所持クジポイント　
                  <span style={{ fontSize: 24, fontWeight: 'bold' }}>
                    {point?.toLocaleString('ja-JP')}
                  </span>
                  <span style={{ fontSize: 16, fontWeight: 'bold' }}>Pt</span>
                </div>
                <div className="popup-item-quantity">
                  破棄個数　<span>{discardNumber}</span>
                  <span style={{ fontSize: 16 }}>個</span>
                </div>
                <div className="popup-item-quantity" style={{ marginBottom: 20 }}>
                  獲得ポイント　
                  <span>{Math.floor(discardNumber * refundPoint)?.toLocaleString('ja-JP')}</span>
                  <span style={{ fontSize: 16 }}>Pt</span>
                </div>
                <TButton title="破棄する" className="primary" onClick={handleDiscardPrize} />
                <TButton
                  title="修正する"
                  className="cancel-btn mt-2"
                  onClick={() => {
                    setSuccess(false);
                    toggleShowModal(false);
                  }}
                />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LotteryCard;
