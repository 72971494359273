// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

function MethodPoint(props) {
  const { dataInfo, lotteryEvent, data, getPriceByPoint, getCheckPoint } = props;
  const totalMyPoint = dataInfo?.total_point;
  const flag = lotteryEvent?.use_point_flag;
  const taxRate = lotteryEvent?.tax_rate;
  const priceByPoint = lotteryEvent?.price_by_point;
  const { quantity } = data;

  const history = useHistory();
  const pathLotteryTrial = '/purchase/trial';
  const pathSite = history.location.pathname;

  const search = queryString.parse(history?.location?.search);
  const [point, setPoint] = useState(Number(search?.point) ? Number(search?.point) : '');
  const [messageCheckPoint, setMessage] = useState('');
  const messageMyPoint = `所持ポイントが足りません。\n所持ポイント以下の数値を入力してください。`;
  const messagePointToUse = `価格以上のポイントが入力されました。\n価格を再度確認の上、もう一度入力してください。`;
  const numberOfPointToUse = Math.floor(
    quantity * priceByPoint + quantity * priceByPoint * (taxRate / 100),
  );

  const getPointUsed = () => {
    if (point > totalMyPoint) {
      setMessage(messageMyPoint);
      setPoint('');
      getPriceByPoint(0);
      getCheckPoint(false);
    } else if (point > numberOfPointToUse) {
      setMessage(messagePointToUse);
      getCheckPoint(false);
      setPoint('');
      getPriceByPoint(0);
    } else {
      setMessage('');
      getPriceByPoint(point === '' ? 0 : point);
      getCheckPoint(true);
    }
  };

  useEffect(() => {
    setPoint('');
    getPriceByPoint(0);
    setMessage('');
  }, [quantity]);

  useEffect(() => {
    setPoint(Number(search?.point) ? Number(search?.point) : '');
    getPriceByPoint(Number(search?.point) ? Number(search?.point) : 0);
  }, []);
  return (
    <>
      {flag === 1 && (
        <div className="my-payment-method method_point">
          <div className="title-inbox-sp">
            <strong className="title-inbox">ポイント</strong>
          </div>

          <div>
            <div className="list-method my-box-purchase">
              <div className="title">
                <strong className="title-inbox">ポイント</strong>
              </div>

              {pathSite === pathLotteryTrial ? (
                <div style={{ color: '#FF0000', whiteSpace: 'pre-wrap' }}>
                  {`お試しくじのため、\nポイント割引の利用は不要です。`}
                </div>
              ) : (
                <>
                  <div>ポイント割引を利用する</div>
                  <div className="point_can_use">
                    <span className="tag">所持Pt</span>
                    <span className="show_point">
                      {dataInfo?.total_point?.toLocaleString('ja-JP')}Pt
                    </span>

                    <div className="action_point">
                      <div className="type_point">
                        <input
                          className="form-control input-group"
                          maxLength="8"
                          value={point}
                          onChange={(event) => setPoint(event.target.value.replace(/\D/g, ''))}
                        />
                        <div className="unit">Pt</div>
                      </div>
                      <div className="btn_action_point" onClick={getPointUsed}>
                        確定
                      </div>
                    </div>

                    {messageCheckPoint && (
                      <span className="note_check_point">{messageCheckPoint}</span>
                    )}

                    <div className="note_point">
                      {`※(1Pt=1円)です \n※送料の支払いはポイントでは行えません`}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MethodPoint;
