// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import olAxios from '../api/Interceptors';

export const PurchaseApis = {
  create: (data) => {
    return olAxios.post(`/order/create`, data);
  },

  scratch: (params) => {
    return olAxios.post('/order/scratch', params);
  },

  result: (params) => {
    return olAxios.post('/order/result', params);
  },

  scratchTrial: (data) => {
    return olAxios.get(`/order/trial/scratch/${data.quantity}/${data.adultPurchase}`);
  },
};
