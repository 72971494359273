// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PurchaseApis } from '../../../services/purchase';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';
import { useAppDispatch } from '../../../store';
import TButton from '../../common/button';
import fSubmit from '../../../utils/payment';
import useMessage from '../../common/toast/UseMessage';
import { listsErrorNotShowToast } from '../../../constants';

function ConfirmPurchase(props) {
  const { data, title, quantity, price, flag } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const history = useHistory();
  const [isPurchase, setPurchase] = useState(true);
  const pathLotteryTrial = '/purchase/trial';
  const pathSite = history.location.pathname;
  const SHOW_NOTE_OC = 0;

  const handleCancel = () => {
    props.onHide();
  };

  const confirmPurchase = async () => {
    dispatch(showLoading());
    setPurchase(false);
    try {
      const res = await PurchaseApis.create(data);
      if (res?.data?.order?.payment_id) {
        fSubmit(res.data.order);
      } else {
        history.push(
          `/scratch/${res?.data?.order.adult_purchase_flag ? 'package' : 'retail'}?order_id=${
            res?.data?.order.id
          }`,
        );
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
      setPurchase(true);
      handleCancel();
    }
  };

  const handleSCratchTrial = () => {
    history.push({
      pathname: `/scratch/trial/${data?.adult_purchase ? 'package' : 'retail'}`,
      state: { quantity, adultPurchaseFlag: data?.adult_purchase },
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-confirm-purchase"
    >
      <Modal.Body>
        <div className="my-confirm-content">
          <p className="title">
            <span>{title}</span>
          </p>
          {flag === SHOW_NOTE_OC && (
            <>
              <div className="note_limit">
                {`※このくじの＋On Challenge は規定数に達しました。\n抽選は必ずはずれになります。ご注意ください。`}
              </div>
              <div className="note_limit_sp">
                {`※このくじの＋On Challenge は\n規定数に達しました。\n抽選は必ずはずれになります。\nご注意ください。`}
              </div>
            </>
          )}

          <div className="show_price_pop_up">
            <div className="number">
              <strong>{t('textNumberOfPurchase')}</strong>
              <div>
                {quantity?.toLocaleString('ja-JP')}
                <span>枚</span>
              </div>
            </div>
            <div className="number">
              <strong>合計金額</strong>
              <div>
                {pathLotteryTrial === pathSite ? 0 : price?.totalPrice?.toLocaleString('ja-JP')}
                <span>円</span>
              </div>
            </div>
            <div className="number">
              <strong>ポイント利用</strong>
              <div>
                {pathLotteryTrial === pathSite ? 0 : price?.priceByPoint?.toLocaleString('ja-JP')}
                <span>Pt</span>
              </div>
            </div>
            <div className="number">
              <strong>支払い金額</strong>
              <div>
                {pathLotteryTrial === pathSite
                  ? 0
                  : price?.actualTotalPoint?.toLocaleString('ja-JP')}
                <span>円</span>
              </div>
            </div>
          </div>

          <div className="my-btn-confirm-purchase">
            <TButton
              title={t('textBtnConfirm')}
              className="my-btn-confirm primary"
              onClick={() => {
                pathLotteryTrial === pathSite ? handleSCratchTrial() : confirmPurchase();
              }}
              disabled={!isPurchase}
            />
            <TButton title={t('cancel')} className="my-btn-cancel" onClick={handleCancel} />
          </div>

          <div className="note-confirm">{t('textNoteConfirmPurchase')}</div>
          <div className="note-confirm-sp">{t('textNoteConfirmPurchaseSp')}</div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmPurchase;
