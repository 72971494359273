// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './styles.scss';

export default function PointHistorySP(props) {
  const { data } = props;
  const history = useHistory();
  return (
    <div className="main-content point-history-sp">
      {data &&
        data.map((item, index) => (
          <div key={index}>
            <Table bordered borderless>
              <thead>
                <tr>
                  <th>
                    <div>{item.created_date}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={5}>
                    <span>
                      {(item.type === 3 || item.type === 4) && (
                        <div
                          className="name-lottery-sp"
                          onClick={() => history.push(`/lottery/${item.lottery_event_id}`)}
                        >
                          {item.lottery_event_name}
                        </div>
                      )}
                      {item.prize_name && <div className="move-to-detail">{item.prize_name}</div>}
                      <div className="d-flex-point-history">
                        <div className="d-flex-point-history">
                          {item.change_reason && (
                            <span className="move-to-detail">{item.change_reason}</span>
                          )}
                          {item.discard_number && (
                            <span className="move-to-detail number-point-sp">
                              {item.discard_number}
                            </span>
                          )}
                        </div>
                        <div
                          className="d-flex-point-history"
                          style={
                            Number(item.change_value.replace(',', '')) < 0
                              ? { color: '#F55076' }
                              : {}
                          }
                        >
                          <span className="move-to-detail number-point-sp text-right">
                            {item.change_value}
                          </span>
                          <span className="number-point-pt">pt</span>
                        </div>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ))}
      <div>※履歴機能開始以前の履歴は表示されません</div>
    </div>
  );
}
