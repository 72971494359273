// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import NotifyNews from '../../notifyNews';
import './headerPC.scss';

export default function HeaderPC(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    gotoLogin,
    isLogin,
    handleLogout,
    gotoMyCollection,
    gotoNews,
    gotoRegister,
    gotoMyPage,
    gotoPurchaseHistory,
  } = props;

  return (
    <header className="ol-header d-flex align-items-center justify-content-between">
      <div
        className="logo"
        onClick={() => {
          history.push('/');
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="167.6"
          height="33"
          viewBox="0 0 393.442 88.279"
        >
          <g id="Group_5684" data-name="Group 5684" transform="translate(-326.397 -208.44)">
            <rect
              id="Rectangle_2802"
              data-name="Rectangle 2802"
              width="13.642"
              height="82.986"
              transform="translate(326.397 212.551)"
              fill="#e63556"
            />
            <path
              id="Path_53386"
              data-name="Path 53386"
              d="M376.259,295.537,340.039,248.2,374.3,212.551h18.92L357.99,249.208l35.447,46.329Z"
              fill="#38a1db"
            />
            <path
              id="Path_53387"
              data-name="Path 53387"
              d="M432.235,246.893v27.116a9.737,9.737,0,1,1-19.473,0V246.89H399.786l0,27.119a22.709,22.709,0,1,0,45.418,0l0-27.116Z"
              fill="#f9be00"
            />
            <path
              id="Path_53388"
              data-name="Path 53388"
              d="M703.92,273.247l-4.1-64.807h20.02l-4.1,64.807Z"
              fill="#38a1db"
            />
            <path
              id="Path_53389"
              data-name="Path 53389"
              d="M710.051,296.718A8.951,8.951,0,1,1,719,287.766a8.962,8.962,0,0,1-8.952,8.952"
              fill="#e63556"
            />
            <rect
              id="Rectangle_2803"
              data-name="Rectangle 2803"
              width="13.642"
              height="82.986"
              transform="translate(568.642 212.551)"
              fill="#e63556"
            />
            <path
              id="Path_53390"
              data-name="Path 53390"
              d="M595.812,212.554H585.119v12.973h10.693a13.684,13.684,0,1,1,0,27.368H585.119v12.97l10.693,0a26.657,26.657,0,1,0,0-53.313"
              fill="#38a1db"
            />
            <rect
              id="Rectangle_2804"
              data-name="Rectangle 2804"
              width="12.974"
              height="48.647"
              transform="translate(537.57 246.89)"
              fill="#f9be00"
            />
            <path
              id="Path_53391"
              data-name="Path 53391"
              d="M549.109,220.485l13.372,9.362-16.2,8.91Z"
              fill="#38a1db"
            />
            <path
              id="Path_53392"
              data-name="Path 53392"
              d="M539,220.485l-13.372,9.362,16.2,8.91Z"
              fill="#e63556"
            />
            <path
              id="Path_53393"
              data-name="Path 53393"
              d="M457.986,225.527l-.151-12.975h45.7l-8.63,12.975Z"
              fill="#e63556"
            />
            <path
              id="Path_53394"
              data-name="Path 53394"
              d="M520.415,295.538H451.738l55.2-82.986h16.494l-46.567,70.01,43.553,0Z"
              fill="#38a1db"
            />
            <path
              id="Path_53395"
              data-name="Path 53395"
              d="M641.366,280.425l5.339-12.309h15.846l5.338,12.309Z"
              fill="#38a1db"
            />
            <path
              id="Path_53396"
              data-name="Path 53396"
              d="M653.083,246.286l-7.435-17.143-28.8,66.394h14.87Z"
              fill="#f9be00"
            />
            <path
              id="Path_53397"
              data-name="Path 53397"
              d="M692.4,295.537l-37.775-87.1-7.435,17.143,30.341,69.954Z"
              fill="#e63556"
            />
          </g>
        </svg>
      </div>
      <div className="menu d-flex">
        {isLogin ? (
          <div
            className="my-collection d-flex align-items-center justify-content-center"
            onClick={gotoMyCollection}
          >
            <svg
              id="card_giftcard_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#f55076"
            >
              <path id="Path_53318" data-name="Path 53318" d="M0,0H24V24H0Z" fill="none" />
              <path
                id="Path_53319"
                data-name="Path 53319"
                d="M20,6H17.82A2.993,2.993,0,0,0,12.5,3.35l-.5.67-.5-.68A2.994,2.994,0,0,0,6.18,6H4A1.985,1.985,0,0,0,2.01,8L2,19a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V8A1.993,1.993,0,0,0,20,6ZM15,4a1,1,0,1,1-1,1A1,1,0,0,1,15,4ZM9,4A1,1,0,1,1,8,5,1,1,0,0,1,9,4ZM20,19H4V17H20Zm0-5H4V8H9.08L7,10.83,8.62,12,11,8.76,12,7.4l1,1.36L15.38,12,17,10.83,14.92,8H20Z"
              />
            </svg>
            {t('menu.myCollection')}
          </div>
        ) : (
          <div />
        )}
        <div
          className="user-guide d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/guide');
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="レイヤー_1" data-name="レイヤー 1" transform="translate(-84 86)">
              <path
                id="Path_52141"
                data-name="Path 52141"
                d="M0,0H24V24H0Z"
                transform="translate(84 -86)"
                fill="none"
              />
              <path
                id="Path_52551"
                data-name="Path 52551"
                d="M32.39,20.541l5.632-5.631a1.158,1.158,0,0,0,.337-.813V4.169a1.152,1.152,0,0,0-1.967-.813l-4,4Z"
                transform="translate(63.641 -84.279)"
                fill="#40b569"
              />
              <path
                id="Path_52139"
                data-name="Path 52139"
                d="M9.783,19.718,14.6,14.9a1.158,1.158,0,0,0,.337-.813V4.161a1.152,1.152,0,0,0-1.967-.813l-4,4-4-4A1.152,1.152,0,0,0,3,4.161v9.926a1.158,1.158,0,0,0,.337.813l4.819,4.819a1.148,1.148,0,0,0,1.627,0Z"
                transform="translate(87 -84.271)"
                fill="none"
                stroke="#40b569"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </g>
          </svg>

          {t('menu.userGuide')}
        </div>
        <div className="wrap-notify">
          <div
            className="notice d-flex align-items-center justify-content-center"
            onClick={gotoNews}
          >
            <svg
              id="notifications_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#ff6c00"
            >
              <path id="Path_52137" data-name="Path 52137" d="M0,0H24V24H0Z" fill="none" />
              <path
                id="Path_52138"
                data-name="Path 52138"
                d="M12,22a2.006,2.006,0,0,0,2-2H10A2.006,2.006,0,0,0,12,22Zm6-6V11c0-3.07-1.63-5.64-4.5-6.32V4a1.5,1.5,0,0,0-3,0v.68C7.64,5.36,6,7.92,6,11v5L4,18v1H20V18Zm-2,1H8V11c0-2.48,1.51-4.5,4-4.5s4,2.02,4,4.5Z"
              />
            </svg>

            {t('menu.news')}
          </div>
          {isLogin && <NotifyNews />}
        </div>

        <div
          className="header-list-lottery d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/lottery');
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.371"
            height="16.695"
            viewBox="0 0 22.371 16.695"
          >
            <g
              id="Rectangle_4671"
              data-name="Rectangle 4671"
              transform="translate(1.371)"
              fill="none"
              stroke="#f55076"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
            >
              <rect width={21} height={15} stroke="none" />
              <rect x={1} y={1} width={19} height={13} fill="none" />
            </g>
            <path
              id="Path_57534"
              data-name="Path 57534"
              d="M1.678-.728l.549-2.2,7.1,11.254L0,6Z"
              transform="matrix(0.966, -0.259, 0.259, 0.966, 0.758, 8.655)"
              fill="#f55076"
            />
            <path
              id="Path_57535"
              data-name="Path 57535"
              d="M-8276.189,699.736l12.112,11.51"
              transform="translate(8278.859 -697)"
              fill="none"
              stroke="#f55076"
              strokeWidth={2}
            />
          </svg>

          {t('menu.listLottery')}
        </div>

        {!isLogin ? (
          <div
            className="register-account d-flex align-items-center justify-content-center"
            onClick={gotoRegister}
          >
            <svg
              id="sentiment_satisfied_alt_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path id="Path_53342" data-name="Path 53342" d="M0,0H24V24H0Z" fill="none" />
              <path id="Path_53343" data-name="Path 53343" d="M0,0H24V24H0Z" fill="none" />
              <circle
                id="Ellipse_28"
                data-name="Ellipse 28"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(14 8)"
              />
              <circle
                id="Ellipse_29"
                data-name="Ellipse 29"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(7 8)"
              />
              <circle
                id="Ellipse_30"
                data-name="Ellipse 30"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(14 8)"
                fill="#1d9ded"
              />
              <circle
                id="Ellipse_31"
                data-name="Ellipse 31"
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="translate(7 8)"
                fill="#1d9ded"
              />
              <path
                id="Path_53344"
                data-name="Path 53344"
                d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm0-2.5A5.5,5.5,0,0,0,17.12,14H15.45a3.976,3.976,0,0,1-6.9,0H6.88A5.5,5.5,0,0,0,12,17.5Z"
                fill="#1d9ded"
              />
            </svg>
            {t('menu.register')}
          </div>
        ) : (
          <div />
        )}
        <div className="sub-menu d-flex align-items-center justify-content-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="menu" transform="translate(-1069 -68)">
              <rect id="Box" width="24" height="24" transform="translate(1069 68)" fill="none" />
              <path
                id="Path_417"
                data-name="Path 417"
                d="M604-1400h18v-2H604Zm0-5h18v-2H604Zm0-7v2h18v-2Z"
                transform="translate(468 1486)"
                fill="#464646"
              />
            </g>
          </svg>
          {t('menu')}
          <div className="sub-menu-wrapper">
            <div id="icon-wrapper">
              <svg
                id="dropdown-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="12"
                viewBox="0 0 18 12"
              >
                <path
                  id="Polygon_5"
                  data-name="Polygon 5"
                  d="M9,0l9,12H0Z"
                  transform="translate(18 12) rotate(180)"
                  fill="#464646"
                  stroke="#fff"
                  strokeWidth={2}
                />
              </svg>
            </div>
            {isLogin ? (
              <div className="sub-menu-item-wrap">
                <div className="sub-menu-item" onClick={gotoMyPage}>
                  {t('menu.myPage')}
                </div>
                <div className="sub-menu-item" onClick={gotoPurchaseHistory}>
                  {t('menu.purchaseHistory')}
                </div>
                <div className="sub-menu-item" onClick={handleLogout}>
                  {t('logout')}
                </div>
              </div>
            ) : (
              <div className="sub-menu-item-wrap">
                <div className="sub-menu-item" onClick={gotoLogin}>
                  {t('login')}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
