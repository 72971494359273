// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './index.scss';
import { AiOutlineRight } from 'react-icons/ai';

const HistoryPurchase = (props) => {
  const { data } = props;

  if (!data) return <div />;

  return (
    <div className="history-purchase">
      <div className="table-title">このクジの購入履歴</div>
      {/* <div className="table-wrapper"> */}
      <table className="fixed_headers">
        <thead>
          <tr>
            <th className="history-date">購入日時</th>
            <th className="history-product">購入枚数</th>
            <th className="history-shippingTime">購入ID</th>
            <th className="history-details">詳細確認</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="text-center history-date">{item.created_at}</td>
              <td className="text-center history-product">
                {item.quantity}枚（破棄数：{item.total_discard}個）{' '}
              </td>
              <td className="text-center history-shippingTime">{item.purchase_id}</td>
              <td className="text-center history-details">
                <a href={`/order/history/${item.id}`} style={{ color: '#1D9DED' }}>
                  購入詳細を見る <AiOutlineRight />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* </div> */}
    </div>
  );
};

export default HistoryPurchase;
