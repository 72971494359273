// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function InformationPurchaseHistory(props) {
  const { dataOrders, data } = props;
  const paymentMethod = dataOrders?.payment_method;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <p className="name-lottery">{dataOrders?.name_lottery}</p>
      <div className="information-purchase-history">
        <div className="border-sp" />
        <div className="mt40">
          <p>{t('titlePurchaseIDTableHistoryMyCollection')}</p>
          <span>{dataOrders?.purchase_id}</span>
        </div>
        <div>
          <p>{t('textTrackingNumber')}</p>
          {dataOrders?.tracking_ship_number ? (
            <span>
              {dataOrders?.shippment_company_info}({dataOrders?.tracking_ship_number})
            </span>
          ) : (
            <span>※ 発送準備が整い次第表示されます。</span>
          )}
        </div>
        <div>
          <p>{t('textTimePurchase')}</p>
          <span>{dataOrders?.created_at}</span>
        </div>
        <div>
          <p>{t('textUnitPrice')}</p>
          <span>{dataOrders?.price_order?.toLocaleString('ja-JP')}円</span>
        </div>
        <div>
          <p>{t('textQuantity')}</p>
          <span>
            {`${dataOrders?.quantity}枚(お届け数:${data?.amount_prizes_received}個/破棄:${data?.total_discard}個)`}
          </span>
        </div>
        <div>
          <p>お届け数</p>
          <span>{data?.amount_prizes_received}個</span>
        </div>
        <div>
          <p>{t('textDateDiscard')}</p>
          <span>
            {dataOrders?.can_discard && (
              <div>
                破棄数変更期限 :<br />
                {dataOrders?.date_discard}
              </div>
            )}
            <span className="text-date-discard">
              ※破棄は
              <span
                className="move-to-my-collection"
                onClick={() => history.push('/my-collection')}
              >
                Myコレクション
              </span>
              ページより行うことができます。
            </span>
          </span>
        </div>
        <div>
          <p>{t('textShippingFee')}</p>
          <span>{dataOrders?.shipping_fee?.toLocaleString('ja-JP')}円</span>
        </div>
        <div>
          <p>{t('textTotalPricePurchase')}</p>
          <span>{dataOrders?.total_price?.toLocaleString('ja-JP')}円</span>
        </div>
        <div className="mb40">
          <p>{t('textPaymentMethod')}</p>
          <div className="d-flex-payment-sp">
            {paymentMethod?.credit_card && <span>{paymentMethod?.credit_card}</span>}
            {paymentMethod?.point ? (
              <span>{paymentMethod?.point}</span>
            ) : (
              <span>ポイント利用: 0Pt</span>
            )}
          </div>
          <div className="border-sp mt40" />
        </div>
      </div>
    </div>
  );
}
