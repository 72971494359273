// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import axios from 'axios';
import JSCookie from 'js-cookie';
import { URL_NOT_REDIRECT } from '../constants';

const olAxios = axios.create({
  baseURL: process.env.APP_ENDPOINT,
  timeout: 1000000,
});

const requestHandler = (request) => {
  const authToken = JSCookie.get('token');
  request.headers = {
    Accept: 'application/json',
    Authorization: `Bearer ${authToken}`,
    'Access-Control-Allow-Origin': '*',
  };
  return request;
};

const redirectToError = (response) => {
  switch (response?.status) {
    case 401:
      JSCookie.remove('token');
      if (!URL_NOT_REDIRECT.includes(window.location.pathname)) {
        window.location = `/login?callbackUrl=${encodeURIComponent(window.location.pathname)}`;
      } else {
        window.location = '/login';
      }
      break;
    case 403:
      window.location = '/error/403';
      break;
    case 404:
      window.location = '/error/404';
      break;
    case 503:
      window.location = '/maintain';
      break;
    case 504:
      window.location = '/error/504';
      break;
    default:
      break;
  }
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  const { response } = error;
  redirectToError(response);
  return Promise.reject(error);
};

olAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error),
);

olAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
);

export default {
  get: olAxios.get,
  post: olAxios.post,
  put: olAxios.put,
  delete: olAxios.delete,
  patch: olAxios.patch,
};
