// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import EndSaleBG from './top/EndSaleBG.png';
import NearEndSaleBG from './top/NearEndSaleBG.png';
import OnSaleBG from './top/OnSaleBG.png';
import OnScheduleBG from './top/OnScheduleBG.png';
import NewBG from './top/NewBG.png';

import GoToBuyLotteryIcon from './top/GoToBuyLotteryIcon.png';
import MyCollectionIcon from './top/MyCollectionIcon.png';
import UserGuideIcon from './top/UserGuideIcon.png';

import CircleRed from './top/CircleRed.png';
import CircleGreen from './top/CircleGreen.png';
import CircleOrange from './top/CircleOrange.png';

import PolygonRed from './top/PolygonRed.png';
import PolygonGreen from './top/PolygonGreen.png';
import PolygonOrange from './top/PolygonOrange.png';

import ScrollToTopImg from './top/ScrollToTop.png';

import VerticalColorfulLine from './top/VerticalColorfulLine.png';
import LineShort from './top/lineShort.png';

import Logo from './common/logo.png';
import OGP from './common/OGP.png';

import imgDescriptionWChange from './lotteryDetail/imgDescriptionWChange.png';
import imgDescriptionWChangeSP from './lotteryDetail/imgDescriptionWChangeSP.png';

import iconFacebook from './myPage/facebook.png';
import iconGoogle from './myPage/google.png';
import iconTwitter from './myPage/twitter.png';

import ScratchImg from './scratch/animationItem.png';
import ScratchScale from './scratch/scratch_scale_item_update.png';
import ScratchBackground from './scratch/ScratchBackground.png';
import SkipButton from './scratch/SkipButton.png';
import NextButton from './scratch/next_button.png';
import NewTag from './scratch/new_tag.png';
import WChanceBG from './scratch/w-chance-bg.png';
import WChanceTicket from './scratch/w-chance-ticket.png';
import WChanceMiss from './scratch/w-chance-miss-item.png';
import Congratulations from './scratch/congratulations.png';
import WChanceResultFooter from './scratch/w-chance-result-footer.png';

import GiftBoxYellow from './scratch/gifBoxYellow.gif';
import GiftBoxRed from './scratch//gifBoxRed.gif';
import GiftBoxBlue from './scratch/gifBoxBlue.gif';

import OpenBoxYellow from './scratch/openBoxYellow.gif';
import OpenBoxRed from './scratch/openBoxRed.gif';
import OpenBoxBlue from './scratch/openBoxBlue.gif';

import Box1 from './scratch/box_1.png';
import Box2 from './scratch/box_2.png';
import Box3 from './scratch/box_3.png';
import wChangeAnimation2 from './scratch/w_change_animation_2.png';

import docomoLogo from './purchaseDetail/docomoLogo.png';
import logoCredit from './purchaseDetail/logoCredit.png';
import softBank from './purchaseDetail/softBank.svg';

import iconLeft from './guide/iconLeft.png';
import iconRight from './guide/iconRight.png';
import firstLine from './guide/firstLine.png';
import secondLine from './guide/secondLine.png';
import lastLine from './guide/lastLine.png';
import icon1 from './guide/icon1.png';
import icon2 from './guide/icon2.png';
import icon3 from './guide/icon3.png';
import icon4 from './guide/icon4.png';
import icon5 from './guide/icon5.png';
import tabPink from './guide/tabPink.png';
import tabGreen from './guide/tabGreen.png';
import tabOrange from './guide/tabOrange.png';
import tabBlue from './guide/tabBlue.png';
import imageStep1 from './guide/imageStep1.png';
import imageStep2 from './guide/imageStep2.png';
import imageStep3 from './guide/imageStep3.png';
import imageStep4 from './guide/imageStep4.png';

import facebookIcon from './common/facebook.png';
import twitterIcon from './common/twitter.png';
import lineIcon from './common/line.png';

import contactImg1 from './jp/contact-img1.svg';
import contactImg2 from './jp/contact-img2.svg';
import exampleHint from './jp/example-hint.svg';
import headerLogo from './jp/header-logo.png';
import fvImg from './jp/fv-img.png';
import fvImgSp from './jp/fv-img-sp.png';
import worryImg1 from './jp/worry-img1.svg';
import worryImg2 from './jp/worry-img2.svg';
import worryImg3 from './jp/worry-img3.svg';
import worryImg4 from './jp/worry-img4.svg';
import solutionImg from './jp/solution-img.svg';
import solutionImg2 from './jp/solution-img2.svg';
import solutionImg3 from './jp/solution-img3.svg';
import whatsImg1 from './jp/whats-img1.svg';
import whatsImg2 from './jp/whats-img2.svg';
import whatsImg3 from './jp/whats-img3.svg';
import startLeft from './jp/start-left.svg';
import startRight from './jp/start-right.svg';
import startImg from './jp/start-img.png';
import reasonImg1 from './jp/reason-img1.svg';
import reasonImg2 from './jp/reason-img2.svg';
import reasonImg3 from './jp/reason-img3.svg';
import reasonImg4 from './jp/reason-img4.svg';
import reasonImg5 from './jp/reason-img5.svg';
import reasonImg6 from './jp/reason-img6.svg';
import serviceImgSp from './jp/service-img-sp.png';
import serviceImg from './jp/service-img.png';
import optionImg1 from './jp/option-img1.svg';
import optionImg2 from './jp/option-img2.svg';
import exampleImg1 from './jp/example-img1.svg';
import exampleImg2 from './jp/example-img2.svg';
import exampleTable from './jp/example-table.svg';
import footerLogo from './jp/header-logo.png';
import solutionBalloon from './jp/solution-balloon.svg';

const TOP_IMAGES = {
  LotteryStatus: {
    EndSaleBG,
    NearEndSaleBG,
    OnSaleBG,
    OnScheduleBG,
    NewBG,
  },
  Icons: {
    GoToBuyLotteryIcon,
    MyCollectionIcon,
    UserGuideIcon,
  },

  Bubbles: {
    CircleGreen,
    CircleOrange,
    CircleRed,
    PolygonGreen,
    PolygonRed,
    PolygonOrange,
  },
  VerticalColorfulLine,
  LineShort,
};

const LOTTERY_IMAGE = {
  imgDescriptionWChange,
  imgDescriptionWChangeSP,
};

const MY_PAGE = {
  iconFacebook,
  iconGoogle,
  iconTwitter,
};

const GUIDE = {
  iconLeft,
  iconRight,
  firstLine,
  secondLine,
  lastLine,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  tabPink,
  tabGreen,
  tabOrange,
  tabBlue,
  imageStep1,
  imageStep2,
  imageStep3,
  imageStep4,
};

const JP = {
  contactImg1,
  contactImg2,
  exampleHint,
  headerLogo,
  fvImg,
  fvImgSp,
  worryImg1,
  worryImg2,
  worryImg3,
  worryImg4,
  solutionImg,
  solutionImg2,
  solutionImg3,
  whatsImg1,
  whatsImg2,
  whatsImg3,
  startLeft,
  startRight,
  startImg,
  reasonImg1,
  reasonImg2,
  reasonImg3,
  reasonImg4,
  reasonImg5,
  reasonImg6,
  serviceImgSp,
  serviceImg,
  optionImg1,
  optionImg2,
  exampleImg1,
  exampleImg2,
  exampleTable,
  footerLogo,
  solutionBalloon,
};

export {
  Logo,
  TOP_IMAGES,
  ScrollToTopImg,
  LOTTERY_IMAGE,
  logoCredit,
  docomoLogo,
  softBank,
  ScratchImg,
  SkipButton,
  ScratchBackground,
  MY_PAGE,
  GUIDE,
  facebookIcon,
  twitterIcon,
  lineIcon,
  OGP,
  ScratchScale,
  NextButton,
  NewTag,
  Box1,
  Box2,
  Box3,
  WChanceBG,
  WChanceTicket,
  WChanceMiss,
  WChanceResultFooter,
  Congratulations,
  GiftBoxBlue,
  GiftBoxRed,
  GiftBoxYellow,
  OpenBoxYellow,
  OpenBoxBlue,
  OpenBoxRed,
  wChangeAnimation2,
  JP,
};
