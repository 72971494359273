// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DeliveryInfo from '../../components/pages/lotteryDetail/DeliveryInfo';
import FloatPanel from '../../components/pages/lotteryDetail/FloatPanel';
import InfoWChance from '../../components/pages/lotteryDetail/InfoWChance';
import LineUpAward from '../../components/pages/lotteryDetail/LineUpAward';
import NoteSP from '../../components/pages/lotteryDetail/NoteSP';
import OverViews from '../../components/pages/lotteryDetail/OverViews';
import PhotoArea from '../../components/pages/lotteryDetail/PhotoArea';
import { LotteryApis } from '../../services/lotteryDetail';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import queryString from 'query-string';
import './styles.scss';
import './customSlider.scss';
import useMessage from '../../components/common/toast/UseMessage';
import { listsErrorNotShowToast } from '../../constants';
import useMatatag from '../../hooks/useMatatag';

export default function LotteryDetail() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [data, setData] = useState();

  const pathLotteryTrial = '/trial';
  const pathSite = history.location.pathname;
  const { id } = useParams();

  useMatatag({
    title: data?.lottery_event?.name,
    description: data?.lottery_event?.description,
    url: `${process.env.APP_DOMAIN}lottery/${id}`,
    image: data?.lottery_event?.image?.image_url,
    keyword: 'くじぱ, クジパ, kuzipa,オンラインスクラッチくじ,' + data?.lottery_event?.name,
    twitterCard: 'summary_large_image',
    twitterSite: '@KuZiPA_jp',
  });

  const search = queryString.parse(history.location.search);

  const { openMessageError } = useMessage();

  const getLotteryDetail = async () => {
    dispatch(showLoading());
    try {
      const res = await LotteryApis.getDetail(id, search.verify ? search : {});
      const { data } = res;
      setData(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getLotteryTrialDetail = async () => {
    dispatch(showLoading());
    try {
      const res = await LotteryApis.getDetailTrial();
      const { data } = res;
      setData(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    if (pathSite === pathLotteryTrial) {
      getLotteryTrialDetail();
    } else {
      getLotteryDetail();
    }
  }, []);

  return (
    <>
      {data && (
        <>
          <div className="my-lottery-detail">
            <PhotoArea data={data} />
            <OverViews data={data} />
            <LineUpAward data={data} />
            <InfoWChance data={data} />
          </div>
          <NoteSP />
          <DeliveryInfo />
          <FloatPanel data={data} />
        </>
      )}
    </>
  );
}
