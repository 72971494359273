// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Modal } from 'react-bootstrap';
import MediaQuery from 'react-responsive';

import useMessage from '../../../components/common/toast/UseMessage';
import TopHeader from '../../../components/common/topHeader';
import ScratchItem from '../../../components/pages/scratch/scratchRetail/ScratchItem';
import { PurchaseApis } from '../../../services/purchase';
import { hideLoading, showLoading } from '../../../store/features/loadingPage';

import './index.scss';
import { useHistory } from 'react-router-dom';
import {
  GiftBoxBlue,
  GiftBoxRed,
  GiftBoxYellow,
  OpenBoxYellow,
  OpenBoxBlue,
  OpenBoxRed,
  ScratchBackground,
  SkipButton,
  ScratchScale,
  NextButton,
  NewTag,
  Box1,
  Box2,
  Box3,
  WChanceBG,
  WChanceTicket,
  WChanceMiss,
  Congratulations,
  wChangeAnimation2,
} from '../../../../assets/imgs';
import { ON_CHALLENGE, listsErrorNotShowToast, BREAK_POINT } from '../../../constants';

const BoxAnimation = styled.div`
  ${(props) => props.isShow && 'animation: zoom-in-zoom-out 2s ease-in-out normal;'}
`;

const ScratchWrapper = styled.div`
  background-image: url(${ScratchBackground});
  background-size: 100% 100%;
`;

const ScratchWChanceWrapper = styled.div`
  background-image: url(${WChanceBG});
  background-size: 100% 100%;
`;

const ButtonSkip = styled.div`
  background-image: url(${SkipButton});
  width: 165px;
  height: 52px;
  background-size: 165px 52px;
  position: absolute;
  top: 22px;
  right: 25px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    top: 10px;
    right: 10px;
    width: 90px;
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;
const ButtonSkipCustom = styled.div`
  background-image: url(${SkipButton});
  width: 165px;
  height: 52px;
  background-size: 165px 52px;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    width: 90px;
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const STEP = {
  OPEN_MODAL: 1,
  SHOW_SCRATCH: 2,
  SCRATCH: 3,
  SHOW_RESULT: 4,
  SHOW_W_CHANCE: 5,
  SHOW_W_CHANCE_TICKET: 6,
  SHOW_W_CHANCE_ANIMATION_1: 7,
  SHOW_W_CHANCE_ANIMATION_2: 8,
  SHOW_W_CHANCE_RESULT: 9,
};

export const STEP_OPEN_BOX = {
  CLOSE: 0,
  OPEN: 1,
  PUSH_CARD: 2,
};

export default function ScratchUI(props) {
  const { orderId, type, setAdultPurchase } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const pathSite = history.location.pathname;
  const pathLotteryTrial = ['/scratch/trial/retail', '/scratch/trial/package'];
  const { openMessageError } = useMessage();

  const [prizesData, setPrizesData] = useState([]);
  const [animations, setAnimations] = useState([]);
  const [wChanceData, setWChanceData] = useState(undefined);
  const [wChanceFlag, setWChanceFlag] = useState(false);

  const [isShowModalScratch, setShowModalScratch] = useState(false);

  const [stepOpenBox, setStepOpenBox] = useState(STEP_OPEN_BOX.CLOSE);
  const [selectedBoxColor, setColor] = useState('');

  const [rankACounter, setRankACounter] = useState(0);
  const [rankBCounter, setRankBCounter] = useState(0);
  const [rankCCounter, setRankCCounter] = useState(0);
  const [rankDCounter, setRankDCounter] = useState(0);
  const [rankECounter, setRankECounter] = useState(0);

  const [step, setStep] = useState(STEP.OPEN_MODAL);
  const [isScratchWChance, setScratchWChance] = useState(false);

  const [counter, setCounter] = useState(0);

  const convertRankNameToAnimation = (rankName) => {
    switch (rankName) {
      case 'A':
        return step === STEP.SHOW_SCRATCH ? animations.image_rank_a[0] : animations.image_rank_a[1];
      case 'B':
        return step === STEP.SHOW_SCRATCH ? animations.image_rank_b[0] : animations.image_rank_b[1];
      default:
        return animations.image_normal;
    }
  };

  const calcRankQuantity = (rankName) => {
    switch (rankName) {
      case 'A':
        return setRankACounter(rankACounter + 1);
      case 'B':
        return setRankBCounter(rankBCounter + 1);
      case 'C':
        return setRankCCounter(rankCCounter + 1);
      case 'D':
        return setRankDCounter(rankDCounter + 1);
      case 'E':
        return setRankECounter(rankECounter + 1);
    }
  };

  // FAKE_POP_UP_UI
  useEffect(() => {
    const content = document.getElementsByClassName('content');
    content[0].style.backgroundColor = '#707070';
    return () => (content[0].style.backgroundColor = 'unset');
  });

  const [adult, setAdult] = useState();
  const stateScratchTrial = history?.location?.state;

  const [dataTrial, setDataTrial] = useState();
  const handleMoveResult = (id) => {
    if (pathLotteryTrial.includes(pathSite)) {
      history.push({ pathname: '/result/trial', state: dataTrial });
    } else {
      history.push(`/result?order_id=${id}`);
    }
  };

  useEffect(async () => {
    dispatch(showLoading());
    try {
      const res = pathLotteryTrial.includes(pathSite)
        ? await PurchaseApis.scratchTrial({
            quantity: stateScratchTrial?.quantity,
            adultPurchase: stateScratchTrial?.adultPurchaseFlag,
          })
        : await PurchaseApis.scratch({
            order_id: orderId,
          });

      const { data } = res;

      setAdult(data?.adult_purchase_flag);
      setDataTrial(data);
      setAdultPurchase(data?.adult_purchase_flag);
      setWChanceFlag(data.have_on_challenge);
      setAnimations(data.animations);
      const __prizeData = data.prizes.filter((item) => item.rank_name !== ON_CHALLENGE);
      if (__prizeData) {
        setPrizesData(__prizeData);
      }
      const __wChanceData = data.prizes.find((item) => item.rank_name === ON_CHALLENGE);
      if (__wChanceData) {
        setWChanceData(__wChanceData);
      }
      if (!__prizeData.length && !__wChanceData) {
        handleMoveResult(orderId);
      }
    } catch (err) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  }, []);

  const handleNextStep = (rankName = '') => {
    switch (step) {
      case STEP.OPEN_MODAL:
        if (['A', 'B'].includes(rankName)) {
          setStep(STEP.SHOW_SCRATCH);
        } else {
          setStep(STEP.SCRATCH);
        }
        break;
      case STEP.SHOW_SCRATCH:
        setStep(STEP.SCRATCH);
        break;
      case STEP.SCRATCH:
        setStep(STEP.SHOW_RESULT);
        break;
      case STEP.SHOW_RESULT:
        calcRankQuantity(prizesData[counter].rank_name);
        if (counter >= prizesData.length - 1) {
          if (!wChanceFlag && typeof wChanceData === 'undefined') {
            setStep(STEP.OPEN_MODAL);
            handleMoveResult(orderId);
          } else {
            setStep(STEP.SHOW_W_CHANCE);
            setScratchWChance(true);
          }
        } else {
          setStep(STEP.OPEN_MODAL);
          if (counter < prizesData.length) {
            setCounter(counter + 1);
          }
        }
        setShowModalScratch(false);
        break;
      case STEP.SHOW_W_CHANCE:
        setStep(STEP.SHOW_W_CHANCE_TICKET);
        setShowModalScratch(true);
        break;
      case STEP.SHOW_W_CHANCE_TICKET:
        setStep(STEP.SHOW_W_CHANCE_ANIMATION_1);
        break;
      case STEP.SHOW_W_CHANCE_ANIMATION_1:
        if (isEmpty(wChanceData)) {
          setStep(STEP.SHOW_W_CHANCE_RESULT);
        } else {
          setStep(STEP.SHOW_W_CHANCE_ANIMATION_2);
        }
        break;
      case STEP.SHOW_W_CHANCE_ANIMATION_2:
        setStep(STEP.SHOW_W_CHANCE_RESULT);
        break;
      default:
        setScratchWChance(false);
        setShowModalScratch(false);
        setStep(STEP.OPEN_MODAL);
        handleMoveResult(orderId);
        break;
    }
  };

  const convertTime = (rankName, order) => {
    let time = 0;

    if (order === 0) {
      switch (rankName) {
        case 'A':
          time = animations?.time_rank_a ? animations?.time_rank_a[0] : 0;
          break;

        case 'B':
          time = animations?.time_rank_b ? animations?.time_rank_b[0] : 0;
          break;

        default:
          time = 2000;
          break;
      }
    } else {
      switch (rankName) {
        case 'A':
          time = animations?.time_rank_a ? animations?.time_rank_a[1] : 0;
          break;

        case 'B':
          time = animations?.time_rank_b ? animations?.time_rank_b[1] : 0;
          break;

        default:
          time = 2000;
          break;
      }
    }
    return time;
  };

  useEffect(() => {
    if (isShowModalScratch) {
      const interval = setInterval(function () {
        if (step === STEP.SHOW_W_CHANCE_ANIMATION_1 || step === STEP.SHOW_W_CHANCE_ANIMATION_2) {
          handleNextStep();
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [step]);

  useEffect(() => {
    if (isShowModalScratch) {
      const interval = setInterval(function () {
        if (step === STEP.SCRATCH) {
          handleNextStep();
        }
      }, convertTime(prizesData[counter]?.rank_name, 1));
      return () => clearInterval(interval);
    }
  }, [step]);

  useEffect(() => {
    if (isShowModalScratch) {
      const interval = setInterval(function () {
        if (step === STEP.SHOW_SCRATCH) {
          handleNextStep();
        }
      }, convertTime(prizesData[counter]?.rank_name, 0));
      return () => clearInterval(interval);
    }
  }, [step]);

  useEffect(() => {
    if (type === 'package' && stepOpenBox === STEP_OPEN_BOX.PUSH_CARD) {
      let interval = null;

      // WAIT 750 MilI SECOND FOR SHOW ANIMATION PUSH CARD
      setTimeout(() => {
        if (counter < prizesData.length) {
          interval = setInterval(function () {
            if (['A', 'B', 'C'].includes(prizesData[counter].rank_name)) {
              if (!isScratchWChance) {
                setShowModalScratch(true);
              }
            } else {
              if (counter < prizesData.length) {
                setCounter(counter + 1);
              }
            }
          }, 750);
          if (!['A', 'B', 'C'].includes(prizesData[counter].rank_name)) {
            calcRankQuantity(prizesData[counter].rank_name);
          }
        } else {
          if (typeof wChanceData === 'undefined' && !wChanceFlag) {
            handleMoveResult(orderId);
          } else {
            setStep(STEP.SHOW_W_CHANCE);
            setScratchWChance(true);
          }
        }
      }, 200);

      return () => clearInterval(interval);
    }
  }, [type, stepOpenBox, prizesData, counter, isScratchWChance]);

  const renderListScratchItem = () => {
    const result = [];
    for (let i = 0; i < 6; i++) {
      result.push(<ScratchItem onClickItem={() => setShowModalScratch(true)} />);
    }
    return result;
  };

  const formatNumber = (number, isDE = false) => {
    if (number <= 9) {
      return (
        <>
          <MediaQuery minWidth={BREAK_POINT + 1}>
            <>
              {isDE ? (
                <>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="46"
                      viewBox="0 0 24 46"
                    >
                      <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                        <rect
                          id="Rectangle_4207"
                          data-name="Rectangle 4207"
                          width="24"
                          height="32"
                          transform="translate(-109 307.635)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(-106 334.635)"
                          fill="#fff"
                          fontSize="32"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            0
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="46"
                      viewBox="0 0 24 46"
                    >
                      <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                        <rect
                          id="Rectangle_4207"
                          data-name="Rectangle 4207"
                          width="24"
                          height="32"
                          transform="translate(-109 307.635)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(-106 334.635)"
                          fill="#fff"
                          fontSize="32"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            {number}
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="70"
                      viewBox="0 0 40 70"
                    >
                      <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                        <rect
                          id="Rectangle_4216"
                          data-name="Rectangle 4216"
                          width="40"
                          height="50"
                          transform="translate(713 1060)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(719 1103)"
                          fill="#fff"
                          fontSize="48"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            0
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="70"
                      viewBox="0 0 40 70"
                    >
                      <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                        <rect
                          id="Rectangle_4216"
                          data-name="Rectangle 4216"
                          width="40"
                          height="50"
                          transform="translate(713 1060)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(719 1103)"
                          fill="#fff"
                          fontSize="48"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            {number}
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                </>
              )}
            </>
          </MediaQuery>
          <MediaQuery maxWidth={BREAK_POINT}>
            <>
              {isDE ? (
                <>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="32"
                      viewBox="0 0 19 32"
                    >
                      <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                        <rect
                          id="Rectangle_4215"
                          data-name="Rectangle 4215"
                          width="19"
                          height="24"
                          transform="translate(-49 346.635)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(-46 366.635)"
                          fill="#fff"
                          fontSize="22"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            0
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="32"
                      viewBox="0 0 19 32"
                    >
                      <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                        <rect
                          id="Rectangle_4215"
                          data-name="Rectangle 4215"
                          width="19"
                          height="24"
                          transform="translate(-49 346.635)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(-46 366.635)"
                          fill="#fff"
                          fontSize="22"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            {number}
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="46"
                      viewBox="0 0 24 46"
                    >
                      <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                        <rect
                          id="Rectangle_4207"
                          data-name="Rectangle 4207"
                          width="24"
                          height="32"
                          transform="translate(-109 307.635)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(-106 334.635)"
                          fill="#fff"
                          fontSize="32"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            0
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="46"
                      viewBox="0 0 24 46"
                    >
                      <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                        <rect
                          id="Rectangle_4207"
                          data-name="Rectangle 4207"
                          width="24"
                          height="32"
                          transform="translate(-109 307.635)"
                          fill="#464646"
                        />
                        <text
                          id="_0"
                          data-name="0"
                          transform="translate(-106 334.635)"
                          fill="#fff"
                          fontSize="32"
                          fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                          fontWeight="500"
                        >
                          <tspan x="0" y="0">
                            {number}
                          </tspan>
                        </text>
                      </g>
                    </svg>
                  </div>
                </>
              )}
            </>
          </MediaQuery>
        </>
      );
    }
    return (
      <>
        <MediaQuery minWidth={BREAK_POINT + 1}>
          <>
            {isDE ? (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {Math.floor(number / 10)}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number % 10}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            ) : (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="70"
                    viewBox="0 0 40 70"
                  >
                    <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                      <rect
                        id="Rectangle_4216"
                        data-name="Rectangle 4216"
                        width="40"
                        height="50"
                        transform="translate(713 1060)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(719 1103)"
                        fill="#fff"
                        fontSize="48"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {Math.floor(number / 10)}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="70"
                    viewBox="0 0 40 70"
                  >
                    <g id="Group_7366" data-name="Group 7366" transform="translate(-713 -1047)">
                      <rect
                        id="Rectangle_4216"
                        data-name="Rectangle 4216"
                        width="40"
                        height="50"
                        transform="translate(713 1060)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(719 1103)"
                        fill="#fff"
                        fontSize="48"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number % 10}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            )}
          </>
        </MediaQuery>
        <MediaQuery maxWidth={BREAK_POINT}>
          <>
            {isDE ? (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="32"
                    viewBox="0 0 19 32"
                  >
                    <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                      <rect
                        id="Rectangle_4215"
                        data-name="Rectangle 4215"
                        width="19"
                        height="24"
                        transform="translate(-49 346.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-46 366.635)"
                        fill="#fff"
                        fontSize="22"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {Math.floor(number / 10)}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="32"
                    viewBox="0 0 19 32"
                  >
                    <g id="Group_7368" data-name="Group 7368" transform="translate(49 -340.635)">
                      <rect
                        id="Rectangle_4215"
                        data-name="Rectangle 4215"
                        width="19"
                        height="24"
                        transform="translate(-49 346.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-46 366.635)"
                        fill="#fff"
                        fontSize="22"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number % 10}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            ) : (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {Math.floor(number / 10)}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="46"
                    viewBox="0 0 24 46"
                  >
                    <g id="Group_7367" data-name="Group 7367" transform="translate(109 -297.635)">
                      <rect
                        id="Rectangle_4207"
                        data-name="Rectangle 4207"
                        width="24"
                        height="32"
                        transform="translate(-109 307.635)"
                        fill="#464646"
                      />
                      <text
                        id="_0"
                        data-name="0"
                        transform="translate(-106 334.635)"
                        fill="#fff"
                        fontSize="32"
                        fontFamily="NotoSansCJKjp-Medium, Noto Sans CJK JP"
                        fontWeight="500"
                      >
                        <tspan x="0" y="0">
                          {number % 10}
                        </tspan>
                      </text>
                    </g>
                  </svg>
                </div>
              </>
            )}
          </>
        </MediaQuery>
      </>
    );
  };

  const formatCountNumber = (number) => {
    if (number < 10) {
      return `00${number}`;
    }
    if (number < 100) {
      return `0${number}`;
    }
    if (number < 1000) return `${number}`;
    return number;
  };

  useEffect(() => {
    if (stepOpenBox === STEP_OPEN_BOX.OPEN) {
      setTimeout(() => {
        setStepOpenBox(STEP_OPEN_BOX.PUSH_CARD);
      }, 2700);
    }
  }, [stepOpenBox]);

  const [isCheckBox, setIsCheckBox] = useState(true);
  const handleOpenBox = (type) => {
    setColor(type);
    setIsCheckBox(false);
    setTimeout(() => {
      setStepOpenBox(1);
    }, 2000);
  };

  return (
    <>
      <TopHeader />
      <div className="popup-wrapper">
        {isScratchWChance ? (
          <ScratchWChanceWrapper className="scratch-wrapper">
            <ButtonSkip
              onClick={() => handleMoveResult(orderId)}
              style={{
                display:
                  step === STEP.SHOW_W_CHANCE_TICKET ||
                  step === STEP.SHOW_W_CHANCE_ANIMATION_1 ||
                  step === STEP.SHOW_W_CHANCE_ANIMATION_2
                    ? 'none'
                    : 'block',
              }}
            />
            <div
              id="w-change-ticket"
              onClick={() => handleNextStep()}
              style={{ cursor: 'pointer' }}
            >
              <img src={WChanceTicket} width="100%" height="100%" />
            </div>

            <div id="footer-scratch">
              <span>当たりが出れば＋ON賞GET！</span>
            </div>
          </ScratchWChanceWrapper>
        ) : (
          <ScratchWrapper className="scratch-wrapper">
            <div
              style={{
                visibility: isShowModalScratch ? 'hidden' : 'visible',
                opacity: isShowModalScratch ? 0 : 1,
                transition: 'all 0.1s ease-in-out',
              }}
            >
              <div id="quantity">
                <span>
                  {formatCountNumber(counter + 1) <= formatCountNumber(prizesData.length)
                    ? formatCountNumber(counter + 1)
                    : formatCountNumber(prizesData.length)}{' '}
                  / {formatCountNumber(prizesData.length)}
                </span>
              </div>
              <ButtonSkip onClick={() => handleMoveResult(orderId)} />
            </div>

            {type === 'package' ? (
              <div className="content-scratch-package-wrapper">
                {stepOpenBox ? (
                  <div className="animation" style={{ overflow: 'hidden' }}>
                    <div style={{ display: selectedBoxColor === 'Yellow' ? 'block' : 'none' }}>
                      <img
                        src={OpenBoxYellow}
                        alt="animation"
                        id="animation_open_box"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'unset' : 'none',
                        }}
                      />
                      <img
                        src={GiftBoxYellow}
                        width="100%"
                        id="animation_push_card"
                        style={{ display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'none' : 'block' }}
                      />
                    </div>
                    <div style={{ display: selectedBoxColor === 'Red' ? 'block' : 'none' }}>
                      <img
                        src={OpenBoxRed}
                        alt="animation"
                        id="animation_open_box"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'unset' : 'none',
                        }}
                      />
                      <img
                        src={GiftBoxRed}
                        width="100%"
                        id="animation_push_card"
                        style={{ display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'none' : 'block' }}
                      />
                    </div>
                    <div style={{ display: selectedBoxColor === 'Blue' ? 'block' : 'none' }}>
                      <img
                        src={OpenBoxBlue}
                        alt="animation"
                        id="animation_open_box"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'unset' : 'none',
                        }}
                      />
                      <img
                        src={GiftBoxBlue}
                        width="100%"
                        id="animation_push_card"
                        style={{ display: stepOpenBox === STEP_OPEN_BOX.OPEN ? 'none' : 'block' }}
                      />
                    </div>
                  </div>
                ) : (
                  <div id="box-wrapper">
                    <BoxAnimation
                      className="box"
                      id="box1"
                      onClick={isCheckBox ? () => handleOpenBox('Yellow') : () => {}}
                      isShow={selectedBoxColor === 'Yellow'}
                    >
                      <img src={Box1} width="100%" height="100%" />
                    </BoxAnimation>
                    <div className="d-flex justify-content-center">
                      <BoxAnimation
                        className="box"
                        id="box2"
                        onClick={isCheckBox ? () => handleOpenBox('Red') : () => {}}
                        isShow={selectedBoxColor === 'Red'}
                      >
                        <img src={Box2} width="100%" height="100%" />
                      </BoxAnimation>
                      <BoxAnimation
                        className="box"
                        id="box3"
                        onClick={isCheckBox ? () => handleOpenBox('Blue') : () => {}}
                        isShow={selectedBoxColor === 'Blue'}
                      >
                        <img src={Box3} width="100%" height="100%" />
                      </BoxAnimation>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="d-flex flex-wrap content-scratch-wrapper">
                {renderListScratchItem()}
              </div>
            )}

            {stepOpenBox ? (
              <div id="scratch-package-result-wrapper">
                <div id="abc-wrapper">
                  <div className="result">
                    <div className="result__rank-name">
                      <MediaQuery minWidth={BREAK_POINT + 1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="68"
                          height="84"
                          viewBox="0 0 68 84"
                        >
                          <text
                            id="A賞"
                            transform="translate(0 67)"
                            fill="#fff"
                            fontSize="58"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              A
                            </tspan>
                            <tspan y="0" fontSize="26">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                      <MediaQuery maxWidth={BREAK_POINT}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="41"
                          height="54"
                          viewBox="0 0 41 54"
                        >
                          <text
                            id="A賞"
                            transform="translate(1 43)"
                            fill="#fff"
                            fontSize="37"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              A
                            </tspan>
                            <tspan y="0" fontSize="16">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                    </div>
                    <div className="result_quantity">{formatNumber(rankACounter)}</div>
                  </div>
                  <div className="result">
                    <div className="result__rank-name">
                      <MediaQuery minWidth={BREAK_POINT + 1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="68"
                          height="84"
                          viewBox="0 0 68 84"
                        >
                          <text
                            id="B賞"
                            transform="translate(0 67)"
                            fill="#fff"
                            fontSize="58"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              B
                            </tspan>
                            <tspan y="0" fontSize="26">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                      <MediaQuery maxWidth={BREAK_POINT}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="41"
                          height="54"
                          viewBox="0 0 41 54"
                        >
                          <text
                            id="B賞"
                            transform="translate(1 43)"
                            fill="#fff"
                            fontSize="37"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              B
                            </tspan>
                            <tspan y="0" fontSize="16">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                    </div>
                    <div className="result_quantity">{formatNumber(rankBCounter)}</div>
                  </div>
                  <div className="result">
                    <div className="result__rank-name">
                      <MediaQuery minWidth={BREAK_POINT + 1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="68"
                          height="84"
                          viewBox="0 0 68 84"
                        >
                          <text
                            id="C賞"
                            transform="translate(0 67)"
                            fill="#fff"
                            fontSize="58"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              C
                            </tspan>
                            <tspan y="0" fontSize="26">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                      <MediaQuery maxWidth={BREAK_POINT}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="41"
                          height="54"
                          viewBox="0 0 41 54"
                        >
                          <text
                            id="C賞"
                            transform="translate(1 43)"
                            fill="#fff"
                            fontSize="37"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              C
                            </tspan>
                            <tspan y="0" fontSize="16">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                    </div>
                    <div className="result_quantity">{formatNumber(rankCCounter)}</div>
                  </div>
                </div>
                <div id="de-wrapper">
                  <div className="result-de">
                    <div className="result__rank-name result__rank-name_D_E">
                      <MediaQuery minWidth={BREAK_POINT + 1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="41"
                          height="54"
                          viewBox="0 0 41 54"
                        >
                          <text
                            id="D賞"
                            transform="translate(1 43)"
                            fill="#fff"
                            fontSize="37"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              D
                            </tspan>
                            <tspan y="0" fontSize="16">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                      <MediaQuery maxWidth={BREAK_POINT}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="39"
                          viewBox="0 0 32 39"
                        >
                          <text
                            id="D賞"
                            transform="translate(0 31)"
                            fill="#fff"
                            fontSize="27"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              D
                            </tspan>
                            <tspan y="0" fontSize="12">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                    </div>
                    <div className="result_quantity">{formatNumber(rankDCounter, true)}</div>
                  </div>
                  <div className="result-de">
                    <div className="result__rank-name result__rank-name_D_E">
                      <MediaQuery minWidth={BREAK_POINT + 1}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="41"
                          height="54"
                          viewBox="0 0 41 54"
                        >
                          <text
                            id="E賞"
                            transform="translate(1 43)"
                            fill="#fff"
                            fontSize="37"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              E
                            </tspan>
                            <tspan y="0" fontSize="16">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                      <MediaQuery maxWidth={BREAK_POINT}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="39"
                          viewBox="0 0 32 39"
                        >
                          <text
                            id="E賞"
                            transform="translate(0 31)"
                            fill="#fff"
                            fontSize="27"
                            fontFamily="NotoSansCJKjp-Bold, Noto Sans CJK JP"
                            fontWeight="700"
                          >
                            <tspan x="0" y="0">
                              E
                            </tspan>
                            <tspan y="0" fontSize="12">
                              賞
                            </tspan>
                          </text>
                        </svg>
                      </MediaQuery>
                    </div>
                    <div className="result_quantity">{formatNumber(rankECounter, true)}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div id="footer-scratch">
                {Number(adult) === 1 && <span>好きなプレゼントを選んでね！</span>}
                {Number(adult) === 0 && <span>好きなクジを選んでね！</span>}
              </div>
            )}
          </ScratchWrapper>
        )}
      </div>

      <Modal
        size="lg"
        show={isShowModalScratch}
        centered
        className="modal-scratch"
        animation={false}
      >
        <Modal.Body>
          <div
            className={type === 'package' ? 'scratch-content my-ml-15px-unset' : 'scratch-content'}
          >
            {step !== STEP.SHOW_W_CHANCE_RESULT && (
              <div
                className="skip-quantity-custom"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: step === STEP.SHOW_RESULT ? '120px' : '20px',
                }}
              >
                <div
                  className="quantity-custom-modal"
                  style={{
                    visibility:
                      step === STEP.SHOW_W_CHANCE_TICKET ||
                      step === STEP.SHOW_W_CHANCE_ANIMATION_1 ||
                      step === STEP.SHOW_W_CHANCE_ANIMATION_2
                        ? 'hidden'
                        : 'visible',
                  }}
                >
                  <span>
                    {formatCountNumber(counter + 1) <= formatCountNumber(prizesData.length)
                      ? formatCountNumber(counter + 1)
                      : formatCountNumber(prizesData.length)}{' '}
                    / {formatCountNumber(prizesData.length)}
                  </span>
                </div>
                <div className="skip-btn-custom-modal">
                  <ButtonSkipCustom onClick={() => handleMoveResult(orderId)} />
                </div>
              </div>
            )}

            <div
              onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
              className={
                (step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData)) ||
                step === STEP.SHOW_RESULT ||
                step === STEP.SHOW_W_CHANCE_ANIMATION_2
                  ? 'wrap_scratch_item_scale height-unset width-custom'
                  : step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData)
                  ? 'wrap_scratch_item_scale width-custom-no-data'
                  : 'wrap_scratch_item_scale'
              }
            >
              <div
                style={{
                  visibility: step === STEP.OPEN_MODAL ? 'visible' : 'hidden',
                  opacity: step === STEP.OPEN_MODAL ? 1 : 0,
                  transition: 'all 0.1s linear',
                  position: 'relative',
                }}
              >
                <img src={ScratchScale} className="scratch-item-scale" />
                <div className="wrap_scratch_item_scale_bg" />
              </div>
              {/* {(step === STEP.SCRATCH || step === STEP.SHOW_SCRATCH) && ( */}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  display: step === STEP.SCRATCH || step === STEP.SHOW_SCRATCH ? 'block' : 'none',
                  opacity: step === STEP.SCRATCH || step === STEP.SHOW_SCRATCH ? 1 : 0,
                  zIndex: 7,
                }}
              >
                <img
                  src={convertRankNameToAnimation(prizesData[counter]?.rank_name)}
                  className="scratch-item-scale"
                />
              </div>

              {/* {step === STEP.SHOW_RESULT && ( */}
              <div
                id="result-scratch"
                style={{
                  position: 'absolute',
                  top: '-100px',
                  display: step === STEP.SHOW_RESULT ? 'block' : 'none',
                  opacity: step === STEP.SHOW_RESULT ? 1 : 0,
                  // transition: 'all 0.1s linear',
                  zIndex: 8,
                }}
              >
                {prizesData[counter]?.is_new && <img src={NewTag} id="new-tag" />}
                <img src={prizesData[counter]?.image?.image_url} id="scratch-item-prize" />
                <div id="prize-name">
                  <div>
                    <span className="prize-name__rank-name">{prizesData[counter]?.rank_name}</span>
                    <span className="prize-name__sub-rank-name">賞</span>
                  </div>
                  <div className="prize-name__title">
                    {prizesData[counter]?.rank_name} - {prizesData[counter]?.number_order}{' '}
                    {prizesData[counter]?.title}
                  </div>
                </div>
                <div className="btn-next">
                  <img
                    src={NextButton}
                    onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
                  />
                </div>
              </div>

              <div>
                <img
                  src={WChanceTicket}
                  className="scratch-item-scale"
                  style={{
                    position: 'absolute',
                    top: '0',
                    visibility: step === STEP.SHOW_W_CHANCE_TICKET ? 'visible' : 'hidden',
                    opacity: step === STEP.SHOW_W_CHANCE_TICKET ? 1 : 0,
                    zIndex: 9,
                  }}
                />
                <div
                  className="wrap_scratch_item_scale_bg"
                  style={{
                    display: step === STEP.SHOW_W_CHANCE_TICKET ? 'block' : 'none',
                  }}
                />
              </div>

              {/* {step === STEP.SHOW_W_CHANCE_ANIMATION_1 && ( */}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  display: step === STEP.SHOW_W_CHANCE_ANIMATION_1 ? 'block' : 'none',
                  zIndex: 10,
                }}
              >
                <img
                  src={animations.image_on_challenge ? animations.image_on_challenge[0] : ''}
                  className="scratch-item-scale"
                />
              </div>
              {/* {step === STEP.SHOW_W_CHANCE_ANIMATION_2 && ( */}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  visibility: step === STEP.SHOW_W_CHANCE_ANIMATION_2 ? 'visible' : 'hidden',
                  transition: 'all 0.1s ease-im-out',
                  zIndex: 11,
                }}
              >
                <img
                  src={animations.image_on_challenge ? animations.image_on_challenge[1] : ''}
                  className="scratch-item-scale scratch-item-scale-custom"
                  style={{
                    backgroundImage: `url(${wChangeAnimation2})`,
                  }}
                />
                <div className="wrap_scratch_item_scale_bg wrap_scratch_item_scale_bg-custom" />
              </div>

              {/* {step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData) && ( */}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  visibility:
                    step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData)
                      ? 'visible'
                      : 'hidden',
                  opacity: step === STEP.SHOW_W_CHANCE_RESULT && isEmpty(wChanceData) ? 1 : 0,
                  transition: 'all ease-in-out',
                  zIndex: 12,
                }}
              >
                <img src={WChanceMiss} className="scratch-item-scale" />
                <div className="btn-next" style={{ textAlign: 'end', marginTop: '21px' }}>
                  <img
                    src={NextButton}
                    style={{ width: '163px', height: '54px' }}
                    onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
                  />
                </div>
                <div className="wrap_scratch_item_scale_bg" />
              </div>
              {/* {step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData) && ( */}
              <div
                id="result-scratch"
                style={{
                  position: 'absolute',
                  top: '-100px',
                  visibility:
                    step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData)
                      ? 'visible'
                      : 'hidden',
                  opacity: step === STEP.SHOW_W_CHANCE_RESULT && !isEmpty(wChanceData) ? 1 : 0,
                  transition: 'all ease-in-out',
                }}
              >
                <div id="w-chance-result">
                  <img src={Congratulations} id="congratulations" />
                  <img src={wChanceData?.image?.image_url} id="scratch-item-w-chance" />
                  <div className="w-chance-result-footer-custom">
                    <div>
                      <span className="icon-custom">+</span> <span className="text-custom">ON</span>
                    </div>
                    <span className="description">{wChanceData?.title}</span>
                  </div>
                </div>
                <div className="btn-next">
                  <img
                    src={NextButton}
                    onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
                  />
                </div>
              </div>
            </div>

            {[
              STEP.OPEN_MODAL,
              STEP.SHOW_SCRATCH,
              STEP.SCRATCH,
              STEP.SHOW_W_CHANCE_TICKET,
              STEP.SHOW_W_CHANCE_ANIMATION_1,
            ].includes(step) && (
              <div
                className="next-button"
                onClick={() => handleNextStep(prizesData[counter]?.rank_name)}
              >
                タップしてクジをめくる
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
