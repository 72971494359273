// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import CountDown from '../../common/countDown';
import { useTranslation } from 'react-i18next';
import { OFF_SALE, ON_SALE, READY_TO_SALE } from '../../../constants';
import icon from '../../../../assets/imgs/lotteryDetail/icon.png';
import TButton from '../../common/button';
import { useHistory, useParams } from 'react-router-dom';
import ScrollButton from '../top/backToTop/ScrollButton';
import dayjs from 'dayjs';
import JSCookie from 'js-cookie';

function FloatPanel(props) {
  const { data } = props;
  const saleStatus = data?.lottery_event?.sale_status;

  const startDate = data?.lottery_event?.start_date;
  const endDate = data?.lottery_event?.end_date;

  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const pathLotteryTrial = '/trial';
  const pathSite = history.location.pathname;

  useEffect(() => {
    const styleFooter = document.getElementById('footer-wrapper');
    if (styleFooter) {
      styleFooter.classList.add('my-footer-lottery-detail');
    }
    return () => styleFooter.classList.remove('my-footer-lottery-detail');
  }, []);

  const token = JSCookie.get('token');

  const handlePurchase = () => {
    token ? history.push(`/purchase/${id}`) : history.push(`/login?callbackUrl=%2Flottery%2F${id}`);
  };

  const handlePurchaseTrial = () => {
    history.push(`/purchase/trial`);
  };

  const getStatusCountDown = (status) => {
    setCountdown(status);
  };

  const [saleStatusClient, setSaleStatusClient] = useState(saleStatus);

  useEffect(() => {
    const interval = setInterval(function () {
      const date = saleStatusClient === ON_SALE ? endDate : startDate;
      const target = dayjs(date);
      const now = dayjs();
      const distance = target.diff(now);

      if (distance <= 0) {
        switch (saleStatusClient) {
          case READY_TO_SALE:
            setSaleStatusClient(ON_SALE);
            break;
          case ON_SALE:
            setSaleStatusClient(OFF_SALE);
            break;
          case OFF_SALE:
            clearInterval(interval);
            break;

          default:
            break;
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [saleStatusClient]);
  return (
    <>
      {data && (
        <div className="float-panel-lottery-detail">
          <ScrollButton className="scroll-float-panel" />
          {pathSite === pathLotteryTrial ? (
            <div className="float-panel-content">
              <p className="title">{data?.lottery_event?.name}</p>

              <div className="info-count-down">
                <div className="lottery-price">
                  <p className="mb-0">
                    1枚{' '}
                    <span className="price">
                      {pathSite === pathLotteryTrial
                        ? 0
                        : Math.floor(
                            data?.lottery_event?.price +
                              (data?.lottery_event?.price * data?.lottery_event?.tax_rate) / 100,
                          ).toLocaleString('ja-JP')}
                    </span>{' '}
                    円<small>(税込)</small>
                  </p>
                </div>
              </div>

              <div className="action text-center">
                <TButton
                  title={t('textButtonFloatPanel')}
                  className="danger btn-move-purchase"
                  onClick={handlePurchaseTrial}
                />
              </div>
            </div>
          ) : (
            <div className="float-panel-content">
              <p className="title">{data.lottery_event.name}</p>

              <div className="info-count-down">
                {saleStatusClient !== OFF_SALE && (
                  <div className="count-down">
                    <p>
                      <img src={icon} alt="img" />
                      <strong>
                        {saleStatusClient === ON_SALE
                          ? t(t('textOnSale'))
                          : t(t('textNotOpenForSale'))}
                      </strong>
                    </p>
                    <div className="text-center">
                      <CountDown
                        date={saleStatusClient === ON_SALE ? endDate : startDate}
                        getStatusCountDown={getStatusCountDown}
                      />
                    </div>
                  </div>
                )}
                <div className="lottery-price">
                  <p className="mb-0">
                    1枚{' '}
                    <span className="price">
                      {Math.floor(
                        data?.lottery_event?.price +
                          (data?.lottery_event?.price * data?.lottery_event?.tax_rate) / 100,
                      ).toLocaleString('ja-JP')}
                    </span>{' '}
                    円<small>(税込)</small>
                  </p>
                </div>
              </div>

              <div className="action text-center">
                <TButton
                  title={
                    saleStatus === ON_SALE
                      ? t('buttonProgressToPurchase')
                      : saleStatus === 0
                      ? t('textPrepare')
                      : t('textOffSale')
                  }
                  className={
                    saleStatusClient === ON_SALE
                      ? 'danger btn-move-purchase'
                      : saleStatusClient === ON_SALE
                      ? 'btn-move-purchase btn-disable-purchase'
                      : 'btn-move-purchase btn-move-off-sale btn-disable-purchase'
                  }
                  disabled={saleStatusClient !== ON_SALE}
                  onClick={handlePurchase}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default FloatPanel;
