// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
export default function InformationLottery(props) {
  const { dataOrders } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Table bordered>
        <tbody>
          <tr>
            <th>{t('textNameLottery')}</th>
            <td>{dataOrders?.name_lottery}</td>
          </tr>
          <tr>
            <th>{t('titlePurchaseIDTableHistoryMyCollection')}</th>
            <td>{dataOrders?.purchase_id}</td>
          </tr>
          <tr>
            <th>{t('textTrackingNumber')}</th>
            {dataOrders?.tracking_ship_number ? (
              <td>
                {dataOrders?.shippment_company_info}({dataOrders?.tracking_ship_number})
              </td>
            ) : (
              <td>※ 発送準備が整い次第表示されます。</td>
            )}
          </tr>
          <tr>
            <th>{t('textTimePurchase')}</th>
            <td>{dataOrders?.created_at}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
