// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';

export default function InformationNews(props) {
  const { data } = props;
  const renderContent = () => {
    return { __html: data?.content };
  };
  return (
    <>
      <div className="information-news">
        <div dangerouslySetInnerHTML={renderContent()}></div>
      </div>
    </>
  );
}
