// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';

const HistoryPurchase = (props) => {
  const { data } = props;
  const history = useHistory();

  if (!data) return <div />;

  return (
    <div className="history-purchase-sp">
      <div style={{ marginBottom: 20, fontSize: 18, fontWeight: 'bold' }} className="table-title">
        このクジの購入履歴
      </div>
      <ul className="list-link">
        {data.map((item, index) => (
          <li key={index}>
            <div onClick={() => history.push(`/order/history/${item.id}`)}>
              <div>購入日時：{item.created_at}</div>
              <div>
                購入枚数 : {item.quantity}枚(破棄数：{item.total_discard}枚)
              </div>
              <div>購入ID : {item.purchase_id}</div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HistoryPurchase;
