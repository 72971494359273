// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TopHeader from '../../components/common/topHeader';
import Collection from '../../components/pages/myCollection/Collection';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import Pagination from '../../components/common/pagination';
import useMessage from '../../components/common/toast/UseMessage';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { LANDING_PAGE, STATUS_URL, listsErrorNotShowToast } from '../../constants';
import { LotteryApis } from '../../services/lotteryDetail';
import { TopApis } from '../../services/top';

function LandingPage() {
  const { openMessageError } = useMessage();

  const dispatch = useAppDispatch();
  const [listLottery, setListLottery] = useState();
  const [banner, setBanner] = useState();
  const [page, setPage] = useState(1);

  const history = useHistory();
  const pathSite = history.location.pathname;

  const paramsShowInIsLandingPage = (pathSite) => {
    switch (pathSite) {
      case LANDING_PAGE.urlLandingPage1:
        return LANDING_PAGE.isLandingPage1;
      case LANDING_PAGE.urlLandingPage2:
        return LANDING_PAGE.isLandingPage2;
      default:
        break;
    }
  };

  const TABS = {
    TAB_LOTTERY_ALL: 'all',
    TAB_LOTTERY_NOW: 'now',
    TAB_LOTTERY_SOON: 'soon',
    TAB_LOTTERY_END: 'end',
  };
  const [status, setStatus] = useState(TABS.TAB_LOTTERY_ALL);

  const handleChangePage = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const totalPage = Math.ceil(listLottery?.total / listLottery?.per_page);

  const getLotteryList = async () => {
    dispatch(showLoading());
    try {
      const res = await LotteryApis.getList({
        page,
        status,
        show_in: paramsShowInIsLandingPage(pathSite),
      });
      const { data } = res;
      setListLottery(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getBanner = async () => {
    dispatch(showLoading());
    try {
      const res = await TopApis.getStatusUrl();
      const { data } = res;
      const itemBanner = data.filter((item) => item.url.includes(pathSite));
      if (itemBanner.length) {
        setBanner(itemBanner[0]);
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getLotteryList();
  }, [page, status]);

  useEffect(() => {
    getBanner();
  }, []);

  return (
    <>
      {listLottery && (
        <>
          {banner && <TopHeader title={banner?.title} />}
          <>
            {banner && (
              <div className="wrap-custom-image">
                <img src={banner?.image_url} alt="img" />
              </div>
            )}
            <div className="main-content list-lottery">
              <div className="tabs">
                <div
                  className={`tab-lottery ${
                    status === TABS.TAB_LOTTERY_ALL && 'tab-lottery-active'
                  }`}
                  onClick={() => {
                    setStatus(TABS.TAB_LOTTERY_ALL);
                    setPage(1);
                  }}
                  value={TABS.TAB_LOTTERY_ALL}
                  name="TAB_LOTTERY_ALL"
                >
                  全て
                </div>
                <div
                  className={`tab-lottery ${
                    status === TABS.TAB_LOTTERY_NOW && 'tab-lottery-active'
                  }`}
                  onClick={() => {
                    setStatus(TABS.TAB_LOTTERY_NOW);
                    setPage(1);
                  }}
                  value={TABS.TAB_LOTTERY_NOW}
                  name="TAB_LOTTERY_NOW"
                >
                  販売中
                </div>
                <div
                  className={`tab-lottery ${
                    status === TABS.TAB_LOTTERY_SOON && 'tab-lottery-active'
                  }`}
                  onClick={() => {
                    setStatus(TABS.TAB_LOTTERY_SOON);
                    setPage(1);
                  }}
                  value={TABS.TAB_LOTTERY_SOON}
                  name="TAB_LOTTERY_SOON"
                >
                  販売予定
                </div>
                <div
                  className={`tab-lottery ${
                    status === TABS.TAB_LOTTERY_END && 'tab-lottery-active'
                  }`}
                  onClick={() => {
                    setStatus(TABS.TAB_LOTTERY_END);
                    setPage(1);
                  }}
                  value={TABS.TAB_LOTTERY_END}
                  name="TAB_LOTTERY_END"
                >
                  販売終了
                </div>
              </div>

              <div className="d-flex flex-wrap content-list">
                {listLottery?.data?.length > 0 &&
                  listLottery?.data.map((collection, index) => {
                    return (
                      <Collection
                        key={index}
                        collection={collection}
                        isShowTime={true}
                        onClick={() => history.push(`/lottery/${collection.id}`)}
                      />
                    );
                  })}
              </div>
              <Pagination onChange={handleChangePage} totalPage={totalPage} forcePage={page} />
            </div>
          </>
        </>
      )}
      <ScrollButton />
    </>
  );
}

export default LandingPage;
