// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';
import jsCookie from 'js-cookie';
import { URL_NOT_REDIRECT } from './constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={(props) =>
        jsCookie.get('token') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={`/login${
              !URL_NOT_REDIRECT.includes(location.pathname)
                ? ''
                : `?callbackUrl=${encodeURIComponent(location.pathname)}`
            }`}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
