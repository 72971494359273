// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import CustomSlider from './CustomSlider';

export default function PhotoArea(props) {
  const { data } = props;
  const lotteryEvent = data.lottery_event;
  const additionImages = lotteryEvent.addition_image;

  return (
    <>
      <div className="photo-area">{additionImages && <CustomSlider data={additionImages} />}</div>
    </>
  );
}
