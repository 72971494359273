// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import TopHeader from '../../components/common/topHeader';
import MyPagePC from '../../components/pages/myPage/MyPagePC';
import MyPageSP from '../../components/pages/myPage/MyPageSP';
import { useTranslation } from 'react-i18next';
import { UserInfoApis } from '../../services/userInfo';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import useMessage from '../../components/common/toast/UseMessage';
import { listsErrorNotShowToast } from '../../constants';

function MyPage(props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();

  const [data, setData] = useState();

  const getUserInfo = async () => {
    const formData = {
      info: 'profile',
    };
    dispatch(showLoading());
    try {
      const res = await UserInfoApis.getData(formData);
      const { data } = res;
      setData(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <>
      <TopHeader title={t('titleMyPage')} />
      <div>
        <div className="my-page-pc">
          <MyPagePC data={data} />
        </div>
        <div className="my-page-sp">
          <MyPageSP data={data} />
        </div>
      </div>
    </>
  );
}

export default MyPage;
