// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import olAxios from '../api/Interceptors';

export const UserInfoApis = {
  getData: (data) => {
    return olAxios.post(`/user`, data);
  },
  updateDeliveryAddress: (data) => {
    return olAxios.post(`/user/update-delivery-address`, data);
  },
  updateDeliveryAddressPurchase: (data) => {
    return olAxios.post(`/order/update-address`, data);
  },
  createAddressPurchase: (data) => {
    return olAxios.post(`/purchase/create-address`, data);
  },
};
