// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive';
import qs from 'query-string';
import { Redirect, useHistory } from 'react-router-dom';
import useMessage from '../../components/common/toast/UseMessage';
import ScratchResultPC from '../../components/pages/scratchResult/PC/ScratchResultPC';
import ScratchResultSP from '../../components/pages/scratchResult/SP/ScratchResultSP';

import { BREAK_POINT, listsErrorNotShowToast } from '../../constants';
import { PurchaseApis } from '../../services/purchase';
import { useAppDispatch, useAppSelector } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { TopApis } from '../../services/top';
import TopHeader from '../../components/common/topHeader';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { getBase64Image, shareLink } from '../../utils/share';
import html2canvas from 'html2canvas';
import { ShareApi } from '../../services/share';

export default function ScratchResult() {
  const history = useHistory();
  const search = qs.parse(location.search);

  const dispatch = useAppDispatch();

  const pathLotteryTrial = '/result/trial';
  const pathSite = history.location.pathname;
  const resultDataTrial = history.location.state;
  const quantityTrial = Number(resultDataTrial?.purchased_quantity);
  const isQuantityWChance = resultDataTrial?.is_get_on_challenge;

  const {
    auth: { userInfo },
  } = useAppSelector((state) => state);

  const { openMessageError } = useMessage();

  const [scratchResult, setScratchResult] = useState([]);
  const [recommendLottery, setRecommendLottery] = useState([]);

  const [lotteryId, setLotteryId] = useState(null);
  const [totalPrize, setTotalPrize] = useState(0);

  if (!search.order_id && pathLotteryTrial !== pathSite) return <Redirect to="/" />;

  useEffect(async () => {
    dispatch(showLoading());
    try {
      const resultData =
        pathLotteryTrial === pathSite
          ? resultDataTrial
          : await PurchaseApis.result({ order_id: search.order_id });
      setScratchResult(
        pathLotteryTrial === pathSite ? resultDataTrial?.result : resultData.data?.prizes,
      );
      setLotteryId(resultData.data?.lottery_event_id);
      setTotalPrize(pathLotteryTrial === pathSite ? quantityTrial : resultData.data?.quantity);
      const recommendData = await TopApis.getListLottery({ recommend: 1 });
      setRecommendLottery(recommendData.data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  }, []);

  const gotoReBuy = () => {
    history.push(`/purchase/${lotteryId}`);
  };

  const gotoLotteryDetail = () => {
    history.push(`/lottery/${lotteryId}`);
  };

  const [width, setWidth] = useState();
  const ratioFace = 1.91;
  const height = width / ratioFace;

  const getWidth = (value) => {
    setWidth(value);
  };

  const handleShare = (type) => {
    const windowReference = window.open();

    const element = document.getElementById('share-sns-wrapper');

    if (element) {
      html2canvas(element, {
        proxy: '',
        useCORS: true,
        allowTaint: true,
        // foreignObjectRendering: true,
        scrollY: 0,
        scrollX: 0,
        scale: 1,
        width: width,
        height: height,
        imageTimeout: 40000,
        onclone: (doc) => {
          const ele = doc.getElementById('share-sns-wrapper');
          const variationImages = doc.querySelectorAll('.prizeItemImg');
          variationImages.forEach((img) => {
            const img64 = getBase64Image(img);
            img.src = img64;
          });
          if (ele) {
            ele.style.position = 'fixed';
            ele.style.margin = '0';
            ele.style.top = '0';
            ele.style.left = '0';
          }
        },
      }).then(async function (canvas) {
        const image = canvas.toDataURL('image/png');

        dispatch(showLoading());
        const res = await ShareApi.uploadImg({
          file: image,
          lottery_event_id: lotteryId,
          type: 'result',
          order_id: search.order_id,
        });
        const { data } = res;
        dispatch(hideLoading());
        windowReference.location = shareLink(
          type,
          `${process.env.APP_ENDPOINT}/share/sns/${userInfo.id}/${lotteryId}/result/${data?.image_url}/${search.order_id}`,
        );
      });
    }
  };

  return (
    <>
      <TopHeader title="抽選結果" />
      <MediaQuery minWidth={BREAK_POINT + 1}>
        <ScratchResultPC
          scratchResult={scratchResult}
          recommendLottery={recommendLottery}
          gotoReBuy={gotoReBuy}
          gotoLotteryDetail={gotoLotteryDetail}
          totalPrize={totalPrize}
          handleShare={handleShare}
          getWidth={getWidth}
          isQuantityWChance={isQuantityWChance}
        />
      </MediaQuery>
      <MediaQuery maxWidth={BREAK_POINT}>
        <ScratchResultSP
          scratchResult={scratchResult}
          recommendLottery={recommendLottery}
          gotoReBuy={gotoReBuy}
          gotoLotteryDetail={gotoLotteryDetail}
          totalPrize={totalPrize}
          handleShare={handleShare}
          getWidth={getWidth}
          isQuantityWChance={isQuantityWChance}
        />
      </MediaQuery>
      <ScrollButton />
    </>
  );
}
