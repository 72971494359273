// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { AiFillCaretRight, AiFillCaretLeft } from 'react-icons/ai';
import './carousel.scss';

const CarouselTop = styled.div`
  .next {
    right: calc(50% - 475px);
    @media only screen and (max-width: 896px) {
      right: calc(50% - 355px);
    }
  }
  .prev {
    left: calc(50% - 475px);
    @media only screen and (max-width: 896px) {
      left: calc(50% - 355px);
    }
  }

  .slick-dots {
    bottom: -40px;
    @media only screen and (max-width: 896px) {
      bottom: -20px;
    }
  }

  .slick-dots li button:before {
    font-size: 40px;
    @media only screen and (max-width: 896px) {
      font-size: 25px;
    }
  }

  .slick-dots li.slick-active button:before {
    color: #f55076;
  }

  .slick-slide {
    position: relative;
    width: ${(props) => (props.quantity === 1 ? '100%' : '828px')};
    height: 380px;
    overflow: hidden;
    @media only screen and (max-width: 896px) {
      width: ${(props) => (props.quantity === 1 ? '100%' : '600px')};
    }
    @media only screen and (max-width: 769px) {
      width: ${(props) => (props.quantity === 1 ? '100%' : '100vw')};
      height: 45.86666vw;
    }
    img {
      width: 100%;
      @media only screen and (max-width: 769px) {
        opacity: 0.7;
      }
    }
  }

  @media only screen and (max-width: 769px) {
    .slick-slide.slick-center img {
      opacity: 1;
    }
  }

  @media only screen and (min-width: 769px) {
    .slick-slide::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0);
      transition: 0.3s ease;
      pointer-events: none;
    }

    .slick-slide:not(.slick-current)::after {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export default function Carousel(props) {
  const { listBanner } = props;
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <AiFillCaretRight size={50} style={{ fill: '#dedede' }} />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <AiFillCaretLeft size={50} style={{ fill: '#dedede' }} />
      </div>
    );
  };

  const settings = {
    className: 'slider',
    dots: true,
    lazyLoad: true,
    infinite: true,
    centerMode: listBanner.length !== 1,
    variableWidth: true,
    adaptiveHeight: true,
    autoplay: listBanner.length !== 1,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <CarouselTop quantity={listBanner.length}>
      <Slider {...settings}>
        {listBanner.map((item, index) => {
          return (
            <a href={item.url_redirect} key={index}>
              <img
                src={item.image}
                className={listBanner?.length === 1 ? 'thumbnail-custom-top' : ''}
              />
            </a>
          );
        })}
      </Slider>
    </CarouselTop>
  );
}
