// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { NewsApis } from '../../services/listNews';
import './styles.scss';
import NewsItem from '../../components/pages/listNews/NewsItem';
import Pagination from '../../components/common/pagination';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import { useLocation } from 'react-router-dom';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { listsErrorNotShowToast } from '../../constants';
import JSCookie from 'js-cookie';

export default function ListNews() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const {
    auth: { userInfo },
  } = useAppSelector((state) => state);
  const token = JSCookie.get('token');

  const TABS = { TAB_NEWS_PUBLIC: 1, TAB_NEWS_PRIVATE: 2 };

  const TAB_PRIVATE_STATE = location?.state?.TAB_PRIVATE;

  const user_id = userInfo?.id;
  const { openMessageError } = useMessage();
  const [tabIndex, setTabIndex] = useState(TABS.TAB_NEWS_PUBLIC);

  useEffect(() => {
    if (tabIndex === TABS.TAB_NEWS_PUBLIC && TAB_PRIVATE_STATE) {
      setTabIndex(TAB_PRIVATE_STATE);
    }
  }, [location?.state?.isCheck]);

  const [listNewsPublic, setListNewsPublic] = useState([]);
  const [listNewsPrivate, setListNewsPrivate] = useState([]);
  const STATUS = { SEEN: 1 };
  const [status, setStatus] = useState(2);
  const [page, setPage] = useState(1);
  const [dataPagination, setDataPagination] = useState({});
  const totalPage = Math.ceil(dataPagination?.total / dataPagination?.per_page);

  const handleChangePage = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const getListNewsPublic = async () => {
    dispatch(showLoading());
    const params = {
      show_range: 1,
      page: page,
    };
    try {
      const res = await NewsApis.getListNews(params);
      const { data } = res;
      const dataNews = data?.list_news.data;
      const dataPage = data?.list_news;
      setDataPagination(dataPage);
      setListNewsPublic(dataNews);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const getListNewsPrivate = async () => {
    dispatch(showLoading());
    const params = {
      show_range: 2,
      user_id: user_id,
      page: page,
    };
    try {
      const res = await NewsApis.getListNews(params);
      const { data } = res;
      const dataNewsPrivate = data?.list_news.data;
      const dataStatus = data?.status;
      const dataPage = data?.list_news;
      setDataPagination(dataPage);
      setStatus(dataStatus);
      setListNewsPrivate(dataNewsPrivate);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getListNewsPublic();
    if (token) {
      getListNewsPrivate();
    }
  }, []);

  useEffect(() => {
    if (tabIndex === TABS.TAB_NEWS_PUBLIC) {
      getListNewsPublic();
    } else if (token) {
      getListNewsPrivate();
    }
  }, [page, tabIndex]);

  return (
    <>
      <TopHeader title={t('headerListNews')} />
      <div className="main-content">
        <div className="items-list-news">
          {token && (
            <div className="tabs">
              <div
                className={
                  tabIndex === TABS.TAB_NEWS_PUBLIC
                    ? 'tab-news tab-news-active tab-seen '
                    : 'tab-news tab-seen '
                }
                onClick={() => {
                  setTabIndex(TABS.TAB_NEWS_PUBLIC);
                  setPage(1);
                }}
                value={TABS.TAB_NEWS_PUBLIC}
                name="TAB_NEWS_PUBLIC"
              >
                {t('titleNewsPublic')}
              </div>
              <div
                className={
                  tabIndex === TABS.TAB_NEWS_PRIVATE ? 'tab-news tab-news-active' : 'tab-news'
                }
                onClick={() => {
                  setTabIndex(TABS.TAB_NEWS_PRIVATE);
                  setPage(1);
                }}
                value={TABS.TAB_NEWS_PRIVATE}
                name="TAB_NEWS_PRIVATE"
              >
                {t('titleNewsPrivate')}
                {status === STATUS.SEEN ? <span className="tab-seen-badge"></span> : <div />}
              </div>
            </div>
          )}
          {tabIndex === TABS.TAB_NEWS_PUBLIC
            ? listNewsPublic.length > 0 &&
              listNewsPublic.map((item, index) => {
                return <NewsItem key={index} news={item} />;
              })
            : listNewsPrivate.length > 0 &&
              listNewsPrivate.map((item, index) => {
                return <NewsItem key={index} news={item} />;
              })}
          {dataPagination ? (
            <div className="pagination-news">
              <Pagination onChange={handleChangePage} totalPage={totalPage} forcePage={page} />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <ScrollButton />
    </>
  );
}
