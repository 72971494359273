// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import MediaQuery from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { BREAK_POINT, URL_NOT_REDIRECT } from '../../../constants';
import { AuthApis } from '../../../services/auth';
import { useAppDispatch } from '../../../store';
import { logout } from '../../../store/features/auth';
import HeaderPC from './headerPC';
import HeaderSP from './headerSP';
import JSCookie from 'js-cookie';

export default function Header() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const token = JSCookie.get('token');

  const handleLogout = async () => {
    await AuthApis.logout();
    dispatch(logout());
    history.push('/login');
  };
  const gotoLogin = () => {
    if (!URL_NOT_REDIRECT.includes(location.pathname)) {
      history.push(`/login?callbackUrl=${encodeURIComponent(location.pathname)}`);
    } else {
      history.push('/login');
    }
  };

  const gotoMyCollection = () => history.push('/my-collection');

  const gotoNews = () => history.push('/news');

  const gotoRegister = () => history.push('/register');

  const gotoMyPage = () => history.push('/my-page');

  const gotoPurchaseHistory = () => history.push('/order/history');

  return (
    <>
      <MediaQuery minWidth={BREAK_POINT + 1}>
        <HeaderPC
          isLogin={!!token}
          gotoLogin={gotoLogin}
          handleLogout={handleLogout}
          gotoMyCollection={gotoMyCollection}
          gotoNews={gotoNews}
          gotoRegister={gotoRegister}
          gotoMyPage={gotoMyPage}
          gotoPurchaseHistory={gotoPurchaseHistory}
        />
      </MediaQuery>
      <MediaQuery maxWidth={BREAK_POINT}>
        <HeaderSP
          isLogin={!!token}
          gotoLogin={gotoLogin}
          handleLogout={handleLogout}
          gotoMyCollection={gotoMyCollection}
          gotoNews={gotoNews}
          gotoRegister={gotoRegister}
          gotoMyPage={gotoMyPage}
          gotoPurchaseHistory={gotoPurchaseHistory}
        />
      </MediaQuery>
    </>
  );
}
