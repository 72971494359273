// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './styles.scss';
import SystemTemplate from './SystemTemplate';

function SystemPage(props) {
  return (
    <div className="wrap_system">
      <SystemTemplate />
    </div>
  );
}

export default SystemPage;
