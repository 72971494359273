// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function PurchaseQuantity(props) {
  const { dataOrders, data } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <Table bordered>
        <tbody>
          <tr>
            <th>{t('textUnitPrice')}</th>
            <td>{dataOrders?.price_order?.toLocaleString('ja-JP')}円</td>
          </tr>
          <tr>
            <th>数量</th>
            <td>
              {`${dataOrders?.quantity}枚(お届け数:${data?.amount_prizes_received}個/破棄:${data?.total_discard}個)`}
            </td>
          </tr>
          <tr>
            <th>お届け数</th>
            <td>{data?.amount_prizes_received}個</td>
          </tr>
          <tr>
            <th>{t('textDateDiscard')}</th>
            <td>
              {dataOrders?.can_discard && <div>破棄数変更期限 : {dataOrders?.date_discard}</div>}
              <span className="text-date-discard">
                ※破棄は
                <span
                  className="move-to-my-collection"
                  onClick={() => history.push('/my-collection')}
                >
                  Myコレクション
                </span>
                ページより行うことができます。
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
