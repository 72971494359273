// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

export const NextArrow = ({ onClick }) => {
  return (
    <div className="arrow next" onClick={onClick}>
      <BiChevronRight size={30} style={{ fill: '#464646' }} />
    </div>
  );
};
export const PrevArrow = ({ onClick }) => {
  return (
    <div className="arrow prev" onClick={onClick}>
      <BiChevronLeft size={30} style={{ fill: '#464646' }} />
    </div>
  );
};
