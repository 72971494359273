// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressShow from './AddressShow';
import MethodPoint from './MethodPoint';

function SettingAddress(props) {
  const { dataInfo, data, lotteryEvent, getPriceByPoint, getCheckPoint } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="my-setting-address my-box-purchase">
        <div className="title">
          <strong className="title-inbox">{t('titleAddressSetting')}</strong>
        </div>
        <AddressShow dataInfo={dataInfo} data={data} />
      </div>
      <MethodPoint
        dataInfo={dataInfo}
        lotteryEvent={lotteryEvent}
        data={data}
        getPriceByPoint={getPriceByPoint}
        getCheckPoint={getCheckPoint}
      />
    </>
  );
}

export default SettingAddress;
