// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import {
  convertSaleStatusToClassName,
  convertSaleStatusToColor,
} from '../../../utils/convertSaleStatus';
import { AiOutlineClockCircle } from 'react-icons/ai';

import './collection.scss';
const maxShowTotalBuy = 9999;
const showTotalBuy = '9999+';

function Collection({ collection, onClick, isShowTime = false, isRecommendLottery = false }) {
  return (
    <div className="card-lottery card" onClick={onClick}>
      <div className="card-img-top">
        <div>
          <img src={isRecommendLottery ? collection.image : collection.image.image_url} />
        </div>
      </div>
      <div className="card-body">
        <div
          style={{
            backgroundImage: `url(${convertSaleStatusToClassName(collection.sale_status)})`,
            backgroundSize: '100% 100%',
          }}
          className="sale-status"
        >
          <div className="sale-status-my-collection">{collection.sale_status}</div>
        </div>

        {collection.total_buy && (
          <div className="quantity-lottery-purchase">
            購入枚数
            <span>
              {collection.total_buy < maxShowTotalBuy ? collection.total_buy : showTotalBuy}枚
            </span>
          </div>
        )}
        <div className="card-title mb-0">{collection.name}</div>

        {isShowTime && (
          <div
            className="end-date"
            style={{ color: convertSaleStatusToColor(collection.sale_status) }}
          >
            <AiOutlineClockCircle /> {collection.end_date}
          </div>
        )}
      </div>
    </div>
  );
}

export default Collection;
