// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { AiOutlineMenu } from 'react-icons/ai';
import './headerSP.scss';
import NotifyNews from '../../notifyNews';
import { useHistory } from 'react-router-dom';

export default function HeaderSP(props) {
  const { t } = useTranslation();
  const [isShowSubMenu, setShowSubMenu] = useState(false);

  const {
    gotoLogin,
    isLogin,
    handleLogout,
    gotoMyCollection,
    gotoNews,
    gotoRegister,
    gotoMyPage,
    gotoPurchaseHistory,
  } = props;

  const toggleMenu = () => {
    setShowSubMenu((isShowSubMenu) => !isShowSubMenu);
  };

  const history = useHistory();

  return (
    <header className="ol-header-sp">
      <div className="logo">
        <div
          onClick={() => {
            history.push('/');
          }}
          className="brand-kuzipa"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="167.6"
            height="33"
            viewBox="0 0 393.442 88.279"
          >
            <g id="Group_5684" data-name="Group 5684" transform="translate(-326.397 -208.44)">
              <rect
                id="Rectangle_2802"
                data-name="Rectangle 2802"
                width="13.642"
                height="82.986"
                transform="translate(326.397 212.551)"
                fill="#e63556"
              />
              <path
                id="Path_53386"
                data-name="Path 53386"
                d="M376.259,295.537,340.039,248.2,374.3,212.551h18.92L357.99,249.208l35.447,46.329Z"
                fill="#38a1db"
              />
              <path
                id="Path_53387"
                data-name="Path 53387"
                d="M432.235,246.893v27.116a9.737,9.737,0,1,1-19.473,0V246.89H399.786l0,27.119a22.709,22.709,0,1,0,45.418,0l0-27.116Z"
                fill="#f9be00"
              />
              <path
                id="Path_53388"
                data-name="Path 53388"
                d="M703.92,273.247l-4.1-64.807h20.02l-4.1,64.807Z"
                fill="#38a1db"
              />
              <path
                id="Path_53389"
                data-name="Path 53389"
                d="M710.051,296.718A8.951,8.951,0,1,1,719,287.766a8.962,8.962,0,0,1-8.952,8.952"
                fill="#e63556"
              />
              <rect
                id="Rectangle_2803"
                data-name="Rectangle 2803"
                width="13.642"
                height="82.986"
                transform="translate(568.642 212.551)"
                fill="#e63556"
              />
              <path
                id="Path_53390"
                data-name="Path 53390"
                d="M595.812,212.554H585.119v12.973h10.693a13.684,13.684,0,1,1,0,27.368H585.119v12.97l10.693,0a26.657,26.657,0,1,0,0-53.313"
                fill="#38a1db"
              />
              <rect
                id="Rectangle_2804"
                data-name="Rectangle 2804"
                width="12.974"
                height="48.647"
                transform="translate(537.57 246.89)"
                fill="#f9be00"
              />
              <path
                id="Path_53391"
                data-name="Path 53391"
                d="M549.109,220.485l13.372,9.362-16.2,8.91Z"
                fill="#38a1db"
              />
              <path
                id="Path_53392"
                data-name="Path 53392"
                d="M539,220.485l-13.372,9.362,16.2,8.91Z"
                fill="#e63556"
              />
              <path
                id="Path_53393"
                data-name="Path 53393"
                d="M457.986,225.527l-.151-12.975h45.7l-8.63,12.975Z"
                fill="#e63556"
              />
              <path
                id="Path_53394"
                data-name="Path 53394"
                d="M520.415,295.538H451.738l55.2-82.986h16.494l-46.567,70.01,43.553,0Z"
                fill="#38a1db"
              />
              <path
                id="Path_53395"
                data-name="Path 53395"
                d="M641.366,280.425l5.339-12.309h15.846l5.338,12.309Z"
                fill="#38a1db"
              />
              <path
                id="Path_53396"
                data-name="Path 53396"
                d="M653.083,246.286l-7.435-17.143-28.8,66.394h14.87Z"
                fill="#f9be00"
              />
              <path
                id="Path_53397"
                data-name="Path 53397"
                d="M692.4,295.537l-37.775-87.1-7.435,17.143,30.341,69.954Z"
                fill="#e63556"
              />
            </g>
          </svg>
        </div>

        <OutsideClickHandler
          onOutsideClick={() => {
            setShowSubMenu(false);
          }}
        >
          <AiOutlineMenu onClick={toggleMenu} />
          {isShowSubMenu && (
            <div className="sub-menu">
              {isLogin ? (
                <div className="sub-menu-item-wrap">
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      setShowSubMenu(false);
                      gotoMyPage();
                    }}
                  >
                    {t('menu.myPage')}
                  </div>
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      setShowSubMenu(false);
                      gotoPurchaseHistory();
                    }}
                  >
                    {t('menu.purchaseHistory')}
                  </div>
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      setShowSubMenu(false);
                      handleLogout();
                    }}
                  >
                    {t('logout')}
                  </div>
                </div>
              ) : (
                <div className="sub-menu-item-wrap">
                  <div
                    className="sub-menu-item"
                    onClick={() => {
                      gotoLogin();
                      setShowSubMenu(false);
                    }}
                  >
                    {t('login')}
                  </div>
                </div>
              )}
            </div>
          )}
        </OutsideClickHandler>
      </div>

      <div className="menu">
        {isLogin && (
          <div
            className="my-collection d-flex align-items-center justify-content-center"
            onClick={gotoMyCollection}
          >
            <svg
              id="card_giftcard_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path id="Path_53318" data-name="Path 53318" d="M0,0H18V18H0Z" fill="none" />
              <path
                id="Path_53319"
                data-name="Path 53319"
                d="M14.6,4.737H13.074a2.014,2.014,0,0,0,.126-.684A2.074,2.074,0,0,0,11.1,2a2.11,2.11,0,0,0-1.75.924L9,3.382l-.35-.465A2.121,2.121,0,0,0,6.9,2,2.074,2.074,0,0,0,4.8,4.053a2.014,2.014,0,0,0,.126.684H3.4A1.374,1.374,0,0,0,2.007,6.105L2,13.632A1.38,1.38,0,0,0,3.4,15H14.6A1.38,1.38,0,0,0,16,13.632V6.105A1.38,1.38,0,0,0,14.6,4.737ZM11.1,3.368a.684.684,0,1,1-.7.684A.694.694,0,0,1,11.1,3.368Zm-4.2,0a.684.684,0,1,1-.7.684A.694.694,0,0,1,6.9,3.368Zm7.7,10.263H3.4V12.263H14.6Zm0-3.421H3.4V6.105H6.956L5.5,8.042l1.134.8L8.3,6.625,9,5.695l.7.931,1.666,2.217,1.134-.8L11.044,6.105H14.6Z"
                transform="translate(0 0)"
                fill="#f55076"
              />
            </svg>

            {t('menu.myCollection')}
          </div>
        )}
        <div
          className="user-guide d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/guide');
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <g id="レイヤー_1" data-name="レイヤー 1" transform="translate(-84 86)">
              <path
                id="Path_52141"
                data-name="Path 52141"
                d="M0,0H18V18H0Z"
                transform="translate(84 -86)"
                fill="none"
              />
              <path
                id="Path_52551"
                data-name="Path 52551"
                d="M32.39,16.173l4.228-4.228a.869.869,0,0,0,.253-.61V3.882a.865.865,0,0,0-1.476-.61l-3,3Z"
                transform="translate(60.631 -85.478)"
                fill="#40b569"
              />
              <path
                id="Path_52139"
                data-name="Path 52139"
                d="M8.083,15.553l3.611-3.616a.87.87,0,0,0,.253-.61V3.873a.863.863,0,0,0-1.473-.61l-3,3-3-3.006A.863.863,0,0,0,3,3.873v7.452a.87.87,0,0,0,.253.61l3.611,3.618a.859.859,0,0,0,1.219,0Z"
                transform="translate(85.504 -85.469)"
                fill="none"
                stroke="#40b569"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </g>
          </svg>
          {t('menu.userGuide')}
        </div>
        <div className="wrap-notify-sp">
          <div
            className="notice d-flex align-items-center justify-content-center"
            onClick={gotoNews}
          >
            <svg
              id="notifications_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="17.997"
              height="17.997"
              viewBox="0 0 17.997 17.997"
            >
              <path id="Path_52137" data-name="Path 52137" d="M0,0H18V18H0Z" fill="none" />
              <path
                id="Path_52138"
                data-name="Path 52138"
                d="M10,17.122a1.5,1.5,0,0,0,1.5-1.5h-3A1.5,1.5,0,0,0,10,17.122Zm4.5-4.5V8.874a4.561,4.561,0,0,0-3.374-4.739v-.51a1.125,1.125,0,0,0-2.25,0v.51A4.563,4.563,0,0,0,5.5,8.874v3.749L4,14.123v.75H16v-.75Zm-1.5.75H7v-4.5A3.075,3.075,0,0,1,10,5.5a3.075,3.075,0,0,1,3,3.374Z"
                transform="translate(-1.001 -0.625)"
                fill="#ff6c00"
              />
            </svg>
            {t('menu.news')}
          </div>
          {isLogin && <NotifyNews />}
        </div>

        <div
          className="header-list-lottery-sp d-flex align-items-center justify-content-center"
          onClick={() => {
            history.push('/lottery');
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.286"
            height={12}
            viewBox="0 0 16.286 12"
          >
            <g
              id="Rectangle_4668"
              data-name="Rectangle 4668"
              transform="translate(0.286)"
              fill="none"
              stroke="#f55076"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <rect width={16} height={11} stroke="none" />
              <rect x="0.75" y="0.75" width="14.5" height="9.5" fill="none" />
            </g>
            <g
              id="Path_57533"
              data-name="Path 57533"
              transform="matrix(0.966, -0.259, 0.259, 0.966, 0.758, 8.292)"
              fill="#f55076"
            >
              <path
                d="M 4.519876003265381 3.052529573440552 L 0.6061152219772339 2.076717376708984 L 1.494024634361267 -1.484504461288452 L 1.54073691368103 -1.67185914516449 L 4.519876003265381 3.052529573440552 Z"
                stroke="none"
              />
              <path
                d="M 1.742537260055542 -0.4144409894943237 L 1.212225914001465 1.712529540061951 L 3.432923316955566 2.266212701797485 L 1.742537260055542 -0.4144409894943237 M 1.338934421539307 -2.929274082183838 L 5.606824398040771 3.838845729827881 L 4.76837158203125e-06 2.440905809402466 L 1.338934421539307 -2.929274082183838 Z"
                stroke="none"
                fill="#f55076"
              />
            </g>
            <path
              id="Path_57536"
              data-name="Path 57536"
              d="M297.741,72.29l8.983,7.929"
              transform="translate(-296.726 -70.003)"
              fill="none"
              stroke="#f55076"
              strokeWidth={2}
            />
          </svg>

          {t('menu.listLottery')}
        </div>
        {!isLogin ? (
          <div
            className="register-account d-flex align-items-center justify-content-center"
            onClick={gotoRegister}
          >
            <svg
              id="sentiment_satisfied_alt_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path id="Path_53342" data-name="Path 53342" d="M0,0H18V18H0Z" fill="none" />
              <path id="Path_53343" data-name="Path 53343" d="M0,0H18V18H0Z" fill="none" />
              <circle
                id="Ellipse_28"
                data-name="Ellipse 28"
                cx="1"
                cy="1"
                r="1"
                transform="translate(11.009 5.991)"
              />
              <circle
                id="Ellipse_29"
                data-name="Ellipse 29"
                cx="1"
                cy="1"
                r="1"
                transform="translate(5.004 5.991)"
              />
              <circle
                id="Ellipse_30"
                data-name="Ellipse 30"
                cx="1"
                cy="1"
                r="1"
                transform="translate(11.009 5.991)"
                fill="#1d9ded"
              />
              <circle
                id="Ellipse_31"
                data-name="Ellipse 31"
                cx="1"
                cy="1"
                r="1"
                transform="translate(5.004 5.991)"
                fill="#1d9ded"
              />
              <path
                id="Path_53344"
                data-name="Path 53344"
                d="M9.494,2A7.5,7.5,0,1,0,17,9.5,7.5,7.5,0,0,0,9.494,2ZM9.5,15.5a6,6,0,1,1,6-6A6,6,0,0,1,9.5,15.5Zm0-1.875A4.122,4.122,0,0,0,13.342,11H12.089a2.982,2.982,0,0,1-5.176,0H5.661A4.122,4.122,0,0,0,9.5,13.627Z"
                transform="translate(-0.501 -0.501)"
                fill="#1d9ded"
              />
            </svg>
            {t('menu.register')}
          </div>
        ) : (
          <div />
        )}
      </div>
    </header>
  );
}
