// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import TopHeader from '../topHeader';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useParams } from 'react-router-dom';

function ErrorScreen() {
  const { errorId } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <TopHeader title={t(`titleError${errorId}`)} />
      <div className="my-error">
        <div className="error-content">
          <div className="title-error">エラー</div>
          <div className="text-error">エラーが発生しました。</div>
          <div className="description-error">{`誠に申し訳ございませんが、\nもう一度やり直してください。`}</div>
        </div>
      </div>
    </>
  );
}

export default ErrorScreen;
