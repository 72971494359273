// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useTranslation } from 'react-i18next';
import TopHeader from '../../common/topHeader';

export default function ConfirmSuccess() {
  const { t } = useTranslation();
  return (
    <>
      <TopHeader title={t('textForgotPassword')} />
      <div className="show-confirm-success main-content">
        <div className="show-content">{t('msgForgotPasswordSuccess')}</div>
      </div>
    </>
  );
}
