// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

/**
 *
 *  Base64 encode / decode
 *  http://www.webtoolkit.info/
 *
 **/

var Base64 = {
  // private property
  _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

  // public method for encoding
  encode: function (input) {
    var output = '';
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }

    return output;
  },

  // public method for decoding
  decode: function (input) {
    var output = '';
    var chr1, chr2, chr3;
    var enc1, enc2, enc3, enc4;
    var i = 0;

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));
      enc2 = this._keyStr.indexOf(input.charAt(i++));
      enc3 = this._keyStr.indexOf(input.charAt(i++));
      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }

    output = Base64._utf8_decode(output);

    return output;
  },

  // private method for UTF-8 encoding
  _utf8_encode: function (string) {
    string = string.replace(/\r\n/g, '\n');
    var utftext = '';

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  },

  // private method for UTF-8 decoding
  _utf8_decode: function (utftext) {
    var string = '';
    var i = 0;
    var c = (c1 = c2 = 0);

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
        i += 3;
      }
    }

    return string;
  },
};

/**
 * Secure Hash Algorithm (SHA1)
 * http://www.webtoolkit.info/
 **/
function SHA1(msg) {
  function rotate_left(n, s) {
    var t4 = (n << s) | (n >>> (32 - s));
    return t4;
  }
  function lsb_hex(val) {
    var str = '';
    var i;
    var vh;
    var vl;
    for (i = 0; i <= 6; i += 2) {
      vh = (val >>> (i * 4 + 4)) & 0x0f;
      vl = (val >>> (i * 4)) & 0x0f;
      str += vh.toString(16) + vl.toString(16);
    }
    return str;
  }
  function cvt_hex(val) {
    var str = '';
    var i;
    var v;
    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f;
      str += v.toString(16);
    }
    return str;
  }
  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, '\n');
    var utftext = '';
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  }
  var blockstart;
  var i, j;
  var W = new Array(80);
  var H0 = 0x67452301;
  var H1 = 0xefcdab89;
  var H2 = 0x98badcfe;
  var H3 = 0x10325476;
  var H4 = 0xc3d2e1f0;
  var A, B, C, D, E;
  var temp;
  msg = Utf8Encode(msg);
  var msg_len = msg.length;
  var word_array = new Array();
  for (i = 0; i < msg_len - 3; i += 4) {
    j =
      (msg.charCodeAt(i) << 24) |
      (msg.charCodeAt(i + 1) << 16) |
      (msg.charCodeAt(i + 2) << 8) |
      msg.charCodeAt(i + 3);
    word_array.push(j);
  }
  switch (msg_len % 4) {
    case 0:
      i = 0x080000000;
      break;
    case 1:
      i = (msg.charCodeAt(msg_len - 1) << 24) | 0x0800000;
      break;
    case 2:
      i = (msg.charCodeAt(msg_len - 2) << 24) | (msg.charCodeAt(msg_len - 1) << 16) | 0x08000;
      break;
    case 3:
      i =
        (msg.charCodeAt(msg_len - 3) << 24) |
        (msg.charCodeAt(msg_len - 2) << 16) |
        (msg.charCodeAt(msg_len - 1) << 8) |
        0x80;
      break;
  }
  word_array.push(i);
  while (word_array.length % 16 != 14) word_array.push(0);
  word_array.push(msg_len >>> 29);
  word_array.push((msg_len << 3) & 0x0ffffffff);
  for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
    for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i];
    for (i = 16; i <= 79; i++) W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1);
    A = H0;
    B = H1;
    C = H2;
    D = H3;
    E = H4;
    for (i = 0; i <= 19; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 20; i <= 39; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 40; i <= 59; i++) {
      temp =
        (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8f1bbcdc) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    for (i = 60; i <= 79; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff;
      E = D;
      D = C;
      C = rotate_left(B, 30);
      B = A;
      A = temp;
    }
    H0 = (H0 + A) & 0x0ffffffff;
    H1 = (H1 + B) & 0x0ffffffff;
    H2 = (H2 + C) & 0x0ffffffff;
    H3 = (H3 + D) & 0x0ffffffff;
    H4 = (H4 + E) & 0x0ffffffff;
  }
  var temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);

  return temp.toLowerCase();
}

export default function fSubmit(res) {
  var order = new Order();
  order.pay_method = res.pay_method;
  order.merchant_id = res.merchant_id;
  order.service_id = res.service_id;
  order.cust_code = res.user_id;
  order.sps_cust_no = '';
  order.sps_payment_no = '';
  order.order_id = res.payment_id;
  order.item_id = res.lottery_event_id;
  order.pay_item_id = '';
  order.item_name = res.name_lottery;
  order.tax = '';
  order.amount = res.amount_by_credit_card;
  order.pay_type = '0';
  order.auto_charge_type = '';
  order.service_type = '0';
  order.div_settele = '';
  order.last_charge_month = '';
  order.camp_type = '';
  order.tracking_id = '';
  order.terminal_type = '0';
  order.success_url = `${process.env.APP_ENDPOINT}/payment/success?adult_purchase_flag=${res.adult_purchase_flag}&id=${res.id}`;
  order.cancel_url = `${process.env.APP_ENDPOINT}/payment/cancel?lottery_event_id=${res.lottery_event_id}`;
  order.error_url = `${process.env.APP_ENDPOINT}/payment/error?lottery_event_id=${res.lottery_event_id}`;
  order.pagecon_url = `${process.env.APP_ENDPOINT}/payment/callback`;
  order.free1 = '';
  order.free2 = '';
  order.free3 = '';
  order.free_csv_input = '';
  order.request_date = res.created_at;
  order.limit_second = '';
  order.hashkey = res.hashkey;

  var orderDetail = new OrderDetail();
  orderDetail.dtl_rowno = '1';
  orderDetail.dtl_item_id = res.lottery_event_id;
  orderDetail.dtl_item_name = res.name_lottery;
  orderDetail.dtl_item_count = res.quantity;
  orderDetail.dtl_tax = '0';
  orderDetail.dtl_amount = res.amount_by_credit_card;
  orderDetail.dtl_free1 = '';
  orderDetail.dtl_free2 = '';
  orderDetail.dtl_free3 = '';
  order.orderDetail.push(orderDetail);

  // フリーCSV
  order.free_csv = Base64.encode(order.free_csv_input);

  //チェックサム
  order.sps_hashcode = SHA1(order.toString());

  feppost(order, res.connect_url);
}

// オブジェクト定義[OrderDetail]
function OrderDetail() {
  this.toString = function () {
    var result =
      this.dtl_rowno +
      this.dtl_item_id +
      this.dtl_item_name +
      this.dtl_item_count +
      this.dtl_tax +
      this.dtl_amount +
      this.dtl_free1 +
      this.dtl_free2 +
      this.dtl_free3;
    return result;
  };
}

// オブジェクト定義[Order]
function Order() {
  this.orderDetail = new Array();
  this.toString = function () {
    var resultOrderDetail = '';
    for (let i = 0; i < this.orderDetail.length; i++) {
      resultOrderDetail = resultOrderDetail + this.orderDetail[i].toString();
    }

    var result =
      this.pay_method +
      this.merchant_id +
      this.service_id +
      this.cust_code +
      this.sps_cust_no +
      this.sps_payment_no +
      this.order_id +
      this.item_id +
      this.pay_item_id +
      this.item_name +
      this.tax +
      this.amount +
      this.pay_type +
      this.auto_charge_type +
      this.service_type +
      this.div_settele +
      this.last_charge_month +
      this.camp_type +
      this.tracking_id +
      this.terminal_type +
      this.success_url +
      this.cancel_url +
      this.error_url +
      this.pagecon_url +
      this.free1 +
      this.free2 +
      this.free3 +
      this.free_csv +
      resultOrderDetail +
      this.request_date +
      this.limit_second +
      this.hashkey;
    return result;
  };
}

// 日時の取得
function getYYYYMMDDHHMMSS() {
  var now = new Date();
  return (
    now.getFullYear() +
    zeroPadding(now.getMonth() + 1) +
    zeroPadding(now.getDate()) +
    zeroPadding(now.getHours()) +
    zeroPadding(now.getMinutes()) +
    zeroPadding(now.getSeconds())
  );
}

function zeroPadding(num) {
  if (num < 10) {
    num = '0' + num;
  }
  return num + '';
}

function feppost(order, connectUrl) {
  var form = $('<form></form>', {
    action: connectUrl,
    method: 'POST',
    'accept-charset': 'Shift_JIS',
  }).hide();

  var body = $('body');
  body.append(form);
  form.append($('<input>', { type: 'hidden', name: 'pay_method', value: order.pay_method }));
  form.append($('<input>', { type: 'hidden', name: 'merchant_id', value: order.merchant_id }));
  form.append($('<input>', { type: 'hidden', name: 'service_id', value: order.service_id }));
  form.append($('<input>', { type: 'hidden', name: 'cust_code', value: order.cust_code }));
  form.append($('<input>', { type: 'hidden', name: 'sps_cust_no', value: order.sps_cust_no }));
  form.append(
    $('<input>', { type: 'hidden', name: 'sps_payment_no', value: order.sps_payment_no }),
  );
  form.append($('<input>', { type: 'hidden', name: 'order_id', value: order.order_id }));
  form.append($('<input>', { type: 'hidden', name: 'item_id', value: order.item_id }));
  form.append($('<input>', { type: 'hidden', name: 'pay_item_id', value: order.pay_item_id }));
  form.append($('<input>', { type: 'hidden', name: 'item_name', value: order.item_name }));
  form.append($('<input>', { type: 'hidden', name: 'tax', value: order.tax }));
  form.append($('<input>', { type: 'hidden', name: 'amount', value: order.amount }));
  form.append($('<input>', { type: 'hidden', name: 'pay_type', value: order.pay_type }));
  form.append(
    $('<input>', { type: 'hidden', name: 'auto_charge_type', value: order.auto_charge_type }),
  );
  form.append($('<input>', { type: 'hidden', name: 'service_type', value: order.service_type }));
  form.append($('<input>', { type: 'hidden', name: 'div_settele', value: order.div_settele }));
  form.append(
    $('<input>', { type: 'hidden', name: 'last_charge_month', value: order.last_charge_month }),
  );
  form.append($('<input>', { type: 'hidden', name: 'camp_type', value: order.camp_type }));
  form.append($('<input>', { type: 'hidden', name: 'tracking_id', value: order.tracking_id }));
  form.append($('<input>', { type: 'hidden', name: 'terminal_type', value: order.terminal_type }));
  form.append($('<input>', { type: 'hidden', name: 'success_url', value: order.success_url }));
  form.append($('<input>', { type: 'hidden', name: 'cancel_url', value: order.cancel_url }));
  form.append($('<input>', { type: 'hidden', name: 'error_url', value: order.error_url }));
  form.append($('<input>', { type: 'hidden', name: 'pagecon_url', value: order.pagecon_url }));
  form.append($('<input>', { type: 'hidden', name: 'free1', value: order.free1 }));
  form.append($('<input>', { type: 'hidden', name: 'free2', value: order.free2 }));
  form.append($('<input>', { type: 'hidden', name: 'free3', value: order.free3 }));
  form.append($('<input>', { type: 'hidden', name: 'free_csv', value: order.free_csv }));
  form.append($('<input>', { type: 'hidden', name: 'request_date', value: order.request_date }));
  form.append($('<input>', { type: 'hidden', name: 'limit_second', value: order.limit_second }));
  form.append($('<input>', { type: 'hidden', name: 'hashkey', value: order.hashkey }));
  form.append($('<input>', { type: 'hidden', name: 'sps_hashcode', value: order.sps_hashcode }));

  for (let i = 0; i < order.orderDetail.length; i++) {
    form.append(
      $('<input>', { type: 'hidden', name: 'dtl_rowno', value: order.orderDetail[i].dtl_rowno }),
    );
    form.append(
      $('<input>', {
        type: 'hidden',
        name: 'dtl_item_id',
        value: order.orderDetail[i].dtl_item_id,
      }),
    );
    form.append(
      $('<input>', {
        type: 'hidden',
        name: 'dtl_item_name',
        value: order.orderDetail[i].dtl_item_name,
      }),
    );
    form.append(
      $('<input>', {
        type: 'hidden',
        name: 'dtl_item_count',
        value: order.orderDetail[i].dtl_item_count,
      }),
    );
    form.append(
      $('<input>', { type: 'hidden', name: 'dtl_tax', value: order.orderDetail[i].dtl_tax }),
    );
    form.append(
      $('<input>', { type: 'hidden', name: 'dtl_amount', value: order.orderDetail[i].dtl_amount }),
    );
    form.append(
      $('<input>', { type: 'hidden', name: 'dtl_free1', value: order.orderDetail[i].dtl_free1 }),
    );
    form.append(
      $('<input>', { type: 'hidden', name: 'dtl_free2', value: order.orderDetail[i].dtl_free2 }),
    );
    form.append(
      $('<input>', { type: 'hidden', name: 'dtl_free3', value: order.orderDetail[i].dtl_free3 }),
    );
  }

  form.submit();
}
