// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './styles.scss';

function TopTitleKuzipa() {
  return (
    <div className="top-title-kuzipa">
      <div className="icon-left">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22.959"
          height="38.751"
          viewBox="0 0 22.959 38.751"
        >
          <path
            id="Path_54278"
            data-name="Path 54278"
            d="M0,0H7.79L1.558,38.95H0Z"
            transform="translate(0 3.169) rotate(-24)"
            fill="#f9be2e"
          />
        </svg>
      </div>

      <div className="text-kuzipa">
        <div className="title-logo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/1999/xlink"
            width="147.076"
            height="33"
            viewBox="0 0 147.076 33"
          >
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_524"
                  data-name="Rectangle 524"
                  width="147.076"
                  height="33"
                  fill="none"
                />
              </clipPath>
            </defs>
            <g id="Group_3912" data-name="Group 3912" transform="translate(0 0)">
              <rect
                id="Rectangle_521"
                data-name="Rectangle 521"
                width="5.1"
                height="31.022"
                transform="translate(0 1.537)"
                fill="#e63556"
              />
              <path
                id="Path_36"
                data-name="Path 36"
                d="M27.182,35.133l-13.54-17.7L26.448,4.111h7.073l-13.169,13.7L33.6,35.133Z"
                transform="translate(-8.542 -2.574)"
                fill="#38a1db"
              />
              <g id="Group_95" data-name="Group 95" transform="translate(0 0)">
                <g id="Group_94" data-name="Group 94" clipPath="url(#clip-path)">
                  <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M85.519,38.451V48.588a3.64,3.64,0,1,1-7.279,0V38.45h-4.85V48.588a8.489,8.489,0,1,0,16.978,0V38.451Z"
                    transform="translate(-45.955 -24.077)"
                    fill="#f9be00"
                  />
                  <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M374.955,24.226,373.422,0h7.484l-1.533,24.226Z"
                    transform="translate(-233.829 0)"
                    fill="#38a1db"
                  />
                  <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M378.049,77.067a3.346,3.346,0,1,1,3.346-3.346,3.35,3.35,0,0,1-3.346,3.346"
                    transform="translate(-234.631 -44.068)"
                    fill="#e63556"
                  />
                  <rect
                    id="Rectangle_522"
                    data-name="Rectangle 522"
                    width="5.1"
                    height="31.022"
                    transform="translate(90.556 1.537)"
                    fill="#e63556"
                  />
                  <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M262.719,4.114h-4v4.85h4a5.115,5.115,0,1,1,0,10.231h-4v4.848h4a9.965,9.965,0,1,0,0-19.929"
                    transform="translate(-162.007 -2.576)"
                    fill="#38a1db"
                  />
                  <rect
                    id="Rectangle_523"
                    data-name="Rectangle 523"
                    width="4.85"
                    height="18.185"
                    transform="translate(78.941 14.373)"
                    fill="#f9be00"
                  />
                  <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M220.939,12.045l5,3.5-6.057,3.331Z"
                    transform="translate(-137.685 -7.542)"
                    fill="#38a1db"
                  />
                  <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M204.234,12.045l-5,3.5,6.057,3.331Z"
                    transform="translate(-124.757 -7.542)"
                    fill="#e63556"
                  />
                  <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M131.494,8.962l-.056-4.85h17.082l-3.226,4.85Z"
                    transform="translate(-82.304 -2.575)"
                    fill="#e63556"
                  />
                  <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M151.013,35.134H125.34L145.974,4.111h6.166L134.732,30.283h16.281Z"
                    transform="translate(-78.486 -2.575)"
                    fill="#38a1db"
                  />
                  <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M314.969,64.278l2-4.6h5.924l2,4.6Z"
                    transform="translate(-197.227 -37.368)"
                    fill="#38a1db"
                  />
                  <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M304,27.112,301.219,20.7,290.454,45.523h5.559Z"
                    transform="translate(-181.877 -12.964)"
                    fill="#f9be00"
                  />
                  <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M337.7,32.559,323.575,0,320.8,6.408l11.342,26.151Z"
                    transform="translate(-200.876 0)"
                    fill="#e63556"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <p className="mb-0">へようこそ</p>
      </div>
      <div className="icon-right">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31.851"
          height="34.158"
          viewBox="0 0 31.851 34.158"
        >
          <path
            id="Path_54277"
            data-name="Path 54277"
            d="M0,38.95H7.79L1.558,0H0Z"
            transform="translate(5.789 34.158) rotate(-138)"
            fill="#f9be2e"
          />
        </svg>
      </div>
    </div>
  );
}

export default TopTitleKuzipa;
