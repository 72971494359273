// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  convertSaleStatusToClassName,
  convertSaleStatusToColor,
} from '../../../../utils/convertSaleStatus';
import { AiOutlineClockCircle } from 'react-icons/ai';

export default function LotteryItem(props) {
  const { data } = props;

  const history = useHistory();

  return (
    <>
      {data && (
        <div className="card-lottery-top card" onClick={() => history.push(`/lottery/${data.id}`)}>
          <div className="card-img-top">
            <img src={data.image} alt={data.name} />
          </div>
          <div className="card-body">
            <div
              style={{
                backgroundImage: `url(${convertSaleStatusToClassName(data.sale_status)})`,
                backgroundSize: '100% 100%',
              }}
              className="sale-status"
            >
              <div>{data.sale_status}</div>
            </div>
            <div className="name">{data.name}</div>
            <div className="end-date" style={{ color: convertSaleStatusToColor(data.sale_status) }}>
              <AiOutlineClockCircle /> {data.end_date}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
