// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useTranslation } from 'react-i18next';
import InformationPurchaseHistory from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailSP/InformationPurchaseHistory';
import ListPrize from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailSP/ListPrize';
import DeliveryAddress from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailSP/DeliveryAddress';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import LotteryIcon from '../../components/pages/myCollection/lotteryDetailSP/LotteryIcon';

export default function PurchaseHistoryDetailSP(props) {
  const { t } = useTranslation();
  const { data } = props;
  const dataOrders = data?.order;
  const dataPrize = data?.list_prize;
  const lotteryEventId = data?.lottery_event_id;

  return (
    <>
      <div className="mb80">
        <InformationPurchaseHistory dataOrders={dataOrders} data={data} />
      </div>
      <div className="title-detail-sp">{t('titleListPrize')}</div>
      <div className="border-sp mb40" />
      <div className="list-prize-sp">
        {dataPrize?.length > 0 &&
          dataPrize?.map((prize, index) => {
            return <ListPrize key={index} prize={prize} />;
          })}
      </div>
      <div className="border-sp" style={{ marginBottom: 80, marginTop: 20 }} />

      <div>
        <div className="title-detail-sp">お届け予定商品 </div>
        <div className="border-sp mb40" />
        <div
          className="d-flex flex-wrap"
          style={{ paddingTop: 15, width: 'max-content', maxWidth: '100%' }}
        >
          {data?.all_prize?.map((item, index) => (
            <LotteryIcon data={item} key={index} flag={1} lotteryEventId={lotteryEventId} />
          ))}
        </div>
        <div style={{ fontSize: 14 }}>※数字は当せん個数。（）内は破棄数。</div>
      </div>

      <div className="mb40">
        <DeliveryAddress dataOrders={dataOrders} />
      </div>
      <ScrollButton />
    </>
  );
}
