// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

function ModalDiscardGuideSP(props) {
  const { isShowDiscardGuide, setShowDiscardGuide } = props;
  return (
    <>
      <Modal
        size="md"
        show={isShowDiscardGuide}
        onHide={() => {
          setShowDiscardGuide(false);
        }}
      >
        <Modal.Body style={{ padding: 0, backgroundColor: '#F7F7F7' }}>
          <div className="popup-discard-item-guide">
            <AiOutlineClose id="close-icon" onClick={() => setShowDiscardGuide(false)} />

            <div className="popup-discard-content" id="session1">
              <div id="s1c1">例えば、</div>
              <div id="s1c2">
                <div>「大きな商品が当せんしすぎた…」</div>
                <div>「同じ商品がたくさん当せんしてしまった…」</div>
              </div>
              <div id="s1c3">そのような場合に商品を破棄することができます。</div>
            </div>

            <div id="text-banner">
              <span>破棄とは？</span>
            </div>

            <div className="popup-discard-content" id="session2">
              <div id="s2c1">
                <div>当せんした商品を破棄することにより、受け取り数を調整する機能です。</div>
                <div>破棄する場合、くじ購入後にマイページより設定することができます。</div>
                <div>
                  不要な商品を破棄することで、新たにくじを引けるポイントに変換することも可能です！
                </div>
              </div>
              <div id="s2c2">
                <div>※破棄した商品の代金は返金されません。</div>
              </div>
              <div id="s2c5">
                ※破棄により付与されたポイントの有効期限は、ポイント付与日から180日間です。
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalDiscardGuideSP;
