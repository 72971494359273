// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import LotteryDetail from '../lotteryDetail';

function LotteryTrial(props) {
  return <LotteryDetail />;
}

export default LotteryTrial;
