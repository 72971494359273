// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { OUT_OF_STOCK, PERIOD_CURRENT, PERIOD_EXPIRED } from '../../../constants';
import periodCurrentIcon from '../../../../assets/imgs/lotteryDetail/periodCurrentIcon.png';

export default function WChanceCampaignPeriod(props) {
  const { t } = useTranslation();
  const { prizes } = props;

  const schedulePeriod = (status, quantity) => {
    let result = '';
    if (status === PERIOD_EXPIRED) {
      result = 'background-expired';
    } else if (status === PERIOD_CURRENT && quantity <= OUT_OF_STOCK) {
      result = 'background-expired background-current';
    } else if (status === PERIOD_CURRENT) {
      result = 'background-current';
    }
    return result;
  };

  return (
    <>
      <div className="w-chance-campaign-period-title">
        <h3>{t('wChangeCampaignTitle')}</h3>
      </div>

      <p className="des-campaign-period">{t('descriptionCampaignPeriod')}</p>

      {prizes.map((prize, index) => {
        return (
          <div
            className={`schedule-period ${schedulePeriod(prize.status, prize.quantity)}`}
            key={index}
          >
            <p className="mb-0 title">{prize.title} </p>

            {prize.status === PERIOD_CURRENT && (
              <img src={periodCurrentIcon} alt="img" className="title-tag" />
            )}

            {prize.status === PERIOD_EXPIRED && <p className="text">{t('endOfLotteryPeriod')}</p>}
            {prize.status === PERIOD_CURRENT && prize.quantity <= OUT_OF_STOCK && (
              <p className="text">{t('outOfStock')}</p>
            )}

            <p className="mb-0 date-period">
              {prize.period_start_date_format} ～ {prize.period_end_date_format}
            </p>
          </div>
        );
      })}
      <div className="note-w-chance">
        <small>{t('noteWChance')}</small>
      </div>
    </>
  );
}
