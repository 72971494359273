// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import styled from 'styled-components';
import { TOP_IMAGES } from '../../../../../assets/imgs';

const BubbleStyled = styled.div`
  cursor: pointer;
  width: 218px;
  height: 218px;
  @media only screen and (max-width: 768px) {
    width: 110px;
    height: 110px;
  }
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${(props) => convertClassNameToCss(props.className)}
`;

const convertClassNameToCss = (className) => {
  switch (className) {
    case 'bubble-myCollection':
      return `
      > .bubble-icon {
          width: 93.79px;
          margin-top: 25px;
          @media only screen and (max-width: 768px) {
            width: 44.15px;
            margin-top: 15px;
          }
        }
      
        > .bubble-text {
          width: 70%;
          margin-top: 10px;
          font-size: 16px;
          @media only screen and (max-width: 768px) {
            font-size: 10px;
            margin-top: 1px;
          }
        }

      background-image: url(${TOP_IMAGES.Bubbles.CircleRed});
      background-size: 100% 100%;
      margin-right: 40px;
      @media only screen and (max-width: 768px) {
        margin-right: 2%;
      }
      &:hover {
        background-image: url(${TOP_IMAGES.Bubbles.PolygonRed});
      }`;
    case 'bubble-useGuide':
      return `
        > .bubble-icon {
          width: 107px;
          margin-top: 20px;
          @media only screen and (max-width: 768px) {
            width: 50px;
            margin-top: 15px;
          }
        }
      
        > .bubble-text {
          width: 70%;
          margin-top: 10px;
          font-size: 16px;
          @media only screen and (max-width: 768px) {
            font-size: 10px;
            margin-top: 1px;
          }
        }

        background-image: url(${TOP_IMAGES.Bubbles.CircleGreen});
        background-size: 100% 100%;
        margin-right: 40px;
        @media only screen and (max-width: 768px) {
          margin-right: 2%;
        }
        &:hover {
          background-image: url(${TOP_IMAGES.Bubbles.PolygonGreen});
        }
        `;
    case 'bubble-gotoBuy':
      return `
          > .bubble-icon {
            width: 79.08px;
            height: 96.84px;
            margin-top: 25px;
            @media only screen and (max-width: 768px) {
              width: 37.22px;
              height: 45.58px;
              margin-top: 15px;
            }
          }
        
          > .bubble-text {
            width: 70%;
            margin-top: 10px;
            font-size: 16px;
            @media only screen and (max-width: 768px) {
              font-size: 10px;
              margin-top: 1px;
            }
          }
          background-image: url(${TOP_IMAGES.Bubbles.CircleOrange});
          background-size: 100% 100%;
          &:hover {
            background-image: url(${TOP_IMAGES.Bubbles.PolygonOrange});
          }`;
  }
  return '';
};

export default function Bubble(props) {
  const { img, text, action, className } = props;

  return (
    <BubbleStyled className={`${className}`} onClick={() => action()}>
      <img src={img} className="bubble-icon" />
      <div className="bubble-text fw-bold text-center text-white">{text}</div>
    </BubbleStyled>
  );
}
