// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './styles.scss';

function Tutorial(props) {
  const { image, text, textAbove, status } = props;

  const convertStyle = (status) => {
    let result = {
      background: '',
      color: '',
      className: '',
    };
    switch (status) {
      case 'PHONE':
        result = {
          background: '#FD486B',
          color: '#F55076',
          className: 'phone',
          wrapClass: 'wrap-phone',
        };
        break;
      case 'FLOWER':
        result = {
          background: '#40B569',
          color: '#40B569',
          className: 'flower',
          wrapClass: 'wrap-flower',
        };
        break;
      case 'BAG':
        result = {
          background: '#FF6C00',
          color: '#FF6C00',
          className: 'bag',
          wrapClass: 'wrap-bag',
        };
        break;
      case 'T_SHIRT':
        result = {
          background: '#1D9DED',
          color: '#1D9DED',
          className: 't-shirt',
          wrapClass: 'wrap-t-shirt',
        };
        break;
      case 'HAT':
        result = {
          background: '#F9BE2E',
          color: '#F9BE2E',
          className: 'hat',
          wrapClass: 'wrap-hat',
        };
        break;
      default:
        break;
    }
    return result;
  };
  return (
    <div className={`${convertStyle(status).wrapClass} wrap-tutorial`}>
      <div className="image-tutorial" style={{ background: convertStyle(status).background }}>
        <img src={image} alt="image" className={convertStyle(status).className} />
      </div>
      <div className="text-tutorial" style={{ color: convertStyle(status).color }}>
        <div className={status === 'T_SHIRT' ? 'width-t-shirt-custom-sp' : ''}>{textAbove}</div>
        <div className={status === 'T_SHIRT' || status === 'HAT' ? 'font-size-custom' : ''}>
          {text}
        </div>
      </div>
    </div>
  );
}

export default Tutorial;
