// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import LotteryItem from './LotteryItem';
import './LotteryItem.scss';

export default function ListLottery(props) {
  const { listLottery } = props;

  return (
    <div className="list-lottery-wrapper d-flex flex-wrap">
      {listLottery && listLottery.map((item, index) => <LotteryItem data={item} key={index} />)}
    </div>
  );
}
