// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { Card, Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { convertRankName } from '../../../../utils/convertSaleStatus';
import { useHistory } from 'react-router-dom';

export default function PreviewPhoto(props) {
  const { t } = useTranslation();
  const { data, isInResultPage = false } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const history = useHistory();
  const pathLotteryTrial = '/result/trial';
  const pathSite = history.location.pathname;

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="preview-photo"
      >
        <Modal.Body>
          <Slider {...settings}>
            <div>
              <div className="intro-thumbnail">
                <Card.Img
                  variant="top"
                  src={
                    isInResultPage && pathLotteryTrial !== pathSite
                      ? data?.image[0]?.image_url
                      : data?.image?.image_url
                  }
                />
              </div>
              <div className="preview-description mt-4">
                <div>{`${convertRankName(data?.rank_name)} - ${data?.number_order} ${
                  data?.title
                }`}</div>
              </div>
            </div>
          </Slider>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="cursor-pointer" onClick={() => props.onHide()}>
            {t('textBack')}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
