// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';

export default function VerifyFail() {
  return (
    <>
      <div className="container text-center mt-4">
        <div>URLの有効期限が切れました。</div>
      </div>
    </>
  );
}
