// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { login } from '../../store/features/auth';
import useMessage from '../../components/common/toast/UseMessage';

import { useAppDispatch, useAppSelector } from '../../store';
import { AuthApis } from '../../services/auth';
import { listsErrorNotShowToast } from '../../constants';

export default function TwitterCallbackPage() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const search = qs.parse(history.location.search);

  const { openMessageError, openMessage } = useMessage();

  const { auth } = useAppSelector((state) => state);

  const isLogin = !!auth.token;

  useEffect(async () => {
    try {
      if (search.oauth_token && search.oauth_verifier) {
        const params = {
          provider: 'twitter',
          oauth_token: search.oauth_token,
          oauth_verifier: search.oauth_verifier,
        };
        let res = null;
        if (isLogin) {
          res = await AuthApis.linkAccount(params);
          openMessage({ message: res?.data?.success, variant: 'success' });
          history.push('/sns-management');
        } else {
          res = await AuthApis.loginSocial(params);
          const { token } = res.data;
          if (token) {
            dispatch(login(res.data));
            history.push('/');
          } else {
            history.push({
              pathname: '/register-social',
              state: { data: res.data },
            });
          }
        }
      } else {
        openMessage({ message: 'ログインが失敗しました。', variant: 'error' });
        if (isLogin) {
          history.push('/sns-management');
        } else {
          history.push('/login');
        }
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
      history.push('/login');
    }
  }, []);

  return <></>;
}
