// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
export default function InformationPayment(props) {
  const { dataOrders } = props;
  const paymentMethod = dataOrders?.payment_method;
  const { t } = useTranslation();

  return (
    <div>
      <Table bordered className="mb40">
        <tbody>
          <tr>
            <th>{t('textShippingFee')}</th>
            <td>{dataOrders?.shipping_fee?.toLocaleString('ja-JP')}円</td>
          </tr>
          <tr>
            <th>{t('textTotalPricePurchase')}</th>
            <td>{dataOrders?.total_price?.toLocaleString('ja-JP')}円</td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <tbody>
          <tr>
            <th>{t('textAmountCreditCard')}</th>
            <td>{dataOrders?.amount_by_credit_card?.toLocaleString('ja-JP')}円</td>
          </tr>
          <tr>
            <th>{t('textPaymentMethod')}</th>
            <td className="d-flex-payment">
              {paymentMethod?.credit_card && (
                <span style={{ paddingBottom: '7px' }}>{paymentMethod?.credit_card}</span>
              )}
              {paymentMethod?.point ? (
                <span>{paymentMethod?.point}</span>
              ) : (
                <span>ポイント利用: 0Pt</span>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
