// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';

function SNSManage(props) {
  const { image, name, status, isPC } = props;
  const { t } = useTranslation();
  const SETTING = 1;
  return (
    <div className="sns-manager">
      <div className="name-social">
        <img src={image} alt="icon" />
        <p className="mb-0">{name}</p>
      </div>
      <div className="sns-status">
        <p className="mb-0">
          {isPC ? t('snsManagerFormat') : t('snsManagerFormatSP')}{' '}
          <span style={status === SETTING ? { color: '#F55076' } : {}}>
            {status === SETTING ? '連携中' : '未設定'}
          </span>
        </p>
      </div>
    </div>
  );
}

export default SNSManage;
