// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { AuthApis } from '../../services/auth';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import { useHistory } from 'react-router-dom';
import { validateEmail } from '../../validations/validateEmail';
import { validateConfirmPassword, validatePassword } from '../../validations/validatePassword';
import BoxRequired from '../../components/common/boxRequired';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import TButton from '../../components/common/button';
import ConfirmEmail from './confirmEmail';
import useMessage from '../../components/common/toast/UseMessage';
import TopHeader from '../../components/common/topHeader';
import { login } from '../../store/features/auth';
import '../login/styles.scss';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { listsErrorNotShowToast } from '../../constants';
import queryString from 'query-string';

export default function Register() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError, openMessage } = useMessage();
  const history = useHistory();
  const search = queryString.parse(history.location.search);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationMsg, setValidationMsg] = useState({});
  const [validationMsgApi, setValidationMsgApi] = useState('');
  const [isRegisterSuccess, setRegisterSuccess] = useState(false);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
    setValidationMsgApi('');
  };

  const moveToLogin = () => {
    history.push('/login');
  };

  const validateAll = () => {
    const error = {
      email: '',
      password: '',
      confirmPassword: '',
    };
    error.email = validateEmail(email.trim());
    error.password = validatePassword(password);
    error.confirmPassword = validateConfirmPassword(password, confirmPassword);
    setValidationMsg(error);
    for (let i = 0; i < Object.keys(error).length; i++) {
      if (error[Object.keys(error)[i]] !== '') {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    const isValid = validateAll();
    if (!isValid) return;
    dispatch(showLoading());
    try {
      await AuthApis.register({
        email: email,
        password: password,
        password_confirmation: confirmPassword,
      });
      setRegisterSuccess(true);
    } catch (error) {
      setValidationMsgApi(error.response.data.message);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleLoginTwitter = async () => {
    dispatch(showLoading());
    try {
      const res = await AuthApis.loginTwitter();
      const { login_url } = res.data;
      if (login_url) {
        window.location = login_url;
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  const responseSocial = async (response, type) => {
    const { accessToken } = response;
    dispatch(showLoading());
    try {
      if (accessToken) {
        const formData = {
          provider: type,
          access_token: accessToken,
        };
        const res = await AuthApis.loginSocial(formData);
        const { token } = res.data;
        if (token) {
          dispatch(login(res.data));
          if (search?.callbackUrl) {
            history.push(search?.callbackUrl);
          } else {
            history.push('/');
          }
        } else {
          history.push({
            pathname: '/register-social',
            state: { data: res.data },
          });
        }
      } else {
        openMessage({ message: 'ログインが失敗しました。', variant: 'error' });
        history.push('/register');
      }
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <TopHeader title={t('headerRegister')} />
      {isRegisterSuccess ? (
        <ConfirmEmail data={email} />
      ) : (
        <div className="login">
          <div className="main-content">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="form-login">
                <div className="title-login">{t('titleRegister')}</div>
                <div className="mt-4">
                  <BoxRequired text={t('txtEmail')} />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    maxLength="255"
                    className="form-control"
                    placeholder="test@example.com"
                    value={email}
                    autoComplete="off"
                    onChange={handleChangeEmail}
                  ></input>
                </div>
                {validationMsg.email && <div className="txt_error">{validationMsg.email}</div>}
                {validationMsgApi && <div className="txt_error">{validationMsgApi}</div>}
                <div className="mt-4">
                  <BoxRequired text={t('txtPassword')} />
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="半角英数字8~16文字"
                    value={password}
                    autoComplete="off"
                    onChange={(event) => setPassword(event.target.value)}
                  ></input>
                </div>
                {validationMsg.password && (
                  <div className="txt_error">{validationMsg.password}</div>
                )}
                <div className="mt-4">
                  <BoxRequired text={t('txtConfirmPassword')} />
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="半角英数字8~16文字"
                    value={confirmPassword}
                    autoComplete="off"
                    onChange={(event) => setConfirmPassword(event.target.value)}
                  ></input>
                </div>
                {validationMsg.confirmPassword && (
                  <div className="txt_error">{validationMsg.confirmPassword}</div>
                )}
                <div className="form-submit">
                  <TButton
                    title={t('buttonRegister')}
                    className="btn-login danger btn-register"
                    type="submit"
                  />
                </div>
              </div>
            </form>
            <div>
              <div className="form-login-social">
                <div className="title-login-social">{t('headerRegisterSocial')}</div>
                <div className="mt-4">
                  <div className="btn-social">
                    <FacebookLogin
                      appId={process.env.APP_ID_FACEBOOK}
                      fields="name,email,picture"
                      callback={(res) => responseSocial(res, 'facebook')}
                      textButton={t('btnFacebook')}
                      cssClass="btnFacebook"
                      icon={<FaFacebookF className="icon-social" />}
                      isMobile={false}
                    />
                    <button type="button" className="btnTwitter" onClick={handleLoginTwitter}>
                      <FaTwitter className="icon-social" />
                      {t('btnTwitter')}
                    </button>
                    <GoogleLogin
                      clientId={process.env.CLIENT_ID_GOOGLE}
                      onSuccess={(res) => responseSocial(res, 'google')}
                      cookiePolicy={'single_host_origin'}
                      className="btnGoogle"
                      icon={false}
                    >
                      <FcGoogle className="icon-google" />
                      {t('btnGoogle')}
                    </GoogleLogin>
                  </div>
                </div>
              </div>
            </div>
            <div className="move-to-login">
              <TButton
                title={t('buttonMoveToLogin')}
                className="btn-login info btn-move-to-login"
                onClick={moveToLogin}
              />
            </div>
          </div>
        </div>
      )}
      <ScrollButton />
    </>
  );
}
