// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { ON_SALE } from '../../../constants';
import TButton from '../../common/button';
import JSCookie from 'js-cookie';

export default function OverViews(props) {
  const { data } = props;
  const lotteryEvent = data?.lottery_event;
  const saleStatus = lotteryEvent?.sale_status;
  const { id } = useParams();

  const { t } = useTranslation();

  const history = useHistory();

  const handleClick = () => {
    history.push(`/purchase/${id}`);
  };

  const token = JSCookie.get('token');

  const pathLotteryTrial = '/trial';
  const pathSite = history.location.pathname;

  return (
    <div className="overview-lottery-detail">
      <div className="text-center">
        <h1 className="title-overview">{lotteryEvent.name}</h1>
        <div className="line" />
        <div className="title-description my-color-black-400">
          <p>{lotteryEvent.description}</p>
        </div>
      </div>
      <div className="border my-overview-content-pc">
        <table className="table table-borderless table-overviews my-color-black-400">
          <tbody>
            {pathLotteryTrial !== pathSite && (
              <tr className="overview-info">
                <th scope="col">
                  <span className="my-tag my-bg-red">{t('lotteryEventStartDate')}</span>
                </th>
                <td className="d-flex flex-column">
                  <span scope="col" className="no-padding" style={{ display: 'block' }}>
                    {lotteryEvent?.start_date_format?.replace('から', '~')}
                  </span>
                  <span scope="col" className="no-padding" style={{ display: 'block' }}>
                    {lotteryEvent?.end_date_format}
                  </span>
                </td>
              </tr>
            )}

            <tr className="overview-info">
              <th scope="col">
                <span className="my-tag my-bg-green">{t('lotteryEventPrice')}</span>
              </th>
              <td scope="col">
                {t('priceLottery').replace(
                  'param',
                  pathLotteryTrial === pathSite
                    ? 0
                    : Math.floor(
                        lotteryEvent?.price + (lotteryEvent?.price * lotteryEvent?.tax_rate) / 100,
                      ).toLocaleString('ja-JP'),
                )}
              </td>
            </tr>
            <tr className="overview-info">
              <th scope="col">
                <span className="my-tag my-bg-orange">{t('lotteryEventTransportFee')}</span>
              </th>
              <td scope="col">
                {pathLotteryTrial === pathSite ? 'なし' : lotteryEvent.shipping_fee}
              </td>
            </tr>
            <tr className="overview-info">
              <th scope="col">
                <span className="my-tag my-bg-blue">{t('lotteryEventEstimateDelivery')}</span>
              </th>
              <td scope="col">
                {pathLotteryTrial === pathSite
                  ? 'お届けはありません。'
                  : lotteryEvent.estimated_delivery_date}
              </td>
            </tr>
            {pathLotteryTrial === pathSite && (
              <tr className="overview-info">
                <th scope="col">
                  <span className="my-tag my-bg-black-200">{t('lotteryEventTermsToPay')}</span>
                </th>
                <td scope="col" className="my-terms-to-pay">
                  {pathLotteryTrial === pathSite
                    ? t('termsToPayAttentionTrial')
                    : t('termsToPayAttention')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {lotteryEvent?.banner_image?.image_url && (
        <div className="banner-overview">
          <img src={lotteryEvent?.banner_image?.image_url} alt="banner" />
        </div>
      )}
      {pathSite === pathLotteryTrial && (
        <div className="text-center my-btn-progress-purchase">
          <TButton
            title={t('buttonProgressToPurchase')}
            onClick={() => history.push('/purchase/trial')}
            className={'danger'}
          />
        </div>
      )}
      {token && pathSite !== pathLotteryTrial && (
        <div className="text-center my-btn-progress-purchase">
          <TButton
            title={
              saleStatus === ON_SALE
                ? t('buttonProgressToPurchase')
                : saleStatus === 0
                ? t('textPrepare')
                : t('textOffSale')
            }
            onClick={handleClick}
            className={saleStatus === ON_SALE ? 'danger' : 'btn-disable-purchase'}
            disabled={saleStatus !== ON_SALE}
          />
        </div>
      )}
      {!token && pathSite !== pathLotteryTrial && (
        <div className="d-flex justify-content-center mb-5 button-wrapper">
          <TButton
            className="danger me-3"
            title="新規会員登録"
            onClick={() => history.push('/register')}
          />
          <TButton
            className="info"
            title="ログイン"
            onClick={() => history.push(`/login?callbackUrl=%2Flottery%2F${id}`)}
          />
        </div>
      )}
    </div>
  );
}
