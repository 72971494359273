// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TButton from '../../components/common/button';
import './styles.scss';

export default function ModalSuccess(props) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body className="size-modal">
          <div className="text-modal">
            新しいメールアドレスに確認メールを送信しました。 <br />
            確認して、メールアドレス変更を完了してください
          </div>
          <div className="text-center">
            <TButton
              title={t('btnMoveToMyPage')}
              className="btn danger"
              onClick={() => history.push('/my-page')}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
