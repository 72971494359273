// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import RecommendLotteryItem from '../RecommendLotteryItem';
import './recommendLottery.scss';

export default function RecommendLottery(props) {
  const { recommendLottery } = props;

  const history = useHistory();
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <BiChevronRight style={{ fill: '#464646' }} />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <BiChevronLeft style={{ fill: '#464646' }} />
      </div>
    );
  };

  const settings = {
    className: 'slider',
    dots: true,
    infinite: true,
    slidesToShow:
      recommendLottery.length > 3
        ? 3
        : recommendLottery.length > 1
        ? recommendLottery.length - 1
        : 1,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="recommend-carousel">
      <Slider {...settings}>
        {recommendLottery.map((item, index) => {
          return (
            <RecommendLotteryItem
              collection={item}
              key={index}
              isShowTime
              isRecommendLottery
              onClick={() => history.push(`/lottery/${item.id}`)}
            />
          );
        })}
      </Slider>
    </div>
  );
}
