// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

export const loadingPageSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading: () => {
      return true;
    },
    hideLoading: () => {
      return false;
    },
  },
});

export const { showLoading, hideLoading } = loadingPageSlice.actions;
export default loadingPageSlice.reducer;
