// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TopHeader from '../../components/common/topHeader';

function ContactSuccess(props) {
  const history = useHistory();
  const data = history?.location?.state;
  useEffect(() => {
    if (!data) {
      history.push('/error/404');
    }
  }, []);
  return (
    <>
      <TopHeader title="お問い合わせ" />
      <div className="wrap_contact_success">
        <p>{`フォームが送信されました。\nお問い合わせありがとうございました。`}</p>
      </div>
    </>
  );
}

export default ContactSuccess;
