// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';

import TopHeader from '../../components/common/topHeader';
import LotteryCard from '../../components/pages/myCollection/lotteryDetailSP/LotteryCard';
import LotteryIcon from '../../components/pages/myCollection/lotteryDetailSP/LotteryIcon';
import LotteryInfo from '../../components/pages/myCollection/lotteryDetailSP/LotteryInfo';
import HistoryPurchase from '../../components/pages/myCollection/lotteryDetailSP/HistoryPurchase';
import { useHistory, useParams } from 'react-router-dom';
import TButton from '../../components/common/button';
import { facebookIcon, GUIDE, twitterIcon } from '../../../assets/imgs';
import ModalDiscardGuideSP from '../../components/common/modalDiscardGuide/ModalDiscardGuideSP';

const MyCollectionLotteryDetailSP = (props) => {
  const { lotteryData, handleShare, getWidth } = props;
  const { lotteryId } = useParams();

  const [isShowDiscardGuide, setShowDiscardGuide] = useState(false);

  const history = useHistory();

  const eleWrap = document?.getElementById('share-sns-wrapper')?.offsetWidth;

  const [width, setWidth] = useState(eleWrap);
  useEffect(() => {
    setWidth(eleWrap);
  }, [eleWrap]);
  useEffect(() => {
    if (width) {
      getWidth(width);
    }
  }, [width]);
  useEffect(() => {
    function handleResize() {
      setWidth(document.getElementById('share-sns-wrapper')?.offsetWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <TopHeader title="Myコレクション" />
      <div className="lottery-detail-sp">
        <LotteryInfo lotteryInfo={lotteryData.lottery_info} />
        <div className="fw-bold" style={{ marginTop: 72, marginBottom: 20, fontSize: 18 }}>
          当せん賞品
        </div>
        <div style={{ fontSize: 14 }}>当せんした商品は以下になります。</div>
        {lotteryData.lottery_info?.can_discard && (
          <>
            <div style={{ fontSize: 14 }}>
              受取数を調整したい方は
              <span
                style={{ color: '#1D9DED', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => setShowDiscardGuide(true)}
              >
                破棄について
              </span>
              を確認ください。
            </div>
            <span style={{ color: '#FF0000', fontSize: 12 }}>
              破棄数変更期限：{lotteryData.lottery_info?.discard_due_date} まで
            </span>
          </>
        )}
        <div className="d-flex flex-wrap" style={{ marginTop: 40 }}>
          {lotteryData?.list_detail?.map((item, index) => (
            <LotteryCard
              key={index}
              data={item}
              canDiscard={lotteryData.lottery_info?.can_discard}
              refundPoint={lotteryData.lottery_info?.refund_point}
            />
          ))}
        </div>
      </div>

      <div
        style={{
          backgroundColor: '#F7F7F7',
          marginTop: 56,
          padding: '40px 4%',
        }}
      >
        <div
          className="d-flex flex-wrap"
          id="share-sns-wrapper"
          style={{ paddingTop: 15, width: 'max-content', maxWidth: '100%' }}
        >
          {lotteryData?.list_prize?.map((item, index) => (
            <LotteryIcon data={item} key={index} />
          ))}
        </div>
        <div style={{ fontSize: 10 }}>※数字は当せん個数。（）内は破棄数。</div>

        <div className="text-center" style={{ marginTop: 40 }}>
          <TButton
            title={`${
              lotteryData?.lottery_info?.can_buy ? 'もう一度このクジを購入する' : '販売終了'
            }`}
            className={`${lotteryData?.lottery_info?.can_buy ? 'info' : 'primary'}`}
            id="goToBuySP"
            onClick={() =>
              lotteryData?.lottery_info?.can_buy && history.push(`/purchase/${lotteryId}`)
            }
            disabled={!lotteryData?.lottery_info?.can_buy}
          />
        </div>

        <div id="share-sns-sp-my-lottery">
          <div className="title">
            <img src={GUIDE.iconLeft} alt="icon" className="icon" />
            <div className="text-share">結果をシェアする</div>
            <img src={GUIDE.iconRight} alt="icon" className="icon" />
          </div>
          <div className="icon-wrapper">
            <img
              src={facebookIcon}
              alt="icon"
              className="icon icon-fb"
              onClick={() => handleShare('fb')}
            />
            <img src={twitterIcon} alt="icon" className="icon" onClick={() => handleShare('tw')} />
          </div>
        </div>
      </div>

      <div className="lottery-detail-sp">
        <HistoryPurchase data={lotteryData?.orders} />
      </div>

      <ModalDiscardGuideSP
        isShowDiscardGuide={isShowDiscardGuide}
        setShowDiscardGuide={setShowDiscardGuide}
      />
    </>
  );
};

export default MyCollectionLotteryDetailSP;
