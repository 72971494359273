// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import TopHeader from '../../components/common/topHeader';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import { useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import useMessage from '../../components/common/toast/UseMessage';
import { PurchaseHistoryApis } from '../../services/purchaseHistory';
import Pagination from '../../components/common/pagination';
import { BREAK_POINT, listsErrorNotShowToast } from '../../constants';
import PurchaseHistoryPC from '../../components/pages/purchaseHistory/PurchaseHistoryPC';
import PurchaseHistorySP from '../../components/pages/purchaseHistory/PurchaseHistorySP';
import './styles.scss';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
export default function PurchaseHistory() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const [listPurchaseHistory, setListPurchaseHistory] = useState();
  const [page, setPage] = useState(1);
  const [dataPagination, setDataPagination] = useState({});
  const totalPage = Math.ceil(dataPagination?.total / dataPagination?.per_page);

  const handleChangePage = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const getPurchaseHistory = async () => {
    dispatch(showLoading());
    const params = {
      page: page,
    };
    try {
      const res = await PurchaseHistoryApis.getData(params);
      const { data } = res;
      const dataPurchase = data.data;
      setListPurchaseHistory(dataPurchase);
      setDataPagination(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getPurchaseHistory();
  }, [page]);

  return (
    <>
      <TopHeader title={t('headerPurchaseHistory')} />
      <MediaQuery minWidth={BREAK_POINT + 1}>
        <PurchaseHistoryPC data={listPurchaseHistory} />
      </MediaQuery>
      <MediaQuery maxWidth={BREAK_POINT}>
        <PurchaseHistorySP data={listPurchaseHistory} />
      </MediaQuery>
      <div className="pagination-purchase-history">
        <Pagination onChange={handleChangePage} totalPage={totalPage} />
      </div>
      <ScrollButton />
    </>
  );
}
