// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import { toast } from 'react-toastify';
import { useCallback } from 'react';

export default function useMessage() {
  const openMessage = useCallback((data) => toast(data.message, { type: data.variant }), []);
  const openMessageError = useCallback((err) => {
    const { response } = err;
    toast(response?.data.message, { type: 'error' });
  }, []);
  return { openMessage, openMessageError };
}
