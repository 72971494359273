// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';

export default function News(props) {
  const { news } = props;

  return (
    <>
      {news && (
        <a className="news" href={`/news/${news.id}`}>
          <div className="fw-bold created_date">{news.created_date}</div>
          <div className="fw-bold title">{news.title}</div>
        </a>
      )}
    </>
  );
}
