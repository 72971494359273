// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';

function PriceItemArea({ title, value, className }) {
  return (
    <div className="price-info">
      <div className="title">
        <strong>{title}</strong>
      </div>
      <div className={`show-value ${className}`}>{value}</div>
    </div>
  );
}

export default PriceItemArea;
