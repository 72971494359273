// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import TopHeader from '../../components/common/topHeader';
import Collection from '../../components/pages/myCollection/Collection';
import { useTranslation } from 'react-i18next';
import { MyCollectionApis } from '../../services/myCollection';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import Pagination from '../../components/common/pagination';
import useMessage from '../../components/common/toast/UseMessage';
import { useHistory } from 'react-router-dom';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import { listsErrorNotShowToast } from '../../constants';

function MyCollection() {
  const { t } = useTranslation();
  const { openMessageError } = useMessage();
  const dispatch = useAppDispatch();
  const [dataCollection, setDataCollection] = useState();
  const [page, setPage] = useState(1);
  const handleChangePage = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const history = useHistory();

  const totalPage = Math.ceil(dataCollection?.total / dataCollection?.per_page);

  const getMyCollectionList = async () => {
    dispatch(showLoading());
    try {
      const res = await MyCollectionApis.getListLottery({ page });
      const { data } = res;
      setDataCollection(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getMyCollectionList();
  }, [page]);

  return (
    <>
      {dataCollection && (
        <>
          <TopHeader title={t('titleMyCollection')} />
          {dataCollection.data.length === 0 && (
            <div className="main-content my-collection-wrapper">
              <div style={{ color: '#464646' }}>まだコレクションがありません</div>
            </div>
          )}
          {dataCollection.data.length > 0 && (
            <>
              <div className="main-content my-collection-wrapper">
                <div className="d-flex flex-wrap">
                  {dataCollection.data.map((collection, index) => {
                    return (
                      <Collection
                        key={index}
                        collection={collection}
                        onClick={() =>
                          history.push(`/my-collection/${collection.lottery_event_id}`)
                        }
                      />
                    );
                  })}
                </div>
              </div>
              <div className="pagination-wrapper">
                <Pagination onChange={handleChangePage} totalPage={totalPage} />
              </div>
            </>
          )}
        </>
      )}
      <ScrollButton />
    </>
  );
}

export default MyCollection;
