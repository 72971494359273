// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import TopHeader from '../../components/common/topHeader';
import { useAppDispatch } from '../../store';
import { useTranslation } from 'react-i18next';
import useMessage from '../../components/common/toast/UseMessage';
import Pagination from '../../components/common/pagination';
import { BREAK_POINT } from '../../constants';
import PointHistoryPC from '../../components/pages/pointHistory/PointHistoryPC';
import './styles.scss';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import TButton from '../../components/common/button';
import PointHistorySP from '../../components/pages/pointHistory/PointHistorySP';
import { useHistory } from 'react-router-dom';
import { PointHistoryApis } from '../../services/pointHistory';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
export default function PointHistory() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const [listPointHistory, setListPointHistory] = useState();
  const [page, setPage] = useState(1);
  const [dataPagination, setDataPagination] = useState({});
  const totalPage = dataPagination?.total_page;
  const history = useHistory();

  const handleChangePage = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const getPointHistory = async () => {
    dispatch(showLoading());
    const params = {
      page: page,
    };
    try {
      const res = await PointHistoryApis.getData(params);
      const { data } = res;
      const dataPoint = data.data;
      setListPointHistory(dataPoint);
      setDataPagination(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getPointHistory();
  }, [page]);
  return (
    <>
      <TopHeader title={t('titlePointHistory')} />
      <MediaQuery minWidth={BREAK_POINT + 1}>
        <PointHistoryPC data={listPointHistory} />
      </MediaQuery>
      <MediaQuery maxWidth={BREAK_POINT}>
        <PointHistorySP data={listPointHistory} />
      </MediaQuery>
      <div className="pagination-point-history">
        <Pagination onChange={handleChangePage} totalPage={totalPage} />
      </div>
      <div className="text-center my-btn-point-history">
        <TButton
          title={t('buttonBackToMyPage')}
          className="danger"
          onClick={() => history.push('/my-page')}
        />
      </div>
      <ScrollButton />
    </>
  );
}
