// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import i18n from './../i18n/i18n';

export const validateFullName = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredFullName');
  } else if (name.length > 15) {
    return i18n.t('checkMaxLengthFullName');
  }
  return '';
};
export const validateFurigana = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredFurigana');
  } else if (name.length > 15) {
    return i18n.t('checkMaxLengthFullName');
  }
  return '';
};
export const validateZipCode = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredZipCode');
  } else if (name.length < 7) {
    return i18n.t('checkZipCode');
  }
  return '';
};
export const validateAddress1 = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredAddress1');
  } else if (name.length > 50) {
    return i18n.t('checkMaxLengthAddress');
  }
  return '';
};
export const validatePhone = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredPhone');
  } else if (name.length < 10 || name.length > 11) {
    return i18n.t('checkPhone');
  }
  return '';
};
export const validateEmailDelivery = (email) => {
  if (isEmpty(email)) {
    return i18n.t('checkRequiredEmailDelivery');
  } else if (!isEmail(email)) {
    return i18n.t('checkEmailType');
  }
  if (email.length > 255) {
    return i18n.t('checkMaxLengthEmail');
  }
  return '';
};

export const validateConfirmEmailDelivery = (email, confirm_email) => {
  if (isEmpty(email)) {
    return i18n.t('checkRequiredConfirmEmailDelivery');
  } else if (email !== confirm_email) {
    return i18n.t('isMatchEmail');
  }
  if (email.length > 255) {
    return i18n.t('checkMaxLengthEmail');
  }
  return '';
};

export const validateDistrict = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredDistrict');
  }
  return '';
};
export const validateProvince = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredProvince');
  }
  return '';
};

export const validateName = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredName');
  } else if (name.length > 30) {
    return i18n.t('checkMaxLengthName');
  }
  return '';
};

export const validateContent = (name) => {
  if (isEmpty(name)) {
    return i18n.t('checkRequiredContent');
  } else if (name.length > 2000) {
    return i18n.t('checkMaxLengthContent');
  }
  return '';
};
