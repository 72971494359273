// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import DeliveryAddress from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailPC/DeliveryAddress';
import InformationLottery from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailPC/InformationLottery';
import InformationPayment from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailPC/InformationPayment';
import ListPrize from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailPC/ListPrize';
import PurchaseQuantity from '../../components/pages/purchaseHistoryDetail/PurchaseHistoryDetailPC/PurchaseQuantity';
import { useTranslation } from 'react-i18next';
import ScrollButton from '../../components/pages/top/backToTop/ScrollButton';
import LotteryIcon from '../../components/pages/myCollection/lotteryDetail/LotteryIcon';

export default function PurchaseHistoryDetailPC(props) {
  const { t } = useTranslation();
  const { data } = props;
  const dataOrders = data?.order;
  const dataPrize = data?.list_prize;
  const lotteryEventId = data?.lottery_event_id;

  return (
    <div>
      <div className="mb80">
        <InformationLottery dataOrders={dataOrders} />
      </div>
      <div className="mb40">
        <PurchaseQuantity dataOrders={dataOrders} data={data} />
      </div>
      <div className="mb80">
        <InformationPayment dataOrders={dataOrders} />
      </div>
      <div className="mb40">
        <p className="title-detail">{t('titleListPrize')}</p>
        <div className="list-prize">
          {dataPrize?.length > 0 &&
            dataPrize?.map((prize, index) => {
              return <ListPrize key={index} prize={prize} />;
            })}
        </div>
      </div>

      <div style={{ marginBottom: 80 }}>
        <p className="title-detail">お届け予定商品</p>
        <div className="d-flex flex-wrap" style={{ width: 'max-content', maxWidth: 920 }}>
          {data?.all_prize?.map((item, index) => (
            <LotteryIcon data={item} key={index} flag={1} lotteryEventId={lotteryEventId} />
          ))}
        </div>
        <div>※数字は当せん個数。（）内は破棄数。</div>
      </div>

      <div className="mb80">
        <DeliveryAddress dataOrders={dataOrders} />
      </div>
      <ScrollButton />
    </div>
  );
}
