// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import TopHeader from '../../components/common/topHeader';
import { UserInfoApis } from '../../services/userInfo';
import { useAppDispatch } from '../../store';
import { hideLoading, showLoading } from '../../store/features/loadingPage';
import './styles.scss';
import useMessage from '../../components/common/toast/UseMessage';
import SNSSocial from '../../components/pages/snsManagement/SNSSocial';
import { MY_PAGE } from '../../../assets/imgs';
import { listsErrorNotShowToast } from '../../constants';

function SNSManagement(props) {
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();

  const [data, setData] = useState();

  const getUserInfo = async () => {
    const formData = {
      info: 'social',
    };
    dispatch(showLoading());
    try {
      const res = await UserInfoApis.getData(formData);
      const { data } = res;
      setData(data);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      <TopHeader title="サービス連携設定" />
      <div className="sns-management">
        <div className="title">ログイン連携</div>
        <div className="description-title">SNSアカウントを使ってログインするための設定です。</div>
        <SNSSocial
          image={MY_PAGE.iconGoogle}
          name="Googleでログイン"
          status={data?.linked_google}
          social="google"
          callbackFunction={getUserInfo}
        />
        <SNSSocial
          image={MY_PAGE.iconTwitter}
          name="Twitterでログイン"
          status={data?.linked_twitter}
          social="twitter"
          callbackFunction={getUserInfo}
        />
        <SNSSocial
          image={MY_PAGE.iconFacebook}
          name="Facebookでログイン"
          status={data?.linked_facebook}
          social="facebook"
          callbackFunction={getUserInfo}
        />
      </div>
    </>
  );
}

export default SNSManagement;
