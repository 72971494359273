// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';
import { useTranslation } from 'react-i18next';
import TButton from '../../components/common/button';
import TopHeader from '../../components/common/topHeader';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import './styles.scss';
import { logout } from '../../store/features/auth';
import { useAppDispatch } from '../../store';

export default function VerifyNewEmail() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const moveToLogin = () => {
    dispatch(logout());
    history.push('/login?callbackUrl=%2Fmy-page');
  };
  return (
    <>
      <TopHeader title={t('headerChangeEmail')} />
      <div className="main-content">
        <Modal show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body className="size-modal">
            <div className="text-modal">メールアドレスの変更が完了しました</div>
            <div className="text-center">
              <TButton title="マイページへ戻る" className="btn danger" onClick={moveToLogin} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
