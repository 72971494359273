// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';

export default function Thumbnail(props) {
  const { data } = props;
  return (
    <>
      {data?.thumbnail !== null && (
        <div className="image-thumbnail">
          <div>
            <img src={data?.thumbnail} />
          </div>
        </div>
      )}
    </>
  );
}
