// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import styled from 'styled-components';
import TopHeader from '../common/topHeader';
import ScrollButton from './top/backToTop/ScrollButton';

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 30px;
  color: #464646;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  font-size: 14px;
  letter-spacing: 0.1em;
  line-height: 24px;
  color: #464646;
  margin-bottom: 30px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;

export default function CompanyInfo() {
  return (
    <>
      <TopHeader title="運営会社" />
      <div className="main-content form-static">
        <Title>運営会社</Title>
        <Content>会社概要</Content>
        <Content>■商号 株式会社　○○○○○○</Content>
        <Content>
          ■所在地
          <br />
          　〒123-4546　東京都○○○○○○○○五丁目○○-○
        </Content>
        <Content>■設立 2021年12月12日</Content>
        <Content>■資本金 3億円</Content>
        <Content>
          ■従業員数
          <br />
          　60名（2021年10月1日時点）
        </Content>
        <Content>
          ■取締役・監査役
          <br />
          　代表取締役社長 山田 太郎
          <br />
          　取締役 山田 太郎
        </Content>
      </div>
      <ScrollButton />
    </>
  );
}
