// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { Timeline } from 'react-twitter-widgets';

export default function TwitterTimeline() {
  return (
    <div className="twitter-wrapper">
      <Timeline
        dataSource={{ sourceType: 'profile', screenName: process.env.TWITTER_NAME }}
        options={{ height: '540', lang: 'ja', chrome: 'nofooter' }}
      />
    </div>
  );
}
