// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import './styles.scss';

function TopHeader(props) {
  return (
    <div className="top-header">
      <div className="title">{props.title}</div>
    </div>
  );
}

export default TopHeader;
