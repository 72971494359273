// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function CountDown(props) {
  const { date } = props;

  const { t } = useTranslation();

  const [isFinishCountDown, setFinishCountDown] = useState(false);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(function () {
      const target = dayjs(date);
      const now = dayjs();
      const distance = target.diff(now);

      const _days = Math.floor(distance / (1000 * 60 * 60 * 24));
      setDays(_days);
      const _hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      setHours(_hours);
      const _minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(_minutes);
      const _seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setSeconds(_seconds);

      if (distance < 0) {
        setFinishCountDown(true);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  const mapObj = {
    days,
    hours,
    minutes,
    seconds,
  };

  return (
    <>
      {!isFinishCountDown && (
        <p className="mb-0">
          {t('formatTimeCountDown').replace(
            /\b(?:days|hours|minutes|seconds)\b/gi,
            (matched) => mapObj[matched],
          )}
        </p>
      )}
    </>
  );
}
