// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import i18n from './../i18n/i18n';

export const validateEmail = (email) => {
  if (isEmpty(email)) {
    return i18n.t('checkRequiredEmail');
  } else if (!isEmail(email)) {
    return i18n.t('checkEmailType');
  } else if (email.length > 255) {
    return i18n.t('checkMaxLengthEmail');
  }
  return '';
};

export const validateNewEmail = (email) => {
  if (isEmpty(email)) {
    return i18n.t('checkRequiredNewEmail');
  } else if (!isEmail(email)) {
    return i18n.t('checkEmailType');
  }
  return '';
};

export const validateConfirmNewEmail = (email, confirmEmail) => {
  if (isEmpty(email)) {
    return i18n.t('checkRequiredConfirmNewEmail');
  } else if (email !== confirmEmail) {
    return i18n.t('isMatchNewEmail');
  }
  return '';
};
