// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React, { useEffect } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import TButton from '../../components/common/button';
import { useHistory } from 'react-router-dom';
import JSCookie from 'js-cookie';
import TopHeader from '../../components/common/topHeader';

export default function DeleteAccountSuccess() {
  const { t } = useTranslation();
  const history = useHistory();
  const handleMovePage = () => {
    JSCookie.remove('token');
    history.push('/');
  };

  useEffect(() => {
    if (history?.location?.state === undefined) {
      history.push('/error/404');
    }
  }, []);
  return (
    <>
      <TopHeader title={t('headerDeleteAccount')} />
      <div className="main-content">
        <div className="title-delete-success">{t('headerDeleteAccount')}</div>
        <div className="info-delete-success">
          <div className="mt-4">{t('alertDeleteAccountSuccess1')}</div>
          <div>{t('alertDeleteAccountSuccess2')}</div>
        </div>

        <div className="mt-4">
          <TButton title={t('textBtnBackTop')} className="danger" onClick={handleMovePage} />
        </div>
      </div>
    </>
  );
}
