// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { MaintainApi } from '../../../services/maintain';

import './footer.scss';

export default function Footer() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const path = location?.pathname;
  const listLink = [
    { title: '運営会社', onclick: () => window.open('https://hike.inc/') },
    { title: '利用規約', onclick: () => history.push('/terms'), route: '/terms' },
    {
      title: '特定商取引法に基づく表記',
      onclick: () => history.push('/commercial-transaction-law'),
      route: '/commercial-transaction-law',
    },
    {
      title: 'プライバシーポリシー',
      onclick: () => window.open('https://hike.inc/privacy/'),
    },
    { title: 'お問い合わせ', onclick: () => history.push('/contact'), route: '/contact' },
    { title: 'よくある質問', onclick: () => window.open('https://help.kuzipa.com') },
    { title: 'システム導入について', onclick: () => window.open('/lp', '_blank') },
  ];

  useEffect(async () => {
    const res = await MaintainApi.getMaintainData();
    const { data } = res;
    if (data?.status && path !== 'maintain') {
      history.push('/maintain');
    }
  }, [path]);

  return (
    <div className="w-100 text-light" id="footer-wrapper" style={{ backgroundColor: '#262626' }}>
      <div className="footer">
        <div className="footer-content">
          {listLink?.map((link, index) => {
            return (
              <div
                className={
                  path === link.route ? 'content-item content-item-active' : 'content-item'
                }
                key={index}
                onClick={link.onclick}
              >
                {link.title}
              </div>
            );
          })}
        </div>
        <div className="text-center">{t('copyRight')}</div>
      </div>
    </div>
  );
}
