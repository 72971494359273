// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';

const TButton = ({ className, disabled = false, title, type, onClick, id }) => (
  <button
    type={type}
    className={`btn ol-btn fw-bold ${className}`}
    disabled={disabled}
    onClick={onClick}
    id={id}
  >
    <span id="btn-text">{title}</span>
    <span className="icon">
      <RiArrowRightSLine />
    </span>
  </button>
);

export default TButton;
