// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import React from 'react';

export default function ConfirmEmail(props) {
  const { data } = props;
  return (
    <>
      <div className="main-content">
        <p>
          ※登録はまだ完了していません <br />「{data}」 へ認証メールを送信しました。 <br />
          メールに記載されているURLを開いて、登録完了してください。
          <br /> <br />
          ※URLの有効期限は1時間です
        </p>
      </div>
    </>
  );
}
