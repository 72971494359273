// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { Card, Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { NextArrow, PrevArrow } from '../../../utils/renderArrow';

export default function PreviewPhoto(props) {
  const { t } = useTranslation();
  const { data, image, order = 0 } = props;
  const carousels = props.carousels || [];
  const prizes = props.prizes || [];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    initialSlide: order,
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="preview-photo"
      >
        <Modal.Body>
          {carousels.length > 0 && (
            <Slider {...settings}>
              {carousels.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="intro-thumbnail">
                      <Card.Img variant="top" src={item.image_url} />
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
          {prizes.length > 0 && (
            <>
              <Slider {...settings}>
                {prizes.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="intro-thumbnail">
                        <Card.Img variant="top" src={item.img} />
                      </div>
                      <div className="preview-description mt-4">
                        <div>{`${data.rank_name}賞 ${data.title} ${data.rank_name}-${item.number_order}${item.title}`}</div>
                        <div className="description">{item?.description}</div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </>
          )}

          {image && (
            <div className="preview-w-change">
              <img src={image} alt="img" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="cursor-pointer" onClick={() => props.onHide()}>
            {t('textBack')}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
