// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

export const TYPE_SOCIAL_MEDIA = {
  FB: 'fb',
  TW: 'tw',
  LN: 'ln',
};

export const shareLink = (provider, link) => {
  switch (provider) {
    case TYPE_SOCIAL_MEDIA.FB:
      return 'https://www.facebook.com/sharer/sharer.php?u=' + link;
    case TYPE_SOCIAL_MEDIA.TW:
      return 'https://twitter.com/intent/tweet?url=' + link;
    case TYPE_SOCIAL_MEDIA.LN:
      return 'https://social-plugins.line.me/lineit/share?url=' + link;
    default:
      return '#';
  }
};

export const getBase64Image = (img) => {
  try {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(img, 0, 0, img.width, img.height);
    }
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  } catch (err) {
    console.error(err);
    return '';
  }
};
