// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { ScratchImg } from '../../../../../assets/imgs';

import './scratchItem.scss';

function ScratchItem(props) {
  const { onClickItem } = props;

  return (
    <div className="scratch-item" onClick={() => onClickItem()}>
      <img src={ScratchImg} style={{ width: '100%' }} />
      <div className="wrap_scratch_bg" />
    </div>
  );
}

export default ScratchItem;
