// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import _ from 'lodash';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './index.scss';

const LotteryIcon = (props) => {
  const { data, flag, lotteryEventId } = props;
  const { lotteryId } = useParams();
  const history = useHistory();
  const HISTORY_PURCHASE = 1;
  let quantity = 0;
  if (!_.isNil(data.total) && !_.isNil(data.total_discard)) {
    quantity = data.total - data.total_discard;
  }

  return (
    <div
      className="lottery-icon"
      onClick={() => {
        if (data.total && lotteryEventId) {
          return history.push(`/my-collection/${lotteryEventId}/prize/${data.id}`);
        } else if (data.total) {
          return history.push(`/my-collection/${lotteryId}/prize/${data.id}`);
        }
      }}
    >
      <div id="img-wrapper">
        {flag === HISTORY_PURCHASE ? (
          <img
            src={data?.image[0]?.image_url}
            className={`prizeItemImg ${quantity ? '' : 'fade'}`}
          />
        ) : (
          <img
            src={data?.image[0]?.image_url}
            className={`prizeItemImg ${quantity ? '' : 'fade'}`}
          />
        )}
      </div>
      <div className={`quantity ${quantity ? '' : 'fade'}`}>
        {data.total} ({data.total_discard})
      </div>
    </div>
  );
};

export default LotteryIcon;
