// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import olAxios from '../api/Interceptors';

export const AuthApis = {
  register: (data) => {
    return olAxios.post('/register', data);
  },
  login: (data) => {
    return olAxios.post('/login', data);
  },
  logout: () => {
    return olAxios.post('/logout');
  },
  loginSocial: (data) => {
    return olAxios.post('/login-social', data);
  },
  registerSocial: (data) => {
    return olAxios.post('/register-social', data);
  },
  loginTwitter: () => {
    return olAxios.get('/login-twitter');
  },
  deleteAccount: (data) => {
    return olAxios.post('/user/delete', data);
  },
  forgotPassword: (data) => {
    return olAxios.post('/password/email', data);
  },
  resetPassword: (data) => {
    return olAxios.post('/password/reset', data);
  },
  contactUs: (data) => {
    return olAxios.post('/contact-us', data);
  },
  linkAccount: (data) => {
    return olAxios.post('/link-account', data);
  },
  unLinkAccount: (data) => {
    return olAxios.get(`/unlink-account/${data}`);
  },
  changeEmail: (data) => {
    return olAxios.post('/change-email', data);
  },
  confirmCode: (data) => {
    return olAxios.post('/check-confirm-code', data);
  },
  changePassword: (data) => {
    return olAxios.post('/change-password', data);
  },
  resetPasswordVerify: (data) => {
    return olAxios.post('/password/reset/verify', data);
  },
};
