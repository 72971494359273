// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';
import { NewsApis } from '../../../services/listNews';
import useMessage from '../../common/toast/UseMessage';
import { listsErrorNotShowToast } from '../../../constants';
import JSCookie from 'js-cookie';

export default function NewsItem(props) {
  const { news } = props;
  const NOT_SEEN_NEWS = 1;
  const history = useHistory();

  const token = JSCookie.get('token');

  const { openMessageError } = useMessage();

  const seenNews = async () => {
    const params = {
      news_id: news.id,
    };
    try {
      await NewsApis.seenNews(params);
    } catch (error) {
      if (!listsErrorNotShowToast.includes(Number(error?.response?.status))) {
        openMessageError(error);
      }
    }
  };

  const moveToDetail = () => {
    if (token) {
      seenNews();
    }
    history.push({ pathname: `/news/${news.id}` });
  };

  const renderStatus = () => {
    if (isNil(news.status)) return <div className="col-md-2" />;
    if (news.status === NOT_SEEN_NEWS) return <div className="status status-not-seen">未読</div>;
    return <div className="status status-seen">既読</div>;
  };

  return (
    <>
      <div className="info-list-news" onClick={moveToDetail}>
        <p className="info-date">{news.created_date}</p>
        <div className="info-news">
          {renderStatus()}
          <p className="info-title">{news.title}</p>
        </div>
      </div>
    </>
  );
}
