// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b
import { TOP_IMAGES } from '../../assets/imgs';
import { ON_CHALLENGE } from '../constants';

export const convertSaleStatusToClassName = (status) => {
  let result = '';
  switch (status) {
    case 'NEW':
      result = TOP_IMAGES.LotteryStatus.NewBG;
      break;
    case '販売予定':
      result = TOP_IMAGES.LotteryStatus.OnScheduleBG;
      break;
    case '終了間近':
      result = TOP_IMAGES.LotteryStatus.NearEndSaleBG;
      break;
    case '販売中':
      result = TOP_IMAGES.LotteryStatus.OnSaleBG;
      break;
    default:
      result = TOP_IMAGES.LotteryStatus.EndSaleBG;
      break;
  }
  return result;
};

export const convertSaleStatusToColor = (status) => {
  let result = '';
  switch (status) {
    case 'NEW':
      result = '#F55076';
      break;
    case '販売予定':
      result = '#40B569';
      break;
    case '終了間近':
      result = '#FD6C21';
      break;
    case '販売中':
      result = '#1D9DED';
      break;
    default:
      result = '#797979';
      break;
  }
  return result;
};

export const convertRankName = (rankName) => {
  return rankName === ON_CHALLENGE ? '＋On Challenge' : rankName;
};
