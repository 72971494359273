// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import { createSlice } from '@reduxjs/toolkit';
import jscookie from 'js-cookie';

const initialState = {
  token: null,
  userInfo: null,
  isShowNews: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, data) => {
      const payload = data.payload;
      state.token = payload.token;
      state.userInfo = payload.user;
      if (payload.token) {
        jscookie.set('token', payload.token, { expires: 30 });
      }
    },
    showNews: (state, data) => {
      const payload = data.payload;
      state.isShowNews = payload.status;
    },
    logout: (state) => {
      state = { ...initialState };
      jscookie.remove('token');
      return state;
    },
  },
});

export const { login, logout, showNews } = authSlice.actions;
export default authSlice.reducer;
