// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import olAxios from '../api/Interceptors';

export const LotteryApis = {
  getList: (params) => {
    return olAxios.get('/lottery/list', { params });
  },

  getDetail: (id, params) => {
    return olAxios.get(`/lottery-event/${id}`, { params });
  },
  getDetailTrial: () => {
    return olAxios.get(`/lottery-trial`);
  },
};
