// project hash key
// e921d896b24ee51ad95dc303a034758da1187532b0a44189bd99aa333255781b

import React from 'react';
import { convertSaleStatusToColor } from '../../../../utils/convertSaleStatus';
import './index.scss';

const LotteryInfo = (props) => {
  const { lotteryInfo } = props;
  return (
    <div className="lottery-info">
      <div className="information">
        <div className="lottery-img">
          <div>
            <img src={lotteryInfo?.image.image_url} />
          </div>
        </div>
        <div className="time-box">
          <div className="fw-bold">{lotteryInfo?.name}</div>
          <div className="d-flex align-items-center">
            <span className={`tag ${lotteryInfo?.can_buy ? 'tag-end' : 'tag-ex'}`}>
              {lotteryInfo?.can_buy ? '販売終了日' : '販売終了'}
            </span>
            <span>{lotteryInfo?.end_date}</span>
            {lotteryInfo?.can_buy && (
              <>
                <span className="tag tag-delivery">お届け時期</span>
                <span>{lotteryInfo?.estimated_delivery_date}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="quantity">
        <div className="quantity-info">
          <div className="content-left">クジ名</div>
          <div className="content-right">
            {lotteryInfo?.name}
            <span
              className="sale-status"
              style={{
                whiteSpace: 'nowrap',
                color: '#fff',
                marginLeft: 40,
                padding: '2px 7px',
                backgroundColor: convertSaleStatusToColor(lotteryInfo?.sale_status),
              }}
            >
              {lotteryInfo?.sale_status}
            </span>
          </div>
        </div>
        <div className="quantity-info">
          <div className="content-left">当せん個数</div>
          <div className="content-right">
            {lotteryInfo?.total_buy}個(受け取り個数
            {lotteryInfo?.total_buy - lotteryInfo?.total_discard}個/破棄数
            {lotteryInfo?.total_discard}個) {lotteryInfo?.status}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LotteryInfo;
